import React, { useContext } from "react";
import { withRouter } from "react-router-dom";
import "../../../Utils/sd-styles/sd-main-sup.scss";
import pagesData from "../../../Utils/data/pages.data";
import { SD_Context } from "../../../context/sd-context";

const TemplateTwo = ({ history }) => {
  //-------------------------------------------------------------states--------------------------------------------------------//
  const { language } = useContext(SD_Context);

  //-------------------------------------------------------------states--------------------------------------------------------//

  //-----------------------------------------------------------useEffects--------------------------------------------------------//

  //-----------------------------------------------------------useEffects--------------------------------------------------------//

  return (
    <>
      {" "}
      <div className="top services-bg">
        <h1>Devotee Services</h1>
        <img
          src={pagesData("home")?.divider}
          alt="-"
          style={{ height: "20px" }}
        ></img>
      </div>
      <div className="sd-template-two">
        {pagesData("home")?.devoteeServices?.map((item, i) => {
          return (
            <div key={i} className="sd-box-one ">
              <div className="sd-box-one-image">
                <img
                  src={item?.img}
                  alt={item?.title}
                  className="sd-logo-img sd-size-four"
                />
              </div>
              <div className="sd-box-one-content">
                <h2>{item?.title}</h2>
                <p>{item?.desc}</p>

                <button
                  className="sd-b-orange"
                  onClick={() => {
                    if (item?.link === "sevas-and-darshanam") {
                      history?.push(`/${language?.code}/${item?.link}`);
                    } else if (localStorage.getItem("accessToken")) {
                      history?.push(
                        `/${language?.code}/devotee-app/online-booking/${item?.link}`
                      );
                    } else {
                      history?.push(
                        `/${language?.code}/devotee/signin?to=devotee-app/online-booking/${item?.link}`
                      );
                    }
                  }}
                >
                  {item?.label}{" "}
                  <img src={pagesData("home")?.primaryArrow} alt=">" />
                </button>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default withRouter(TemplateTwo);
