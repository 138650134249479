import getTempleShortCodeFromDomain from "../Utils/data/templeId.data";
const templeImagePath =
  process.env.REACT_APP_TEMPLE ??
  getTempleShortCodeFromDomain(window?.location?.hostname);

//------------------homepage png's---------------------------//
const sd_accomadation =
  require(`./common/sd-home-pngs/SD-accommodation-icon.png`)?.default;
const sd_annadanam =
  require(`./common/sd-home-pngs/SD-annadanam-icon.svg`)?.default;
//added new

const loader =
  require(`./common/temple-home-page/kanipakam-temple-preloader.gif`)?.default;
const calender =
  require(`./common/temple-home-page/MDI - calendar-blank-outline@2x.png`)?.default;
const seva_and_darshanam =
  require(`./common/temple-home-page/seva-and-darshanam.svg`)?.default;
const donation = require(`./common/temple-home-page/donation.svg`)?.default;
const accommodation =
  require(`./common/temple-home-page/accommodation.svg`)?.default;

const send_icon = require(`./common/temple-home-page/MDI - send.svg`)?.default;
const mail_icon = require(`./common/temple-home-page/mail.svg`)?.default;
const nine = require(`./common/temple-home-page/Group 55.svg`)?.default;

const sd_ticket_banner =
  require(`./common/temple-home-page/Header@2x.png`)?.default;

const upcomingBanner1 =
  require(`./common/temple-home-page/upcoming-banner-1.png`)?.default;

// const ssvvsd_home_banner_spcl = require(`./common/sd-slider-spcl-2.jpg`)?.default;
//------------------------------------------Gallery Images-----------------------------------------------------------------------------------//

//------------------------------------------Home Icons -------------------------------------------------------------------------------------//
const orange_arrow =
  require(`./common/temple-home-page/round-arrow.svg`)?.default;

const sd_email_icon = require(`./common/SD-mail-icon.png`)?.default;
const sd_playstore_icon =
  require(`./common/sd-home-pngs/SD-playstore-icon.png`)?.default;
const sd_appstore_icon =
  require(`./common/sd-home-pngs/SD-appstore-icon.png`)?.default;
const sd_newsletter_arrow_icon =
  require(`./common/sd-home-pngs/SD-newsletter-arrow-icon.png`)?.default;
const sd_print_a_ticket_icon =
  require(`./common/sd-home-pngs/SD-Print-a-ticket-icon.png`)?.default;
const sd_sign_in_icon =
  require(`./common/sd-home-pngs/SD-Sign-In-icon.png`)?.default;
const sd_srisaila_tv_icon =
  require(`./common/sd-home-pngs/SD-Srisaila-TV-icon.png`)?.default;
const sd_instagram_icon = require(`./common/SD-Instagram-icon.png`)?.default;
const sd_facebook_icon = require(`./common/SD-fb-icon.png`)?.default;
const sd_twitter_icon = require(`./common/SD-tw-icon.png`)?.default;
const sd_youtube_icon = require(`./common/SD-YT-icon.png`)?.default;
const sd_search_icon =
  require(`./common/sd-home-pngs/SD-search-icon.png`)?.default;
const sd_cart_icon = require(`./common/sd-home-pngs/SD-cart-icon.png`)?.default;
const greaterthan = require(`./common/greater.png`)?.default;
const sd_web_icon = require(`./common/SD-web-icon.png`)?.default;
const sd_btn_loading = require(`./common/sd-btn-loader.svg`)?.default;
const sd_print_icon = require(`./common/SD-print-icon.png`)?.default;
const sd_side_arrow = require(`./common/images/sd-side-arrow.png`)?.default;
const sd_check = require(`./common/sd-check.svg`)?.default;
const sd_instagram_icon_white = require(`./common/SD-instagram.png`)?.default;
const sd_facebook_icon_white = require(`./common/SD-facebook.png`)?.default;
const sd_twitter_icon_white = require(`./common/SD-twitter.png`)?.default;
const sd_popup_close = require(`./common/sd-popup-close.png`)?.default;
const sd_youtube_icon_white = require(`./common/SD-youtube.png`)?.default;
const orange_x = require(`./common/x-mark-48.png`)?.default;
const sd_map_icon = require(`./common/SD-map-icon.png`)?.default;
const sd_right_arrow_blue =
  require(`./common/SD-right-arrow-blue-icon.png`)?.default;
const sd_right_arrow_white =
  require(`./common/SD-right-arrow-white-icon.png`)?.default;
const sd_arrow_right_orange =
  require(`./common/SD-right-arrow-orange-icon.png`)?.default;
const sd_infor_icon = require(`./common/sd-info-icon.svg`)?.default;
const sd_white_plus = require(`./common/sd-plus-icon.png`)?.default;
const sd_edit_black = require(`./common/sd-edit-black-icon.png`)?.default;
const sd_dropdown_arrow_icon =
  require(`./common/sd-home-pngs/SD-Dropdown-Arrow-icon.png`)?.default;
const sd_call_icon = require(`./common/SD-call-icon.png`)?.default;
const sd_hamburger = require(`./common/hamburger.png`)?.default;
const sd_flower = require(`./common/sd-email-divider-icon.png`)?.default;
const sd_t_email = require(`./common/sd-email-address.png`)?.default;
const sd_t_phone = require(`./common/sd-contact-numbers.png`)?.default;
const sd_t_websiter = require(`./common/sd-webiste-add.png`)?.default;
const sd_minus_icon = require(`./common/sd-mbl-minus.png`)?.default;
const sd_plus_icon = require(`./common/sd-mbl-plus.png`)?.default;
const sd_devotee_dashboard =
  require(`./common/sd-devotee-dashboard.png`)?.default;
const sd_default_profile_pic =
  require(`./common/sd-default-profile-pic.png`)?.default;
const sd_logout_icon = require(`./common/sd-logout.png`)?.default;
const sd_myaccount_icon = require(`./common/sd-my-account.png`)?.default;
const sd_upload_image = require(`./common/sd-upload-image.png`)?.default;
const sd_booking_history_icon =
  require(`./common/sd-booking-history.png`)?.default;
const sd_support = require(`./common/sd-support.png`)?.default;
const sd_online_booking_icon =
  require(`./common/sd-online-booking.png`)?.default;
const sd_wishlist_heart_active =
  require(`./common/sd-wishlist-active.png`)?.default;
const sd_wishlist_heart_unactive = require(`./common/sd-wishlist.png`)?.default;
const sd_share = require(`./common/sd-share.png`)?.default;
const sd_india_flag = require(`./common/sd-india-flag.svg`)?.default;
const sd_eye = require(`./common/sd-eye.png`)?.default;
const sd_eye_close = require(`./common/sd-invisible-symbol.svg`)?.default;
const sd_faq_plus = require(`./common/sd-plus.png`)?.default;
const sd_faq_minus = require(`./common/sd-minus.png`)?.default;
const sd_plus1 = require(`./common/sd-plus1.png`)?.default;
const sd_plus2 = require(`./common/sd-plus2.png`)?.default;
const sd_plus3 = require(`./common/sd-plus3.png`)?.default;
const sd_call_outline = require(`./common/sd-call-oultine.png`)?.default;
const sd_mail_outline = require(`./common/sd-mail-outline.png`)?.default;
const sd_map_outline = require(`./common/sd-map-oultine.png`)?.default;
const sd_chat_outline = require(`./common/sd-chat-outline.png`)?.default;
const sd_press_icon = require(`./common/sd-press-icon.png`)?.default;
const sd_rta_icon = require(`./common/sd-rta-icon.png`)?.default;
const sd_mediakit_icon = require(`./common/sd-media-kit-icon.png`)?.default;
const sd_tenders_icon = require(`./common/sd-tenders-icon.png`)?.default;
const sd_whats_new_icon = require(`./common/sd-whats-new-icon.png`)?.default;
const sd_gallery_icon = require(`./common/sd-galler-icon.png`)?.default;
const sd_contact_us_icon = require(`./common/sd-contact-us-icon.png`)?.default;
const sd_connectivity = require(`./common/sd-connectivity.png`)?.default;
const sd_facilities = require(`./common/sd-facilities-icon.png`)?.default;
const sd_faq_icon = require(`./common/sd-faq-icon.png`)?.default;
const sd_trash = require(`./common/sd-trash.svg`)?.default;
const sd_rupee_white = require(`./common/sd-rupee-white-icon.png`)?.default;
const sd_rupee = require(`./common/sd-rupee-icon.png`)?.default;
const sd_alarm = require(`./common/sd-time-icon.png`)?.default;
//------------------------------------------Paroksha Seva ----------------------------------------------------------------------------------//

//----------------------------------------------Ganapathi homam-----------------------------------------------------------------------------//

const sd_info_icon =
  require(`./common/sd-ganapathihomam-pngs/SD-info-icon.png`)?.default;

//---------------------------------------------Donations-------------------------------------------------------------------------------//
//---------------------------------------------Dashboard--------------------------------------------------------------------------//
const sd_profile_icon_dashboard =
  require(`./common/images/sd-profile-icon.png`)?.default;

const sd_dashboard_accomadation =
  require(`./common/images/sd-accommodation-icon.png`)?.default;
const sd_dashboard_seva = require(`./common/images/sd-seva-icon.png`)?.default;
const sd_dashboard_donation =
  require(`./common/images/sd-donation.png`)?.default;
const sd_dashboard_srisaila_prabha =
  require(`./common/images/sd-prabha-icon.png`)?.default;
const sd_dashboard_upcoming =
  require(`./common/images/sd-calendar-icon.png`)?.default;
const sd_news_list_icon =
  require(`./common/images/sd-news-list-icon.png`)?.default;
const sd_dashboard_side_arrow =
  require(`./common/images/sd-side-arrow.png`)?.default;
const sd_dashboard_phone_ico =
  require(`./common/images/sd-phone-ico.png`)?.default;
const sd_dashboard_dob = require(`./common/images/sd-dob.png`)?.default;
const sd_dashboard_edit_ico =
  require(`./common/images/sd-edit-ico.png`)?.default;

//----------------------------------------------About-------------------------------------------------------------------------------------//
const sd_temple_icon = require(`./common/sd-about-temple.png`)?.default;
const sd_temple_book = require(`./common/sd-temple-book.png`)?.default;
const sd_general_indo = require(`./common/sd-general-info.png`)?.default;
const sd_srisaila_loading_logo =
  require(`./common/srisaila-loader.svg`)?.default;
const sd_success_icon = require(`./common/sd-success-arrow.png`)?.default;
const sd_failure_icon = require(`./common/sd-error-icon.png`)?.default;
// -----------------------------------------new----------------------------------------------------------------------------------------//
//-------------------------------------------new images-----------------------------------------//
const sd_view = require(`./common/images/sd-view.png`)?.default;
const sd_move_left = require(`./common/images/sd-arrow-left.png`)?.default;
const sd_move_right = require(`./common/images/sd-arrow-right.png`)?.default;
const sd_email_header = require(`./common/sd-email-header.png`)?.default;
const sd_script = require(`./common/script.png`)?.default;
const sd_404 = require(`./common/sd-404.gif`)?.default;

const flower = require(`./common/temple-home-page/Group 5839.svg`)?.default;

const EO_SSVVSD = require(`./common/temple-home-page/EO_SSVVSD.jpeg`)?.default;
const EO_SDMSD = require(`./common/temple-home-page/EO_SDMSD.png`)?.default;
const chairman =
  require(`./common/temple-home-page/Group 5851@2x.png`)?.default;
const parokshaseva =
  require(`./common/temple-home-page/parokshaba.png`)?.default;
const kalyana = require(`./common/temple-home-page/kalayana.png`)?.default;
const kalyanaMandapam =
  require(`./common/temple-home-page/242254323_4244528972309069_845044139586773574_n@2x.png`)?.default;
const accommodationb = require(`./common/temple-home-page/accc.png`)?.default;
const kaniapkaadevasthanam =
  require(`./common/temple-home-page/temple.png`)?.default;

const gajapthi = require(`./common/kanipakam-board/gajapathi.jpeg`)?.default;

const contact = require(`./common/temple-home-page/Shape 43.svg`)?.default;
const mailbox = require(`./common/temple-home-page/mail.svg`)?.default;
const globe = require(`./common/temple-home-page/Shape 42.svg`)?.default;
const location = require(`./common/temple-home-page/Shape 45.svg`)?.default;

const templeMythology =
  require(`./${templeImagePath}/temple-home-page/temple-mythology.png`)?.default;
const maintenance = require(`./common/maintenance.png`)?.default;
const signInIdol = require(`./common/sign_in_idol.png`)?.default;
const nityaAnnadanamTrust =
  require(`./common/Saswatha Nithya Annadanam Trust.png`)?.default;
const goSamrakshanaTrust =
  require(`./common/go samrakshana trust.png`)?.default;
//-----------banners-------------------//
const ssvvsd_home_banner_1 = require(`./banners/SSVVSD/banner1.webp`)?.default;
const ssvvsd_home_banner_2 = require(`./banners/SSVVSD/banner2.webp`)?.default;
const ssvvsd_home_banner_3 = require(`./banners/SSVVSD/banner3.webp`)?.default;
const ssvvsd_home_banner_4 = require(`./banners/SSVVSD/banner4.webp`)?.default;
const ssvvsd_home_banner_5 = require(`./banners/SSVVSD/banner5.webp`)?.default;
const ssvvsd_home_banner_6 = require(`./banners/SSVVSD/banner6.webp`)?.default;

const svlnsd_home_banner_1 = require(`./banners/SVLNSD/banner1.webp`)?.default;
const svlnsd_home_banner_2 = require(`./banners/SVLNSD/banner2.webp`)?.default;
const svlnsd_home_banner_3 = require(`./banners/SVLNSD/banner3.webp`)?.default;

const svsvd_home_banner_1 = require(`./banners/SVSVD/banner1.webp`)?.default;

const skhtd_home_banner_1 = require(`./banners/SKHTD/banner1.webp`)?.default;

const sdmsd_home_banner_1 = require(`./banners/SDMSD/banner1.webp`)?.default;
const sdmsd_home_banner_2 = require(`./banners/SDMSD/banner2.webp`)?.default;
const sdmsd_home_banner_3 = require(`./banners/SDMSD/banner3.webp`)?.default;
const sdmsd_home_banner_4 = require(`./banners/SDMSD/banner4.webp`)?.default;
//-----------banners-------------------//

const theHistory =
  require(`./${templeImagePath}/temple-home-page/the_history.png`)?.default;
const sd_Logo_circle = require(`./${templeImagePath}/Logo-circle.png`)?.default;
const bannerAlt =
  require(`./${templeImagePath}/temple-home-page/bannerAlt.webp`)?.default;
const sd_divider =
  require(`./${templeImagePath}/divider/SD-divider.svg`)?.default;
const ganapathi =
  require(`./${templeImagePath}/temple-home-page/ganapathi.webp`)?.default;
const bluebackground =
  require(`./${templeImagePath}/temple-home-page/blue-background.svg`)?.default;
const sun_icon =
  require(`./${templeImagePath}/temple-home-page/Group 5796.svg`)?.default;
const hand_icon =
  require(`./${templeImagePath}/temple-home-page/Group 5798.svg`)?.default;
const blue_arrow =
  require(`./${templeImagePath}/temple-home-page/blue-arrow.svg`)?.default;
const template5 =
  require(`./${templeImagePath}/temple-home-page/Group 5789@2x.webp`)?.default;
const newspaper =
  require(`./${templeImagePath}/temple-home-page/newspaper.svg`)?.default;
const sd_gallery_01 =
  require(`./${templeImagePath}/temple-home-page/tempel view (1)@2x.webp`)?.default;
const sd_gallery_02 =
  require(`./${templeImagePath}/temple-home-page/IMG-20220413-WA0016@2x.webp`)?.default;
const sd_gallery_03 =
  require(`./${templeImagePath}/temple-home-page/IMG-20220413-WA0018@2x.webp`)?.default;
const sd_gallery_04 =
  require(`./${templeImagePath}/temple-home-page/IMG-20220413-WA0023@2x.webp`)?.default;
const sd_gallery_05 =
  require(`./${templeImagePath}/temple-home-page/DSC_0263@2x.webp`)?.default;
const sd_gallery_06 =
  require(`./${templeImagePath}/temple-home-page/seshavaham.png`)?.default;
const seshavaham =
  require(`./${templeImagePath}/temple-home-page/seshavaham.png`)?.default;
const sd_gallery_07 =
  require(`./${templeImagePath}/temple-home-page/14@2x.webp`)?.default;
const sd_gallery_08 =
  require(`./${templeImagePath}/temple-home-page/homam.png`)?.default;
const sd_gallery_09 =
  require(`./${templeImagePath}/temple-home-page/sathyaPramanalaNilayam.png`)?.default;
const homam =
  require(`./${templeImagePath}/temple-home-page/homam.png`)?.default;
const intellectual =
  require(`./${templeImagePath}/temple-home-page/tempel view (1)@2x.webp`)?.default;

export {
  signInIdol,
  maintenance,
  templeMythology,
  theHistory,
  contact,
  mailbox,
  globe,
  location,
  sd_ticket_banner,
  gajapthi,
  kaniapkaadevasthanam,
  accommodationb,
  kalyanaMandapam,
  kalyana,
  bannerAlt,
  parokshaseva,
  chairman,
  EO_SDMSD,
  EO_SSVVSD,
  intellectual,
  flower,
  loader,
  calender,
  template5,
  nine,
  sun_icon,
  send_icon,
  mail_icon,
  hand_icon,
  seshavaham,
  ganapathi,
  bluebackground,
  homam,
  newspaper,
  seva_and_darshanam,
  donation,
  accommodation,
  sd_404,
  sd_email_header,
  sd_eye,
  sd_script,
  sd_eye_close,
  sd_white_plus,
  sd_edit_black,
  sd_contact_us_icon,
  sd_facilities,
  sd_faq_icon,
  sd_connectivity,
  sd_press_icon,
  sd_rta_icon,
  sd_mediakit_icon,
  sd_tenders_icon,
  sd_whats_new_icon,
  sd_gallery_icon,
  sd_call_outline,
  sd_map_outline,
  sd_trash,
  sd_chat_outline,
  sd_mail_outline,
  sd_move_left,
  upcomingBanner1,
  sd_move_right,
  sd_btn_loading,
  sd_flower,
  sd_t_websiter,
  sd_t_phone,
  sd_t_email,
  sd_rupee_white,
  sd_alarm,
  sd_rupee,
  sd_view,
  sd_side_arrow,
  sd_infor_icon,
  orange_x,
  sd_success_icon,
  sd_failure_icon,
  ssvvsd_home_banner_1,
  ssvvsd_home_banner_2,
  ssvvsd_home_banner_3,
  ssvvsd_home_banner_4,
  ssvvsd_home_banner_5,
  ssvvsd_home_banner_6,
  svlnsd_home_banner_1,
  svlnsd_home_banner_2,
  svlnsd_home_banner_3,
  svsvd_home_banner_1,
  skhtd_home_banner_1,
  sdmsd_home_banner_1,
  sdmsd_home_banner_2,
  sdmsd_home_banner_3,
  sdmsd_home_banner_4,
  sd_dashboard_edit_ico,
  sd_dashboard_dob,
  sd_popup_close,
  sd_dashboard_phone_ico,
  sd_dashboard_side_arrow,
  sd_news_list_icon,
  sd_srisaila_loading_logo,
  sd_general_indo,
  sd_temple_book,
  sd_temple_icon,
  sd_dashboard_upcoming,
  sd_dashboard_accomadation,
  sd_dashboard_seva,
  sd_dashboard_donation,
  sd_check,
  orange_arrow,
  blue_arrow,
  sd_dashboard_srisaila_prabha,
  sd_profile_icon_dashboard,
  sd_plus1,
  sd_plus2,
  sd_plus3,
  sd_faq_plus,
  sd_faq_minus,
  sd_india_flag,
  sd_wishlist_heart_active,
  sd_share,
  sd_wishlist_heart_unactive,
  sd_online_booking_icon,
  sd_support,
  sd_booking_history_icon,
  sd_myaccount_icon,
  sd_upload_image,
  sd_logout_icon,
  sd_default_profile_pic,
  sd_devotee_dashboard,
  sd_minus_icon,
  sd_plus_icon,
  sd_instagram_icon_white,
  sd_youtube_icon_white,
  sd_facebook_icon_white,
  sd_twitter_icon_white,
  sd_arrow_right_orange as SD_arrow_right_orange,
  sd_map_icon,
  sd_right_arrow_blue as SD_Right_Arrow_Blue,
  sd_right_arrow_white as SD_Right_Arrow_white,
  sd_hamburger as SD_Hamburger,
  sd_call_icon as SD_Call_Icon,
  sd_print_icon as SD_Print_Icon,
  sd_web_icon as SD_Web_Icon,
  sd_Logo_circle as SD_Logo_circle,
  sd_dropdown_arrow_icon as SD_Dropdown_Arrow_icon,
  sd_divider as SD_divider,
  sd_info_icon as SD_info_icon,
  sd_cart_icon as SD_cart_icon,
  sd_search_icon as SD_search_icon,
  sd_instagram_icon as SD_instagram_icon,
  sd_facebook_icon as SD_facebook_icon,
  sd_twitter_icon as SD_twitter_icon,
  sd_youtube_icon as SD_youtube_icon,
  sd_srisaila_tv_icon as SD_Srisaila_TV_icon,
  sd_print_a_ticket_icon as SD_Print_a_ticket_icon,
  sd_sign_in_icon as SD_Sign_In_icon,
  sd_email_icon as SD_email_icon,
  sd_playstore_icon as SD_playstore_icon,
  sd_newsletter_arrow_icon as SD_newsletter_arrow_icon,
  sd_appstore_icon as SD_appstore_icon,
  sd_gallery_01 as SD_gallery_01,
  sd_gallery_02 as SD_gallery_02,
  sd_gallery_03 as SD_gallery_03,
  sd_gallery_04 as SD_gallery_04,
  sd_gallery_05 as SD_gallery_05,
  sd_gallery_06 as SD_gallery_06,
  sd_gallery_07 as SD_gallery_07,
  sd_gallery_08 as SD_gallery_08,
  sd_gallery_09 as SD_gallery_09,
  sd_accomadation as SD_accomadation,
  greaterthan,
  sd_annadanam as SD_annadanam,
  nityaAnnadanamTrust as Nitya_Annadanam_Trust,
  goSamrakshanaTrust as Go_Samrakshana_Trust,
};
