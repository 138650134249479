import React, { useContext } from "react";
import "../../../Utils/sd-styles/sd-main-sup.scss";
import pagesData from "../../../Utils/data/pages.data";
import { SD_Context } from "../../../context/sd-context";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";

const TemplateFive = ({ history }) => {
  //-------------------------------------------------------------states--------------------------------------------------------//
  const { language } = useContext(SD_Context);
  //-------------------------------------------------------------states--------------------------------------------------------//

  //-------------------------------------------------------------useEffects--------------------------------------------------------//

  //-------------------------------------------------------------useEffects--------------------------------------------------------//

  return (
    <div className="sd-template-six ">
      <div className="sd-t6-one">
        <img src={pagesData("home")?.aboutTemple["img"]} alt="alt" />
      </div>

      <div className="sd-t6-two">
        <div>
          <h1>{pagesData("home")?.aboutTemple["title"]}</h1>
          <img
            src={pagesData("home")?.divider}
            alt="-"
            style={{ height: "20px" }}
          ></img>

          {pagesData("home")?.aboutTemple?.mainDesc?.map((item, i) => {
            return (
              <p style={{ marginTop: "0", marginBottom: "10px" }} key={i}>
                {item}
              </p>
            );
          })}
          {pagesData("home")?.aboutTemple?.smallDesc?.map((item, i) => {
            return (
              <h5 style={{ marginTop: "0", marginBottom: "10px" }} key={i}>
                {item}
              </h5>
            );
          })}
          <div className="temples">
            {" "}
            <div
              onClick={() => {
                history.push(`about/the-temple/about-the-temple`);
              }}
            >
              <img src={pagesData("home")?.secondaryArrow} height="20px" />
              The Temple
            </div>
            <div
              onClick={() => {
                history.push(`about/the-temple-history/the-history`);
              }}
            >
              <img src={pagesData("home")?.secondaryArrow} height="20px" />
              {pagesData("commonTitles", "History")}
            </div>
            <div
              onClick={() => {
                history.push(`about/general-information/visiting-places`);
              }}
            >
              <img src={pagesData("home")?.secondaryArrow} height="20px" />
              General Information
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default withRouter(TemplateFive);
