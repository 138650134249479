import Axios from "../Utils/sd-axios/Axios";

const AddonService = {
  fetchAddon: async (id, serviceId, serviceType, date) => {
    try {
      const resp = await Axios.get(
        `/add-on/online-service-add-ons?serviceId=${serviceId}&serviceType=${serviceType}&bookingDate=${date}`,
        {
          headers: {
            "X-Temple-Code": id,
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      return resp.data;
    } catch (error) {
      if (error?.response?.statusCode === 401) {
        throw new Error("Session Expired");
      } else if (
        error?.response?.statusCode !== 500 &&
        error?.response?.message
      ) {
        throw new Error(error?.response?.message);
      } else {
        //
        // throw new Error(error?.response?.data?.message ? error?.response?.data?.message : error?.response?.message ?error?.response?.message: "something is wrong");
      }
    }
  },
};

export default AddonService;
