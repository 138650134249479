import React, { useContext, useState, useEffect } from "react";
import { Switch, Route, withRouter, Redirect } from "react-router-dom";
import { SD_Context } from "./context/sd-context";
import New_Nav from "./Components/sd-navbar/sd-new-nav";
import Footer from "./Components/sd-footer/sd-footer";
import Sd_Routes from "./sd-routes";
import UserService from "./services/sd-user.service";
import Sd_error_page from "./pages/sd-error-pages/sd-error-response";

import ErrorBoundary from "./context/sd-error-boundary";
import { colors } from "./Utils/data/colors.data";
import getTempleShortCodeFromDomain from "./Utils/data/templeId.data";
// import Sd_loading from "./Components/sd-common-components/sd-loading";
import TmsScrollToTop from "./Utils/sd-common/tms-scroll-to-top";

import Maintenance from "./pages/maintenance/maintenance";
// import useUniqueId from "./Utils/sd-common/tms-uniqueId";
const App = ({ history }) => {
  const {
    setUserData,
    refContainer,
    templeID,
    getFavorities,
    setlanguage,
    getVisitors,
    languageList,
  } = useContext(SD_Context);

  const [maintenance, setMaintenance] = useState(false);
  const [loading, setLoading] = React.useState(true);

  const dynamicColors = (colors) => {
    const root = document.documentElement;
    Object.entries(colors).forEach(([property, value]) => {
      root?.style.setProperty(property, value);
    });
  };

  const temple =
    process.env.REACT_APP_TEMPLE ??
    getTempleShortCodeFromDomain(window?.location?.hostname);

  useEffect(async () => {
    const lang = languageList.find(
      (item) => item?.code === history?.location?.pathname?.split?.("/")?.[1]
    );
    if (lang) {
      setlanguage(lang);
    } else {
      setlanguage({ desc: "ENGLISH", code: "en-in" });
      setLoading(false);
    }

    getVisitors();
    AuthFunction();
    dynamicColors(colors[temple]);
  }, []);

  useEffect(() => {
    getFavorities();
  }, [localStorage.getItem("accessToken")]);

  const AuthFunction = async () => {
    const res = await UserService.getTempleConfig(templeID);
    if (!res.onlineBookingEnabled) {
      setMaintenance(true);
      setLoading(false);
      return;
    }
    if (localStorage.getItem("accessToken")) {
      try {
        setLoading(true);
        let data = await UserService.checkToken(templeID);
        setUserData(data);
        setLoading(false);
      } catch (error) {
        localStorage.clear();
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  };

  return (
    <>
      {!loading && !maintenance ? (
        <div className="sd-main" id="myDIV" name="myDIV">
          <TmsScrollToTop />
          <New_Nav />
          <div ref={refContainer}></div>
          <ErrorBoundary>
            <Switch>
              <Route
                path="/:lang"
                render={(props) => {
                  const lang = languageList.find(
                    (item) => item?.code === props?.match?.params?.lang
                  );

                  if (
                    props?.history?.location?.pathname?.replaceAll?.(
                      "/",
                      ""
                    ) === props?.match?.params?.lang &&
                    lang
                  ) {
                    return (
                      <Redirect to={`/${props?.match?.params?.lang}/home`} />
                    );
                  }
                  return lang ? (
                    <Sd_Routes {...props} />
                  ) : (
                    <Redirect
                      to={`/en-in${props?.history?.location?.pathname}${props?.history?.location?.search}`}
                    />
                  );
                }}
              />
              <Route
                path="*"
                render={(props) => {
                  return (
                    <Redirect
                      to={`/en-in${props?.history?.location?.pathname}${props?.history?.location?.search}`}
                    />
                  );
                }}
              />
            </Switch>
            <Footer />
          </ErrorBoundary>
        </div>
      ) : maintenance ? (
        <>
          <Maintenance />
        </>
      ) : (
        <></>
      )}
      <div className="sd-error">
        <Sd_error_page type="resolution" />
      </div>
    </>
  );
};
App.displayName = "Apps";

export default withRouter(App);
