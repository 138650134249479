import getTempleShortCodeFromDomain from "./templeId.data";

const id =
  process.env.REACT_APP_TEMPLE ??
  getTempleShortCodeFromDomain(window?.location?.hostname);

const languagesList = {
  SVSVD: [{ desc: "ENGLISH", code: "en-in" }],
  SKHTD: [{ desc: "ENGLISH", code: "en-in" }],
  SVLNSD: [
    { desc: "ENGLISH", code: "en-in" },
    { desc: "తెలుగు", code: "te-in" },
  ],
  SSVVSD: [{ desc: "ENGLISH", code: "en-in" }],
  SDMSD: [{ desc: "ENGLISH", code: "en-in" }],
};

export default languagesList[id];
