/* eslint-disable no-constant-condition */
import React, { useContext } from "react";
import { withRouter } from "react-router-dom";
import {
  greaterthan,
  sd_alarm,
  SD_Right_Arrow_white,
  sd_rupee,
} from "../../Assets/imageList";
import "../../Utils/sd-styles/sd-home.scss";
import IndiaTime from "../../Utils/sd-moment/IndiaTime";
import { SD_Context } from "../../context/sd-context";

const Sd_breadcrumb = ({ from, history, data }) => {
  //-------------------------------------------------------------states-----------------------------------------------------------//
  const { language } = useContext(SD_Context);

  //-------------------------------------------------------------states-----------------------------------------------------------//

  //-------------------------------------------------------------consts-----------------------------------------------------------//
  const faqData = [
    "park_lk",
    "paroksha_seva",
    "prak_chandi_homam",
    "park_ganapthi_homam",
    "park_kumkumarchana",
    "park_leela_kalyanotsavam",
    "park_mrityunkjaya",
    "park_rudra_homam",
    "park_rudra_abhishekam",
    "park_valli_devasena",
    "park_vedasirvachanam",
    "park_ekantha_Seva",
    "online_accommodation",
    "aagama_patasala_trust",
    "anna_prasadam_trust",
    "darshanam",
    "donations",
    "e_hundi",
    "go_samrakshana_trust",
    "online_booking",
    "pranadhana_trust",
    "saswata_seva",
    "SBMSD_trust",
    "signin_up",
    "swacha_srisailam",
    "volunteer",
    "about",
    "pratyaksha_seva",
    "media_room",
    "shop",
    "cart",
    "support",
    "search",
    "sevas_darshanam",
  ];
  const faqDatNames = {
    paroksha_seva: "Paroksha Seva",
    park_lk: "Paroksha Seva (Laksha Kumkumarchana)",
    prak_chandi_homam: "Paroksha Seva (Chandi Homam)",
    park_ganapthi_homam: "Paroksha Seva (Ganapathi Homam)",
    park_kumkumarchana: "Paroksha Seva (Kumkumarchana)",
    park_leela_kalyanotsavam: "Paroksha Seva (Leela Kalyanotsavam)",
    park_mrityunkjaya: "Paroksha Seva (Mrityunjaya Homam)",
    park_rudra_homam: "Paroksha Seva (Rudra Homam)",
    park_rudra_abhishekam: "Paroksha Seva (Rudra Abhisheksam)",
    park_valli_devasena:
      "Paroksha Seva (Valli Devasena Subramanyana Swamy Kalyanam)",
    park_vedasirvachanam: "Paroksha Seva (Vedasirvachanam)",
    park_ekantha_Seva: "Paroksha Seva (Ekantha Seva)",
    online_accommodation: "Accommodation",
    aagama_patasala_trust: "Aagama Patasala Trust",
    anna_prasadam_trust: "AnnaPrasadam Trust",
    darshanam: "Darshanam",
    donations: "Donations",
    e_hundi: "E-Hundi",
    go_samrakshana_trust: "GoSamrakshana Trust",
    online_booking: "Online Booking",
    pranadhana_trust: "Prandhana Trust",
    saswata_seva: "Sawata Seva",
    SBMSD_trust: "SBMSD Trust",
    signin_up: "Signin / Signup",
    swacha_srisailam: "Swachh Srisailam",
    volunteer: "volunteer",
    about: "about",
    pratyaksha_seva: "Pratyaksha Seva",
    media_room: "Media Room",
    shop: "Shop",
    cart: "Cart",
    support: "Suppport",
    search: "Search",
    sevas_darshanam: "Sevas and Darshanam",
  };
  const parokshasevaarray = [
    "Sri Ganapathi Pooja",
    "Mrityunjaya Homam",
    "Rudraabhishekam",
    "Ganapathi Homam",
    "Nandeeshwara Swamy vari Visesharchana",
    "Ekantha Seva",
    "Rudra Homam",
    "Chandi Homam",
    "Leela Kalyanotsavam",
    "Valli Devasena sametha Subramanyeswara Swamy Kalyanam",
    "Vedasirvachanam",
    "Kumkumarchana",
    "Sri Bayalu Veerabhadra Swamy Vari Visesharchana",
    "Laksha Kumkumarchana",
    "Varalakshmi Vratham",
    "Mahanyasa Purvaka Rudrabhishekam",
  ];
  const donationsArray = [
    "Annaprasadam",
    "Aagama Patasala",
    "GoSamrakshana",
    "E-Hundi",
    "Pranadana Trust",
    "SBMSD Trust",
    "Swachh Srisailam",
    "Srisaila TV",
  ];
  const aboutArray = ["12 Jyotirlingas"];

  const pratyakshasevaArray = [
    "p-Mrityunjaya Homam",
    "p-VIP Break Darshanam",
    "p-Abhishekam at Garbhalayam",
    "p-Abhishekam Alakara Mandapam",
    "p-Sri Swamy Ammavarla Kalyanam",
    "p-Sri Subramanyeswara Swamy Kalyanam",
    "p-Samuhika Abhishekamulu",
    "p-Chandi Homam",
    "p-Ekantha Seva",
    "p-Sri Sakshi Ganapathi Homam",
    "p-Kalyanotsavam",
    "p-Kumkumarchana in Aseervachana Mandapam",
    "p-Kumkuma Pooja Sri Chakram",
    "p-Laksha Bilvarchakam",
    "p-Laksha Kumkumarchana",
    "p-Mahanyasa Purvaka Rudrabhishekam",
    "p-Rudra Homam",
    "p-Sarva Seva Pathakam",
    "p-Suprabhata Seva",
    "p-Vruddha Mallikarjuna Swamy Vaari Abhishekam",
    "p-Valli Devasena sametha Subramanyeswara Swamy Kalyanam",
    "p-Kumkumarchana in Antharalayam",
    "p-Leela Kalyanotsavam",
  ];
  const supportArray = [
    "Support",
    "Support-Contact Us",
    "Support-Facilities to Pilgrims",
    "Support-Connectivity",
  ];

  const dashboardBasic = ["Dashboard", "Booking History", "Dashboard-Support"];

  const dashboardOnlinebooking = [
    "Onlinebooking-Darshanam",
    "Onlinebooking-Donations",
    "Onlinebooking-Accommodations",
    "Onlinebooking-Pratyaksha Seva",
    "Onlinebooking-Paroksha Seva",
    "Onlinebooking-Saswata Seva",
    "Onlinebooking-Kalyana Katta",
    "Onlinebooking-Prasadam",
    "Onlinebooking-Kalayana Madapam",
    "Onlinebooking-Publications",
    "Onlinebooking-Paroksha Seva-Enter Details",
    "Onlinebooking-Donations-Confirm",
    "Onlinebooking-Darshanam-Confirm Details",
    "Onlinebooking-Accommodations-Confirm",
    "Onlinebooking-Pratyaksha Seva-Enter Details",
    "Onlinebooking-Publications",
    "Onlinebooking-Publications-Confirm",
  ];

  const dashboardMyaccount = [
    "My Account-Devotee Master List",
    "My Account-Change Password",
    "My Account-My Favorites",
    "My Account-Settings",
    "My Account-My Profile",
  ];

  const mediaRoom = [
    "Media Room",
    "Media Room-Media Kit",
    "Media Room-Gallery",
    "Media Room-Whats New",
    "Media Room-Press Temple News",
  ];
  //-------------------------------------------------------------consts-----------------------------------------------------------//

  //-------------------------------------------------------------useffects-----------------------------------------------------------//
  React.useEffect(() => {
    //
  }, []);
  //-------------------------------------------------------------useeffects-----------------------------------------------------------//
  return (
    <>
      <div className={"breadcrumb-div"}>
        {/* <HorizontalScroll> */}
        <div className="bc-left">
          <p
            className="voilet"
            onClick={() => {
              history?.push(`/${language?.code}/home`);
            }}
          >
            Home
          </p>
          {from ===
            "Onlinebooking-Paroksha Seva-Enter Details-Confirm Details" && (
            <>
              <img src={greaterthan} className="mr-2 ml-2" alt=">" />
              <p
                className="voilet"
                onClick={() => {
                  history?.push(
                    `/${language?.code}/devotee-app/devotee-dashboard`
                  );
                }}
              >
                Devotee
              </p>
              <img src={greaterthan} className="mr-2 ml-2" alt=">" />
              <p
                className="voilet"
                onClick={() => {
                  history?.push(`/${language?.code}/online-booking`);
                }}
              >
                Online Booking
              </p>
              <img src={greaterthan} className="mr-2 ml-2" alt=">" />
              <p
                className="voilet"
                onClick={() => {
                  history?.push(
                    `/${language?.code}/devotee-app/online-booking/paroksha-seva`
                  );
                }}
              >
                Paroksha Seva
              </p>
              <img src={greaterthan} className="mr-2 ml-2" alt=">" />
              <p
                className="voilet"
                onClick={() => {
                  history?.push(
                    `/${language?.code}/devotee-app/online-booking/paroksha-seva/booking/false`
                  );
                }}
              >
                Edit Details
              </p>
              <img src={greaterthan} className="mr-2 ml-2" alt=">" />
              <p
                className="voilet"
                onClick={() => {
                  history?.push(
                    `/${language?.code}/devotee-app/online-booking/paroksha-seva/confirm-details`
                  );
                }}
              >
                Confirm Details{" "}
              </p>
            </>
          )}

          {from ===
            "Onlinebooking-Pratyaksha Seva-Enter Details-Confirm Details" && (
            <>
              <img src={greaterthan} className="mr-2 ml-2" alt=">" />
              <p
                className="voilet"
                onClick={() => {
                  history?.push(
                    `/${language?.code}/devotee-app/devotee-dashboard`
                  );
                }}
              >
                Devotee
              </p>
              <img src={greaterthan} className="mr-2 ml-2" alt=">" />
              <p
                className="voilet"
                onClick={() => {
                  history?.push(`/${language?.code}/online-booking`);
                }}
              >
                Online Booking
              </p>
              <img src={greaterthan} className="mr-2 ml-2" alt=">" />
              <p
                className="voilet"
                onClick={() => {
                  history?.push(
                    `/${language?.code}/devotee-app/online-booking/pratyaksha-seva`
                  );
                }}
              >
                Pratyaksha Seva
              </p>
              <img src={greaterthan} className="mr-2 ml-2" alt=">" />
              <p
                className="voilet"
                onClick={() => {
                  history?.push(
                    `/${language?.code}/devotee-app/online-booking/pratyaksha-seva/booking/suprabhata-seva`
                  );
                }}
              >
                Edit Details
              </p>
              <img src={greaterthan} className="mr-2 ml-2" alt=">" />
              <p
                className="voilet"
                onClick={() => {
                  history?.push(
                    `/${language?.code}/devotee-app/online-booking/pratyaksha-seva/confirm-details`
                  );
                }}
              >
                Confirm Details{" "}
              </p>
            </>
          )}

          {dashboardBasic.includes(from) ? (
            <>
              <img src={greaterthan} className="mr-2 ml-2" alt=">" />
              <p
                className="voilet"
                onClick={() => {
                  history?.push(
                    `/${language?.code}/devotee-app/devotee-dashboard`
                  );
                }}
              >
                Devotee
              </p>
              <img src={greaterthan} className="mr-2 ml-2" alt=">" />
              <p
                onClick={() => {
                  history?.push(
                    `/${language?.code}/devotee-app/devotee-dashboard`
                  );
                }}
              >
                {from}
              </p>
            </>
          ) : (
            from === "dashboard-support" && (
              <>
                <img src={greaterthan} className="mr-2 ml-2" alt=">" />
                <p
                  className="voilet"
                  onClick={() => {
                    history?.push(
                      `/${language?.code}/devotee-app/devotee-dashboard`
                    );
                  }}
                >
                  Devotee
                </p>
                <img src={greaterthan} className="mr-2 ml-2" alt=">" />
                <p>Support</p>
              </>
            )
          )}
          {from === "Donor Module" && (
            <>
              <img src={greaterthan} className="mr-2 ml-2" alt=">" />
              <p
                className="voilet"
                onClick={() => {
                  history?.push(`/${language?.code}/devotee-app/donor-module`);
                }}
              >
                Devotee
              </p>
              <img src={greaterthan} className="mr-2 ml-2" alt=">" />
              <p>Donor Module</p>
            </>
          )}

          {dashboardOnlinebooking.includes(from) && (
            <>
              <img src={greaterthan} className="mr-2 ml-2" alt=">" />
              <p
                className="voilet"
                onClick={() => {
                  history?.push(
                    `/${language?.code}/devotee-app/devotee-dashboard`
                  );
                }}
              >
                Devotee
              </p>
              <img src={greaterthan} className="mr-2 ml-2" alt=">" />
              <p
                className="voilet"
                onClick={() => {
                  history?.push(`/${language?.code}/online-booking`);
                }}
              >
                Online Booking
              </p>
              <img src={greaterthan} className="mr-2 ml-2" alt=">" />
              <p
                className={from.split("-")[2] ? "voilet" : ""}
                onClick={() => {
                  history?.push(
                    `/${language?.code}/devotee-app/online-booking/${from
                      .split("-")[1]
                      ?.replace?.(/\s/g, "-")
                      .toLowerCase()}`
                  );
                }}
              >
                {from.split("-")[1]}
              </p>
              {from.split("-")[2] ? (
                <>
                  {" "}
                  <img src={greaterthan} className="mr-2 ml-2" alt=">" />{" "}
                  <p
                    className={from.split("-")[3] ? "voilet" : ""}
                    onClick={() => {
                      from.split("-")[3]
                        ? history?.push(
                            `/${
                              language?.code
                            }/devotee-app/online-booking/${from
                              .split("-")[1]
                              ?.replace?.(/\s/g, "-")
                              .toLowerCase()}/${from
                              .split("-")[2]
                              ?.replace?.(/\s/g, "-")
                              .toLowerCase()}`
                          )
                        : "";
                    }}
                  >
                    {from.split("-")[2]}
                  </p>
                </>
              ) : (
                ""
              )}
              {from.split("-")[3] ? (
                <>
                  {" "}
                  <img src={greaterthan} className="mr-2 ml-2" alt=">" />{" "}
                  <p>{from.split("-")[3]}</p>
                </>
              ) : (
                ""
              )}
            </>
          )}
          {dashboardMyaccount.includes(from) && (
            <>
              <img src={greaterthan} className="mr-2 ml-2" alt=">" />

              <p
                className="voilet"
                onClick={() => {
                  history?.push(
                    `/${language?.code}/devotee-app/devotee-dashboard`
                  );
                }}
              >
                Devotee
              </p>
              {/* <img src={greaterthan} className="mr-2 ml-2" alt=">" />
            <p
              className="voilet"
              onClick={() => {
                history?.push(
                  `/${language?.code}/devotee-app/devotee-dashboard`
                );
              }}
            >
              Dashboard
            </p> */}
              <img src={greaterthan} className="mr-2 ml-2" alt=">" />
              <p
                className="voilet"
                onClick={() => {
                  history?.push(
                    `/${language?.code}/devotee-app/my-account/profile`
                  );
                }}
              >
                My Account
              </p>
              <img src={greaterthan} className="mr-2 ml-2" alt=">" />
              <p>{from.split("-")[1]}</p>
            </>
          )}
          {from === "Paroksha-seva" ? (
            <>
              <img src={greaterthan} className="mr-2 ml-2" alt=">" />
              <p
                className="voilet"
                onClick={() => {
                  history?.push(`/${language?.code}/sevas-and-darshanam`);
                }}
              >
                Sevas & Darshanam
              </p>
              <img src={greaterthan} className="mr-2 ml-2" alt=">" />
              <p
                onClick={() => {
                  history?.push(
                    `/${language?.code}/sevas-and-darshanam/paroksha-seva`
                  );
                }}
              >
                Paroksha Seva
              </p>
            </>
          ) : parokshasevaarray.includes(from) ? (
            <>
              <img src={greaterthan} className="mr-2 ml-2" alt=">" />
              <p
                className="voilet"
                onClick={() => {
                  history?.push(`/${language?.code}/sevas-and-darshanam`);
                }}
              >
                Sevas & Darshanam
              </p>
              <img src={greaterthan} className="mr-2 ml-2" alt=">" />
              <p
                className="voilet"
                onClick={() => {
                  history?.push(
                    `/${language?.code}/sevas-and-darshanam/paroksha-seva`
                  );
                }}
              >
                Paroksha Seva
              </p>
              <img src={greaterthan} className="mr-2 ml-2" alt=">" />
              <p>{from}</p>
            </>
          ) : from === "Pratyaksha Seva" ? (
            <>
              <img src={greaterthan} className="mr-2 ml-2" alt=">" />
              <p
                className="voilet"
                onClick={() => {
                  history?.push(`/${language?.code}/sevas-and-darshanam`);
                }}
              >
                Sevas & Darshanam
              </p>
              <img src={greaterthan} className="mr-2 ml-2" alt=">" />
              <p
                onClick={() => {
                  history?.push(
                    `/${language?.code}/sevas-and-darshanam/pratyaksha-seva`
                  );
                }}
              >
                Pratyaksha Seva
              </p>
            </>
          ) : pratyakshasevaArray.includes(from) ? (
            <>
              <img src={greaterthan} className="mr-2 ml-2" alt=">" />
              <p
                className="voilet"
                onClick={() => {
                  history?.push(`/${language?.code}/sevas-and-darshanam`);
                }}
              >
                Sevas & Darshanam
              </p>
              <img src={greaterthan} className="mr-2 ml-2" alt=">" />
              <p
                className="voilet"
                onClick={() => {
                  history?.push(
                    `/${language?.code}/sevas-and-darshanam/pratyaksha-seva`
                  );
                }}
              >
                Pratyaksha Seva
              </p>
              <img src={greaterthan} className="mr-2 ml-2" alt=">" />
              <p>{from?.split("-")[1]}</p>
            </>
          ) : from === "Saswata Seva" ? (
            <>
              <img src={greaterthan} className="mr-2 ml-2" alt=">" />
              <p>Saswata Seva</p>
            </>
          ) : from === "Sevas & Darshanam" ? (
            <>
              <img src={greaterthan} className="mr-2 ml-2" alt=">" />
              <p className="voilet">Sevas & Darshanam</p>
            </>
          ) : from === "The Temple Story" ||
            from === "The Temple" ||
            from === "General Information" ||
            from === "The History" ? (
            <>
              <img src={greaterthan} className="mr-2 ml-2" alt=">" />
              <p
                onClick={() => {
                  history?.push(`/${language?.code}/about`);
                }}
                className="voilet"
              >
                About
              </p>
              <img src={greaterthan} className="mr-2 ml-2" alt=">" />
              <p>{from}</p>
            </>
          ) : from === "darshanam" ? (
            <>
              <img src={greaterthan} className="mr-2 ml-2" alt=">" />
              <p className="voilet">Sevas & Darshanam</p>
              <img src={greaterthan} className="mr-2 ml-2" alt=">" />
              <p> Darshanam</p>
            </>
          ) : from === "login" ? (
            <>
              <img src={greaterthan} className="mr-2 ml-2" alt=">" />
              <p
                onClick={() => {
                  history?.push(
                    `/${language?.code}/devotee/signin?to=devotee-app/devotee-dashboard`
                  );
                }}
                className="voilet"
              >
                Devotee
              </p>
              <img src={greaterthan} className="mr-2 ml-2" alt=">" />
              <p>Sign In</p>
            </>
          ) : from === "signup" ? (
            <>
              <img src={greaterthan} className="mr-2 ml-2" alt=">" />
              <p
                onClick={() => {
                  history?.push(
                    `/${language?.code}/devotee/signin?to=devotee-app/devotee-dashboard`
                  );
                }}
                className="voilet"
              >
                Devotee
              </p>
              <img src={greaterthan} className="mr-2 ml-2" alt=">" />
              <p>Sign Up</p>
            </>
          ) : from === "assistance" ? (
            <>
              <img src={greaterthan} className="mr-2 ml-2" alt=">" />
              <p
                onClick={() => {
                  history?.push(
                    `/${language?.code}/devotee/signin?to=devotee-app/devotee-dashboard`
                  );
                }}
                className="voilet"
              >
                Devotee
              </p>
              <img src={greaterthan} className="mr-2 ml-2" alt=">" />
              <p
                className="voilet"
                onClick={() => {
                  history?.push(
                    `/${language?.code}/devotee/signin?to=devotee-app/devotee-dashboard`
                  );
                }}
              >
                Sign In
              </p>
              <img src={greaterthan} className="mr-2 ml-2" alt=">" />
              <p>Get Help</p>
            </>
          ) : from === "reset" || from === "verify-otp" ? (
            <>
              <img src={greaterthan} className="mr-2 ml-2" alt=">" />
              <p
                onClick={() => {
                  history?.push(
                    `/${language?.code}/devotee/signin?to=devotee-app/devotee-dashboard`
                  );
                }}
                className="voilet"
              >
                Devotee
              </p>
              <img src={greaterthan} className="mr-2 ml-2" alt=">" />
              <p
                className="voilet"
                onClick={() => {
                  history?.push(
                    `/${language?.code}/devotee/signin?to=devotee-app/devotee-dashboard`
                  );
                }}
              >
                Sign In
              </p>

              <img src={greaterthan} className="mr-2 ml-2" alt=">" />
              <p>Reset password</p>
            </>
          ) : from === "guest-login" ? (
            <>
              <img src={greaterthan} className="mr-2 ml-2" alt=">" />
              <p
                onClick={() => {
                  history?.push(
                    `/${language?.code}/devotee/signin?to=devotee-app/devotee-dashboard`
                  );
                }}
                className="voilet"
              >
                Devotee
              </p>
              <img src={greaterthan} className="mr-2 ml-2" alt=">" />
              <p>Guest Sign In</p>
            </>
          ) : from === "dashboard" ? (
            <>
              <img src={greaterthan} className="mr-2 ml-2" alt=">" />
              <p>Devotee</p>
            </>
          ) : from === "faqs" ? (
            <>
              <img src={greaterthan} className="mr-2 ml-2" alt=">" />
              <p
                onClick={() => {
                  history?.push(`/${language?.code}/support`);
                }}
                className="voilet"
              >
                Support
              </p>
              <img src={greaterthan} className="mr-2 ml-2" alt=">" />
              <p>FAQs</p>
            </>
          ) : faqData.includes(from.split("-")[1]) ? (
            <>
              <img src={greaterthan} className="mr-2 ml-2" alt=">" />
              <p
                className="voilet"
                onClick={() => {
                  history?.push(`/${language?.code}/support`);
                }}
              >
                Support
              </p>
              <img src={greaterthan} className="mr-2 ml-2" alt=">" />
              <p
                className="voilet"
                onClick={() => {
                  history?.push(`/${language?.code}/support/faqs`);
                }}
              >
                FAQs
              </p>
              <img src={greaterthan} className="mr-2 ml-2" alt=">" />

              <p>{faqDatNames[from.split("-")[1]]}</p>
            </>
          ) : from === "volunteer" ? (
            <>
              <img src={greaterthan} className="mr-2 ml-2" alt=">" />
              <p>Get Involved</p>
            </>
          ) : from === "About" ? (
            <>
              <img src={greaterthan} className="mr-2 ml-2" alt=">" />
              <p className="voilet">About</p>
            </>
          ) : aboutArray.includes(from) ? (
            <>
              <img src={greaterthan} className="mr-2 ml-2" alt=">" />
              <p className="voilet">About</p>
              <img src={greaterthan} className="mr-2 ml-2" alt=">" />
              <p>{from}</p>
            </>
          ) : from === "trusts" ? (
            <>
              <img src={greaterthan} className="mr-2 ml-2" alt=">" />
              <p>Trusts</p>
            </>
          ) : from === "donations" ? (
            <>
              <img src={greaterthan} className="mr-2 ml-2" alt=">" />
              <p
                className="voilet"
                onClick={() => {
                  history?.push(`/${language?.code}/donations`);
                }}
              >
                Donations
              </p>
            </>
          ) : donationsArray.includes(from) ? (
            <>
              <img src={greaterthan} className="mr-2 ml-2" alt=">" />
              <p
                className="voilet"
                onClick={() => {
                  history?.push(`/${language?.code}/donations`);
                }}
              >
                Donations
              </p>
              <img src={greaterthan} className="mr-2 ml-2" alt=">" />
              <p>{from}</p>
            </>
          ) : from === "online-booking" ? (
            <>
              <img src={greaterthan} className="mr-2 ml-2" alt=">" />
              <p>Online Booking</p>
            </>
          ) : mediaRoom.includes(from) ? (
            <>
              <img src={greaterthan} className="mr-2 ml-2" alt=">" />
              <p
                className="voilet"
                onClick={() => {
                  history?.push(`/${language?.code}/media-room`);
                }}
              >
                Media Room
              </p>
              {from !== "Media Room" ? (
                <>
                  <img src={greaterthan} className="mr-2 ml-2" alt=">" />
                  <p>{from.split("-")[1]}</p>
                </>
              ) : (
                ""
              )}
            </>
          ) : supportArray.includes(from) ? (
            <>
              <img src={greaterthan} className="mr-2 ml-2" alt=">" />
              <p
                className="voilet"
                onClick={() => {
                  history?.push(`/${language?.code}/support`);
                }}
              >
                Support{" "}
              </p>
              {from !== "Support" && (
                <>
                  <img src={greaterthan} className="mr-2 ml-2" alt=">" />
                  <p>{from.split("-")[1]}</p>
                </>
              )}
            </>
          ) : from === "Terms and Conditions" || from === "Privacy Policy" ? (
            <>
              <img src={greaterthan} className="mr-2 ml-2" alt=">" />
              <p className="voilet">Legalities</p>
              <img src={greaterthan} className="mr-2 ml-2" alt=">" />
              <p>{from}</p>
            </>
          ) : from === "Gallery" ? (
            <>
              <img src={greaterthan} className="mr-2 ml-2" alt=">" />
              <p
                className="voilet"
                onClick={() => {
                  history?.push(`/${language?.code}/media-room`);
                }}
              >
                Media Room
              </p>
              <img src={greaterthan} className="mr-2 ml-2" alt=">" />
              <p>{from}</p>
            </>
          ) : from === "Print a Ticket" ? (
            <>
              <img src={greaterthan} className="mr-2 ml-2" alt=">" />
              <p
                className="voilet"
                onClick={() => {
                  if (localStorage?.getItem("accessToken")) {
                    history?.push(
                      `/${language?.code}/devotee-app/devotee-dashboard`
                    );
                  } else {
                    history?.push(
                      `/${language?.code}/devotee/signin?to=devotee/print-a-ticket`
                    );
                  }
                }}
              >
                Devotee
              </p>
              <img src={greaterthan} className="mr-2 ml-2" alt=">" />
              <p style={{ textTransform: "none" }}>Print a Ticket</p>
            </>
          ) : (
            ""
          )}
        </div>
        <div className="bc-right">
          {parokshasevaarray.includes(from) && (
            <div className="gp-breadcrumb">
              <div className="gpbd-left">
                <h3>
                  <img src={sd_alarm} alt="Rs." />{" "}
                  {IndiaTime(data?.start_date).format("hh:mm A") ===
                  IndiaTime(data?.end_date).format("hh:mm A") ? (
                    <>
                      {data.start_date &&
                        IndiaTime(data.start_date).format("hh:mm A")}
                    </>
                  ) : (
                    <>
                      {data.start_date &&
                        IndiaTime(data.start_date).format("hh:mm A")}{" "}
                      -
                      {data.end_date &&
                        IndiaTime(data.end_date).format("hh:mm A")}
                    </>
                  )}
                </h3>
              </div>
              <div>
                <p>
                  <img src={sd_rupee} alt="Rs." /> {data?.price}.00
                </p>
                {!data?.disabled && (
                  <button
                    onClick={() => {
                      if (localStorage.getItem("accessToken")) {
                        history?.push(
                          `/${
                            language?.code
                          }/devotee-app/online-booking/paroksha-seva/booking/${data?.name
                            ?.replace?.(/\s/g, "-")
                            .toLowerCase()}`
                        );
                      } else {
                        history?.push(
                          `/${
                            language?.code
                          }/devotee/signin?to=devotee-app/online-booking/paroksha-seva/booking/${data?.name
                            ?.replace?.(/\s/g, "-")
                            .toLowerCase()}`
                        );
                      }
                    }}
                  >
                    Book Now <img src={SD_Right_Arrow_white} alt=">" />
                  </button>
                )}
              </div>
            </div>
          )}
          {pratyakshasevaArray.includes(from) && (
            <div className="gp-breadcrumb">
              <div className="gpbd-left">
                <h3>
                  <img src={sd_alarm} alt="Rs." />{" "}
                  {IndiaTime(data?.start_date).format("hh:mm A") ===
                  IndiaTime(data?.end_date).format("hh:mm A") ? (
                    <>
                      {data.start_date &&
                        IndiaTime(data.start_date).format("hh:mm A")}
                    </>
                  ) : (
                    <>
                      {data.start_date &&
                        IndiaTime(data.start_date).format("hh:mm A")}{" "}
                      -
                      {data.end_date &&
                        IndiaTime(data.end_date).format("hh:mm A")}
                    </>
                  )}
                </h3>
              </div>
              <div>
                <p>
                  <img src={sd_rupee} alt="Rs." /> {data?.price}.00
                </p>
                {!data?.disabled && (
                  <button
                    onClick={() => {
                      if (localStorage.getItem("accessToken")) {
                        history?.push(
                          `/${
                            language?.code
                          }/devotee-app/online-booking/pratyaksha-seva/booking/${data?.name
                            ?.replace?.(/\s/g, "-")
                            .toLowerCase()}`
                        );
                      } else {
                        history?.push(
                          `/${
                            language?.code
                          }/devotee/signin?to=devotee-app/online-booking/pratyaksha-seva/booking/${data?.name
                            ?.replace?.(/\s/g, "-")
                            .toLowerCase()}`
                        );
                      }
                    }}
                  >
                    Book Now <img src={SD_Right_Arrow_white} alt=">" />
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
        {/* </HorizontalScroll> */}
      </div>
    </>
  );
};
export default withRouter(Sd_breadcrumb);
