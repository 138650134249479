import React, { useContext, useEffect } from "react";
import "../../../Utils/sd-styles/sd-main-sup.scss";
import pagesData from "../../../Utils/data/pages.data";
import { SD_Context } from "../../../context/sd-context";
import { withRouter } from "react-router";

const TemplateThree = ({ history }) => {
  //-------------------------------------------------------------states--------------------------------------------------------//
  const { language } = useContext(SD_Context);

  //-------------------------------------------------------------states--------------------------------------------------------//

  //-------------------------------------------------------------useEffects--------------------------------------------------------//

  useEffect(async () => {}, []);

  //-------------------------------------------------------------useEffects--------------------------------------------------------//

  return (
    <div className="sd-template-three">
      <div
        className="sd-t3-one"
        style={{
          backgroundImage: `url(${pagesData("home")?.idolBackground})`,
        }}
      >
        <img src={pagesData("home")?.idol} height="100%" />
      </div>
      {pagesData("home")?.services?.map((item, i) => {
        return (
          <div className="sd-t3-two" key={i}>
            {" "}
            <div className="sd-box-one ">
              <div className="sd-box-one-image">
                <img
                  src={item?.img}
                  alt={item?.title}
                  className="sd-logo-img sd-size-four"
                />
              </div>
              <div className="sd-box-one-content">
                <h2>{item?.title}</h2>
                <p>{item?.desc}</p>

                <button
                  className="sd-b-orange"
                  onClick={() => {
                    if (item?.link?.includes("youtube")) {
                      window.open(item?.link, "_blank");
                    } else {
                      if (localStorage.getItem("accessToken")) {
                        history?.push(
                          `/${language?.code}/devotee-app/online-booking/${item?.link}`
                        );
                      } else {
                        history?.push(
                          `/${language?.code}/devotee/signin?to=devotee-app/online-booking/${item?.link}`
                        );
                      }
                    }
                  }}
                >
                  {item?.label}{" "}
                  <img src={pagesData("home")?.primaryArrow} alt=">" />
                </button>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
export default withRouter(TemplateThree);
