// import {FALSE} from "node-sass";
import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import {
  SD_cart_icon,
  SD_Dropdown_Arrow_icon,
  SD_Logo_circle,
  sd_minus_icon,
  sd_plus_icon,
  SD_Print_a_ticket_icon,
  SD_Srisaila_TV_icon,
} from "../../Assets/imageList";
import { SD_Context } from "../../context/sd-context";
import NavBarData from "../../Utils/data/navbar.data";
import pagesData from "../../Utils/data/pages.data";
const Sd_mobile_left_sider = ({
  setSider,

  history,

  languageList,
}) => {
  const { language, setlanguage } = React.useContext(SD_Context);
  //-------------------------------------------------------------funtion-----------------------------------------------------------//

  const [visible, setVisisble] = useState("");
  const [subVisible, setSubVisisble] = useState("");

  const Route_push = () => {
    setSider(false);
    setVisisble("");
    setSubVisisble("");
  };
  // const praytakshaSevaNav = [
  //   {
  //     key: " Suprabhata Seva (1-Person) ",
  //     path: " suprabhata-seva",
  //   },
  //   { key: " Sri Ammavari Vastram Seva ", path: " sri-ammavari-vastram-seva " },
  //   { key: " Kadgamalarchana ", path: " kadgamalarchana " },
  //   { key: " Ganapathi Homam ", path: " ganapathi-homam " },
  //   { key: " Rudra Homam ", path: " rudra-homam " },
  //   { key: " Navagraha Santhi Homam ", path: " navagraha-santhi-homam " },
  //   { key: " Lakshakumkumarchana ", path: " lakshakumkumarchana " },
  //   { key: " Sri Chakranavavaranachana ", path: " sri-chakranavavaranachana " },
  //   { key: " Mrutyunjaya Homam ", path: " mrutyunjaya-homam " },
  //   { key: " Chandi Homam ", path: " chandi-homam " },
  //   { key: " Santhi Kalyanam ", path: " santhi-kalyanam " },
  //   { key: " Rahu Ketu ", path: " rahu-ketu " },
  //   {
  //     key: " Pancha Harathulu(1-Person) ",
  //     path: " pancha-harathulu",
  //   },
  //   { key: " Pallaki Seva ", path: " pallaki-seva " },
  //   { key: " Pavalimpu Seva ", path: " pavalimpu-seva " },
  //   { key: " Astotanamarchana ", path: " astotanamarchana " },
  //   { key: " Sahasranamarchana ", path: " sahasranamarchana " },
  //   { key: " Annaprasana ", path: " annaprasana " },
  //   { key: " Aksharabhyasam ", path: " aksharabhyasam " },
  //   { key: " Sooryopasana(Sunday) ", path: " sooryopasana" },
  //   {
  //     key: " Subramanya Swami Kalyanam (Shashti) ",
  //     path: " subramanya-swami-kalyanam",
  //   },
  //   {
  //     key: (
  //       <>
  //         Sri Malleshwara Swami Vari
  //         <br />
  //         Panchamrutaabhishekam
  //       </>
  //     ),
  //     path: " sri-malleshwara-swami-vari-panchamrutaabhishekam ",
  //   },
  //   { key: " Veda Aasheeravachanam ", path: " veda-aasheeravachanam " },
  //   {
  //     key: (
  //       <>
  //         Sri Malleshwara Swami Vari
  //         <br /> Astotanamarchana
  //       </>
  //     ),
  //     path: " sri-malleshwara-swami-vari-astotanamarchana ",
  //   },
  // ];
  // const saswathaSevaNav = [
  //   { key: <>Sri Ammavari Vastram Seva </>, path: "sri-ammavari-vastram-seva" },
  //   {
  //     key: <>Khadgamala Pooja in Antharalayam </>,
  //     path: "khadgamala-pooja-in-antharalayam",
  //   },
  //   {
  //     key: (
  //       <>
  //         Ammavari Sahasranamarchana <br />
  //         (One Day){" "}
  //       </>
  //     ),
  //     path: "ammavari-sahasranamarchana",
  //   },
  //   {
  //     key: <>Ammavari Astotranamarchana (One Day) </>,
  //     path: "ammavari-astotranamarchana",
  //   },
  // ];
  //-------------------------------------------------------------funtion-----------------------------------------------------------//
  return (
    <div className="inner">
      <div className="inner-nav-top">
        <div className="left">
          <img
            className="logo"
            src={SD_Logo_circle}
            alt="icon"
            onClick={() => {
              Route_push();
              history?.push(`/${language?.code}/home`);
            }}
          />
        </div>
        <div className="right">
          <div className="dropdown">
            <button
              className="btn"
              type="button"
              id="dropdownMenuButton1"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {language.desc} <img src={SD_Dropdown_Arrow_icon} alt="icon" />
            </button>
            <div
              className="dropdown-menu"
              aria-labelledby="dropdownMenuButton1"
            >
              {languageList?.map((item, i) => {
                return (
                  <p
                    key={i}
                    className="dropdown-item"
                    onClick={() => {
                      setlanguage(item);
                    }}
                  >
                    {item?.desc}
                  </p>
                );
              })}
            </div>
          </div>

          <img src={SD_cart_icon} alt={SD_cart_icon} />
          <button
            type="button"
            onClick={() => {
              setSider(false);
            }}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>

      <div className="inner-bottom">
        <div className="inner-bottom-t">
          {pagesData("navbar")?.tv && (
            <div
              onClick={() => {
                Route_push();
                window.open(pagesData("navbar")?.tv?.link, "_blank");
              }}
            >
              <img src={SD_Srisaila_TV_icon} alt={SD_Srisaila_TV_icon} />
              {pagesData("navbar")?.tv?.label}
            </div>
          )}

          <div
            onClick={() => {
              if (localStorage.getItem("accessToken")) {
                history?.push(`/${language?.code}/devotee-app/booking-history`);
              } else {
                history?.push(
                  `/${language?.code}/devotee/signin?to=devotee-app/booking-history`
                );
              }
              Route_push();
            }}
          >
            <img src={SD_Print_a_ticket_icon} alt={SD_Print_a_ticket_icon} />
            BOOKING HISTORY
          </div>
          <div
            onClick={() => {
              Route_push();
              history?.push(
                `/${language?.code}/get-involved/volunteer-oppurtunity`
              );
            }}
          >
            VOLUNTEER
          </div>
          <div
            onClick={() => {
              Route_push();
              history?.push(`/${language?.code}/shop`);
            }}
          >
            SHOP
          </div>
        </div>
        <div className="inner-bottom-b">
          <div style={{ padding: "0" }}>
            {NavBarData("menu")?.map((item, i) => {
              return (
                <div key={i} style={{ padding: "0" }}>
                  <div className="between">
                    {item?.title}
                    {item?.children &&
                      (visible === item?.title ? (
                        <img
                          src={sd_minus_icon}
                          alt="-"
                          onClick={() => {
                            setVisisble("");
                          }}
                        />
                      ) : (
                        <img
                          src={sd_plus_icon}
                          alt="+"
                          onClick={() => {
                            setVisisble(item?.title);
                          }}
                        />
                      ))}
                  </div>

                  {item?.children &&
                    item?.children?.map((subItem, s) => {
                      return (
                        <div
                          key={s}
                          className={`drop-three ${
                            visible === item?.title ? "block" : "none"
                          }`}
                        >
                          <div>
                            <span
                              onClick={() => {
                                if (item?.key === "online-booking") {
                                  if (subItem?.path === "online-booking") {
                                    history?.push(
                                      `/${language?.code}/${subItem?.path}/`
                                    );
                                  } else if (
                                    localStorage.getItem("accessToken")
                                  ) {
                                    history?.push(
                                      `/${language?.code}/devotee-app/online-booking/${subItem?.path}`
                                    );
                                  } else {
                                    history?.push(
                                      `/${language?.code}/devotee/signin?to=devotee-app/online-booking/${subItem?.path}`
                                    );
                                  }
                                } else if (subItem?.children === undefined) {
                                  history?.push(
                                    `/${language?.code}/${subItem?.path}/`
                                  );
                                }
                                Route_push();
                              }}
                            >
                              {subItem?.name}
                            </span>
                            {subItem?.children &&
                              (subVisible === subItem?.name ? (
                                <img
                                  src={sd_minus_icon}
                                  alt="-"
                                  onClick={() => {
                                    setSubVisisble("");
                                  }}
                                />
                              ) : (
                                <img
                                  src={sd_plus_icon}
                                  alt="+"
                                  onClick={() => {
                                    setSubVisisble(subItem?.name);
                                  }}
                                />
                              ))}
                          </div>
                          {subItem?.children &&
                            subItem?.children?.map((data, d) => {
                              return (
                                <div
                                  className={`drop-8 ${
                                    subVisible === subItem?.name
                                      ? "block"
                                      : "none"
                                  }`}
                                  style={{ padding: "5px 0px" }}
                                  key={d}
                                >
                                  <div
                                    onClick={() => {
                                      Route_push();

                                      history?.push(
                                        `/${language?.code}/${data?.path}`
                                      );
                                    }}
                                  >
                                    {data?.name}
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      );
                    })}
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="inner-footer">
        {pagesData("navbar")?.socialLinks?.map((item, i) => {
          return (
            <img
              style={{ filter: "invert(1)" }}
              key={i}
              onClick={() => {
                window.open(item?.link, "_blank");
              }}
              src={item?.icon}
              alt="icon"
            />
          );
        })}
      </div>
    </div>
  );
};
export default withRouter(Sd_mobile_left_sider);
