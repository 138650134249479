/* eslint-disable react/jsx-key */
import React, { useContext, useEffect, useState } from "react";
import {
  sd_dashboard_accomadation,
  sd_dashboard_donation,
  sd_dashboard_seva,
  sd_dashboard_side_arrow,
  sd_dashboard_srisaila_prabha,
  sd_dashboard_upcoming,
  sd_news_list_icon,
  SD_Right_Arrow_white,
  sd_script,
} from "../../Assets/imageList";
// import OwlCarousel from "react-owl-carousel";
// import "owl.carousel/dist/assets/owl.carousel.css";
// import "owl.carousel/dist/assets/owl.theme.default.css";
import "../../Utils/sd-styles/sd-css/main.css";
import { SD_Context } from "../../context/sd-context";
import Sd_DashboardService from "../../services/sd-dashboard.service";
import Sd_loading from "../../Components/sd-common-components/sd-loading";
import IndiaTime from "../../Utils/sd-moment/IndiaTime";
import { withRouter } from "react-router";
import Slider from "react-slick";
import { EncryptInformation } from "../../Utils/sd-encrypt/encrypt";
import moment from "moment";
import pagesData from "../../Utils/data/pages.data";
const Sd_dashboard_devotee_dashboard = ({ history }) => {
  //-------------------------------------------------------------states--------------------------------------------------------//
  const { setActive, setActivePath, logout, userData, templeID, language } =
    useContext(SD_Context);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState();
  // const [hoverActive, setHoverActive] = useState("none");
  // const [statisctics, setStatictics] = useState([]);
  const [modal, setModal] = useState(false);
  const settings = {
    dots: true,
    autoplay: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    nav: false,
    autoplaySpeed: 4000,
    arrows: false,
  };
  //-------------------------------------------------------------states--------------------------------------------------------//

  //-----------------------------------------------------------useeffect------------------------------------------------------//
  useEffect(() => {
    setLoading(true);
    setActive("devotee-dashboard");
    setActivePath("Dashboard");

    getData();
  }, []);
  useEffect(() => {
    if (userData?.email === "") {
      setModal(true);
    }
  }, []);
  //------------------------------------------------------------useffect--------------------------------------------------------//

  //------------------------------------------------------------functions----------------------------------------------------------//

  const getData = async () => {
    try {
      // let res = await Sd_DashboardService.upcoming_booking(4, 1);
      let res = await Sd_DashboardService.booking_history(
        20,
        1,
        templeID,
        true,
        "success"
      );
      // let res1 = await Sd_DashboardService.getStatistics();

      // setStatictics([]);
      setData(res.items);
      setLoading(false);
    } catch (e) {
      if (e.message === "Session Expired") {
        logout();
        history?.push(
          `/${language?.code}/devotee/signin?to=devotee-app/devotee-dashboard`
        );
      }
      setLoading(false);
    }
  };
  //-------------------------------------------------------------functions--------------------------------------------------------//
  return (
    <div className="col-xs-12 col-sm-12 col-md-9 col-lg-9 sd-dashboard">
      {loading ? (
        <Sd_loading />
      ) : (
        <>
          <h2 className="sd-side-heading fw500">
            <span className="fw700">Devotee</span> Dashboard
          </h2>
          <div className="col-xs-12 col-sm-12 col-md-8 col-lg-8 pl-0 ">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 pl-0 xs-pr-0">
              <div className="sd-upcoming-bookings">
                <img
                  className="sd-dashboard-icons"
                  src={sd_dashboard_upcoming}
                  width="50px"
                  height="50"
                />
                <div className="sd-upcoming-bookings-table">
                  <h3 className="sd-side-heading-2 fw600">Upcoming Bookings</h3>
                  <div className="clearfix">
                    <table>
                      {data.map((item) => {
                        return (
                          <tr>
                            <td width="100%" className="sd-booking-name flex-j">
                              <span>
                                <a
                                  onClick={() => {
                                    history?.push(
                                      `/${
                                        language?.code
                                      }/devotee/print-a-ticket?id=${EncryptInformation(
                                        item?.ticketId
                                      )}&display=view`
                                    );
                                  }}
                                >
                                  {item.serviceName}
                                </a>
                                <span
                                  style={{
                                    fontWeight: "500",
                                    marginLeft: "3px",
                                    marginRight: "5px",
                                  }}
                                >
                                  ({item?.serviceType?.replace("_", " ")})
                                </span>
                              </span>
                              <span>
                                {IndiaTime(item.bookingDate).format(
                                  "ddd, Do MMMM  YYYY"
                                )}

                                {item?.serviceType === "online_darshanam" ||
                                item?.serviceType === "online_seva" ? (
                                  <>
                                    - (
                                    {moment(
                                      item.slotStartTime,
                                      "HH:mm:ss"
                                    ).format("hh:mm A")}{" "}
                                    -
                                    {moment(
                                      item.slotEndTime,
                                      "HH:mm:ss"
                                    ).format("hh:mm A")}
                                    )
                                  </>
                                ) : (
                                  <>
                                    {IndiaTime(item?.slotStartTime).format(
                                      "hh:mm A"
                                    ) ===
                                    IndiaTime(item?.slotEndTime).format(
                                      "hh:mm A"
                                    ) ? (
                                      <>
                                        {item.slotStartTime && ` - `}
                                        {item.slotStartTime &&
                                          IndiaTime(item.slotStartTime).format(
                                            "hh:mm A"
                                          )}
                                      </>
                                    ) : (
                                      <>
                                        {item.slotStartTime && ` - `}
                                        {item.slotStartTime &&
                                          IndiaTime(item.slotStartTime).format(
                                            "hh:mm A"
                                          )}
                                        -
                                        {item.slotEndTime &&
                                          IndiaTime(item.slotEndTime).format(
                                            "hh:mm A"
                                          )}
                                      </>
                                    )}
                                  </>
                                )}
                              </span>
                            </td>
                          </tr>
                        );
                      })}
                      {data.length === 0 && (
                        <p className="flex-a mt-26 mb-20">
                          <img
                            src={sd_script}
                            alt="#"
                            height="30px"
                            style={{ marginRight: "5px" }}
                          />
                          Sorry! There seems to be no current bookings.
                        </p>
                      )}
                    </table>
                  </div>
                  {data.length !== 0 && (
                    <div className="clearfix mt-20 mb-20">
                      <a
                        className="sd-btn-orange"
                        onClick={() => {
                          history?.push(
                            `/${language?.code}/devotee-app/booking-history`
                          );
                        }}
                      >
                        View All
                        <img
                          className="sd-arrow-btn ml-5"
                          src={SD_Right_Arrow_white}
                        />
                      </a>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="sd-dashboard-banners ">
              <div className="col-xs-12 col-sm-6 col-md-12 col-lg-6 pl-0 xs-pr-0 mt-30">
                <div className="sd-four-sections sd-seva-banner">
                  <img
                    className="sd-dashboard-icons"
                    src={sd_dashboard_seva}
                    width="50px"
                    height="50"
                  />
                  <div className="sd-seva-right-para">
                    <h3 className="sd-side-heading-2 fw600 sd-border-no pb-15">
                      Sevas & Darshanam
                    </h3>
                    <div className="clearfix">
                      <p className="sd-booking-date sd-para-text">
                        Darshanam and Seva tickets can now be bought offline at
                        the ticket counter and online via our website. Relish by
                        visiting {pagesData("home")?.title}
                      </p>
                    </div>
                    <div className="clearfix mt-10">
                      <a
                        className="sd-btn-blue"
                        onClick={() => {
                          history?.push(
                            `/${language?.code}/sevas-and-darshanam`
                          );
                        }}
                      >
                        Book Now
                        <img
                          className="sd-arrow-btn ml-5"
                          src={SD_Right_Arrow_white}
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-sm-6 col-md-12 col-lg-6 pl-0 xs-pr-0 mt-30">
                <div className="sd-four-sections sd-accommodation-banner">
                  <img
                    className="sd-dashboard-icons"
                    src={sd_dashboard_accomadation}
                    width="50px"
                    height="50"
                  />
                  <div className="sd-seva-right-para">
                    <h3 className="sd-side-heading-2 fw600 sd-border-no  pb-15">
                      Accommodation
                    </h3>
                    <div className="clearfix">
                      <p className="sd-booking-date sd-para-text">
                        Book comfortable and hygienic AC/Non AC Rooms, Cottages,
                        Guest Houses, Hotels, close to all pilgrimage spots,
                        with all basic amenities.
                      </p>
                    </div>
                    <div className="clearfix mt-10">
                      <a
                        className="sd-btn-orange"
                        onClick={() => {
                          history?.push(
                            `/${language?.code}/devotee-app/online-booking/accommodations`
                          );
                        }}
                      >
                        Book Now
                        <img
                          className="sd-arrow-btn ml-5"
                          src={SD_Right_Arrow_white}
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-sm-6 col-md-12 col-lg-6 pl-0 xs-pr-0 mt-30">
                <div className="sd-four-sections sd-donation-banner">
                  <img
                    className="sd-dashboard-icons"
                    src={sd_dashboard_donation}
                    width="50px"
                    height="50"
                  />
                  <div className="sd-seva-right-para">
                    <h3 className="sd-side-heading-2 fw600 sd-border-no  pb-15">
                      Donations
                    </h3>
                    <div className="clearfix">
                      <p className="sd-booking-date sd-para-text">
                        Devotees can now donate to {pagesData("home")?.title}{" "}
                        through online payments. We accept credit card,debit
                        cards and UPI transactions.
                      </p>
                    </div>
                    <div className="clearfix mt-10">
                      <a
                        className="sd-btn-green"
                        onClick={() => {
                          history?.push(
                            `/${language?.code}/devotee-app/online-booking/donations`
                          );
                        }}
                      >
                        Donate
                        <img
                          className="sd-arrow-btn ml-5"
                          src={SD_Right_Arrow_white}
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-sm-6 col-md-12 col-lg-6 pl-0 xs-pr-0 mt-30">
                <div className="sd-four-sections sd-prabha-banner">
                  <img
                    className="sd-dashboard-icons"
                    src={sd_dashboard_srisaila_prabha}
                    width="50px"
                    height="50"
                  />
                  <div className="sd-seva-right-para">
                    <h3 className="sd-side-heading-2 fw600 sd-border-no  pb-15">
                      Publications
                    </h3>
                    <div className="clearfix">
                      <p className="sd-booking-date sd-para-text">
                        {pagesData("home")?.title}
                        has been publishing a Religious Monthly Magazine
                        regarding hindu traditions.
                      </p>
                    </div>
                    <div className="clearfix mt-10">
                      <a
                        className="sd-btn-blue-dark"
                        onClick={() => {
                          history?.push(
                            `/${language?.code}/devotee-app/online-booking/publications`
                          );
                        }}
                      >
                        Subscribe
                        <img
                          className="sd-arrow-btn ml-5"
                          src={sd_dashboard_side_arrow}
                          alt=">"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 sd-dashboard-right-panel xs-pl-0 xs-pr-0 sm-pt-30 pr-0 pl-0">
            <div className="sd-upcoming-events">
              <h2 className="sd-side-heading fw500 sd-border-no">
                <span className="fw700">Upcoming</span> Events
              </h2>
              {pagesData("upcomingPoster")?.length > 0 ? (
                <Slider {...settings} dots={true}>
                  {pagesData("upcomingPoster")?.map((item, i) => {
                    return (
                      <img
                        key={i}
                        style={{ cursor: "pointer !important" }}
                        src={item}
                        alt={`picture${i}`}
                        // onClick={() => setCurrentImage(upcomingBanner1)}
                      />
                    );
                  })}
                </Slider>
              ) : (
                <p>No Events</p>
              )}

              {/* <OwlCarousel
                className="owl-theme"
                loop
                items="1"
                mouseDrag={true}
                touchDrag={true}
                autoplay={true}
                autoplayTimeout={5000}
                lazyLoad={true} // nav
                // navElement={<div><img src={} alt=""/></div>}
                fallbackEasing={"swing"}
              >
                <div className="item">
                  {" "}
                  <img src={upcomingBanner1} alt="banner" />
                </div>
                <div className="item">
                  {" "}
                  <img src={upcomingBanner1} alt="banner" />
                </div>
                <div className="item">
                  {" "}
                  <img src={upcomingBanner1} alt="banner" />
                </div>
              </OwlCarousel> */}
            </div>
            <div className="sd-news-updates mt-40">
              <h2 className="sd-side-heading fw500">
                <span className="fw700">News</span> Updates
              </h2>
              {pagesData("home")
                ?.news?.slice(0, 2)
                ?.map((item, i) => {
                  return (
                    <div className="sd-news-list mt-20" key={i}>
                      <div className="item">
                        <img
                          className="sd-dashboard-icons"
                          src={sd_news_list_icon}
                          alt="news"
                        />
                        <div className="sd-news-para">
                          <p>{item?.data}</p>
                          <span className="sd-news-date">
                            {moment(item?.date)?.format("DD-MMM-YYYY")}
                          </span>
                        </div>
                      </div>
                    </div>
                  );
                })}

              <div className="clearfix mt-50">
                <a
                  className="sd-btn-orange"
                  onClick={() => {
                    history?.push(
                      `/${language?.code}/media-room/press/temple-news`
                    );
                  }}
                >
                  View All
                  <img
                    className="sd-arrow-btn ml-5"
                    src={sd_dashboard_side_arrow}
                    alt=">"
                  />
                </a>
              </div>
            </div>
          </div>

          {modal && (
            <div className="sd-profile-popup">
              <div
                className="inner"
                style={{
                  height: "150px",
                  display: "flex",
                  flexDirection: "column",
                  minHeight: "150px",
                }}
              >
                <h2>
                  Update Email Address in My Account to recieve online tickets.{" "}
                  <span
                    style={{ textDecoration: "underline" }}
                    onClick={() => {
                      history.push(
                        `/${language?.code}/devotee-app/my-account/profile`
                      );
                    }}
                  >
                    Click here
                  </span>{" "}
                </h2>
                <button
                  style={{
                    color: "white",
                    backgroundColor: "#991403",
                    marginTop: "10px",
                    alignSelf: "center",
                  }}
                  className="btn"
                  onClick={() => {
                    setModal(false);
                  }}
                >
                  Close
                </button>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};
export default withRouter(Sd_dashboard_devotee_dashboard);
