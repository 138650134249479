import pagesData from "./pages.data";
import getTempleShortCodeFromDomain from "./templeId.data";

const FooterData = (key) => {
  const id =
    process.env.REACT_APP_TEMPLE ??
    getTempleShortCodeFromDomain(window?.location?.hostname);
  const about = [
    {
      name: "The Temple",
      path: "about/the-temple/about-the-temple",
    },
    { name: "Temple Story", path: "about/the-temple-history/the-history" },
    {
      name: "General Information",
      path: "about/general-information/visiting-places",
    },
  ];

  const sevasAndDarshanam = [
    {
      name: pagesData("commonTitles", "Darshanam"),
      path: "darshanam",
    },
    {
      name: pagesData("commonTitles", "Pratyaksha Seva"),
      path: "pratyaksha-seva",
    },
    {
      name: pagesData("commonTitles", "Paroksha Seva"),
      path: "paroksha-seva",
    },
  ];

  const donation = {
    SVSVD: [
      { name: "e-Hundi", path: "e-Hundi" },
      {
        name: "Saswatha Annadanam Trust",
        path: "saswatha-annadanam-trust",
      },
      {
        name: "Go Samrakshana Padhakam Trust",
        path: "go-samrakshana-padhakam-trust",
      },
      {
        name: "Pranadana Trust",
        path: "pranadana-trust",
      },
      {
        name: "Cottage Donation Scheme",
        path: "cottage-donation-scheme",
      },
      {
        name: "Sri Venkateswara Vykhanasa Agama Patasala Vidhyardhi Dattata Phakam",
        path: "sri-venkateswara-vykhanasa-agama-patasala-vidhyardhi-dattata-phakam",
      },

      // {
      //   name: "Sri Satya Deva Annaprasada Distribution Trust ",
      //   path: "sri-satya-deva-annaprasada-distribution-trust ",
      // },
      // {
      //   name: "Pulihora and Dadhojanam Scheme",
      //   path: "pulihora-and-dadhojanam-scheme",
      // },
    ],
    SKHTD: [
      { name: "e-Hundi", path: "e-Hundi" },
      {
        name: "Annadanam Trust",
        path: "annadanam-trust",
      },
      {
        name: "Gosamrakshana Trust",
        path: "gosamrakshana-trust",
      },
    ],
    SVLNSD: [{ name: "e-Hundi", path: "e-Hundi" }],
    SSVVSD: [
      { name: "e-Hundi", path: "e-Hundi" },
      {
        name: "Saswatha Nithya Annadanam Trust",
        path: "saswatha-nithya-annadanam-trust",
      },
      {
        name: "Sri Varasiddhi Vinayaka Go Samrakshana Trust",
        path: "sri-varasiddhi-vinayaka-go-samrakshana-trust",
      },
      {
        name: "Sri Siddhi Vinayaka Swamy Accommodation Trust",
        path: "about/general-information/sri-siddhi-vinayaka-swamy-accommodation-trust",
      },
    ],
    SDMSD: [
      { name: "e-Hundi", path: "e-Hundi" },
      // {
      //   name: "Saswatha Nithya Annadanam Trust",
      //   path: "saswatha-nithya-annadanam-trust",
      // },
      // {
      //   name: "Sri Varasiddhi Vinayaka Go Samrakshana Trust",
      //   path: "sri-varasiddhi-vinayaka-go-samrakshana-trust",
      // },
      {
        name: "Annadanam Trust",
        path: "annadanam-trust",
      },
      {
        name: "Devasthana Trust",
        path: "devasthana-trust",
      },
      {
        name: "Gosamrakshana Trust",
        path: "gosamrakshana-trust",
      },
      {
        name: "Saswatha Sri Durga  Trust",
        path: "saswatha-sri-durga-malleswara-swamy-varla-dharma-prachara-parishadNithya-annadanam-trust",
      },
      {
        name: "Sri Krishna Nadi Pavithra Harathi Trust",
        path: "sri-krishna-nadi-pavithra-harathi-trust",
      },
      // {
      //   name:"Parirakshana Trust",
      //   path:"about/general-information/parirakshana-trust"
      // },
    ],
  };

  const onlineBooking = {
    SVSVD: [
      {
        name: pagesData("commonTitles", "Darshanam"),
        path: "darshanam",
      },
      {
        name: pagesData("commonTitles", "Pratyaksha Seva"),
        path: "pratyaksha-seva",
      },
      {
        name: pagesData("commonTitles", "Paroksha Seva"),
        path: "paroksha-seva",
      },
      // { name: pagesData("commonTitles", "Prasadam"), path: "Prasadam" },
      {
        name: pagesData("commonTitles", "Accommodation"),
        path: "accommodations",
      },
      // {
      //   name: pagesData("commonTitles", "Katta"),
      //   path: "kalyanakatta",
      // },
      // {
      //   name: pagesData("commonTitles", "Annadanam"),
      //   path: "annadanam",
      // },
      // {
      //   name: pagesData("commonTitles", "Kalyana"),
      //   path: "kalyana-mandapam",
      // },
    ],
    SKHTD: [
      {
        name: pagesData("commonTitles", "Darshanam"),
        path: "darshanam",
      },
      {
        name: pagesData("commonTitles", "Pratyaksha Seva"),
        path: "pratyaksha-seva",
      },
      {
        name: pagesData("commonTitles", "Paroksha Seva"),
        path: "paroksha-seva",
      },
      { name: pagesData("commonTitles", "Prasadam"), path: "Prasadam" },
      {
        name: pagesData("commonTitles", "Accommodation"),
        path: "accommodations",
      },
      {
        name: pagesData("commonTitles", "Katta"),
        path: "kalyanakatta",
      },
      {
        name: pagesData("commonTitles", "Annadanam"),
        path: "annadanam",
      },
      {
        name: pagesData("commonTitles", "Kalyana"),
        path: "kalyana-mandapam",
      },
    ],
    SVLNSD: [
      {
        name: pagesData("commonTitles", "Darshanam"),
        path: "darshanam",
      },
      {
        name: pagesData("commonTitles", "Pratyaksha Seva"),
        path: "pratyaksha-seva",
      },
      {
        name: pagesData("commonTitles", "Paroksha Seva"),
        path: "paroksha-seva",
      },
      // { name: pagesData("commonTitles", "Prasadam"), path: "Prasadam" },
      {
        name: pagesData("commonTitles", "Accommodation"),
        path: "accommodations",
      },
      // {
      //   name: pagesData("commonTitles", "Katta"),
      //   path: "kalyanakatta",
      // },
      // {
      //   name: pagesData("commonTitles", "Annadanam"),
      //   path: "annadanam",
      // },
      // {
      //   name: pagesData("commonTitles", "Kalyana"),
      //   path: "kalyana-mandapam",
      // },
    ],
    SSVVSD: [
      {
        name: pagesData("commonTitles", "Darshanam"),
        path: "darshanam",
      },
      {
        name: pagesData("commonTitles", "Pratyaksha Seva"),
        path: "pratyaksha-seva",
      },
      {
        name: pagesData("commonTitles", "Paroksha Seva"),
        path: "paroksha-seva",
      },
      {
        name: pagesData("commonTitles", "Accommodation"),
        path: "accommodations",
      },
    ],
    SDMSD: [
      {
        name: pagesData("commonTitles", "Darshanam"),
        path: "darshanam",
      },
      {
        name: pagesData("commonTitles", "Pratyaksha Seva"),
        path: "pratyaksha-seva",
      },
      {
        name: pagesData("commonTitles", "Paroksha Seva"),
        path: "paroksha-seva",
      },
      {
        name: pagesData("commonTitles", "Accommodation"),
        path: "accommodations",
      },
      {
        name: pagesData("commonTitles", "Donations"),
        path: "donations",
      },
    ],
  };

  const mediaRoom = [
    {
      name: pagesData("commonTitles", "Media Kit"),
      path: "media-room/media-kit",
    },
    {
      name: pagesData("commonTitles", "Gallery"),
      path: "media-room/photo-gallery",
    },
    { name: "Whats New ", path: "media-room/whats-new/temple-diary" },
    {
      name: pagesData("commonTitles", "Press"),
      path: "media-room/press/temple-news",
    },
    {
      name: pagesData("commonTitles", "Tenders"),
      path: "media-room/tenders",
    },
    {
      name: pagesData("commonTitles", "RTI Act"),
      path: "media-room/rti-act",
    },
  ];
  const support = [
    { name: "FAQs", path: "faqs" },
    {
      name: pagesData("commonTitles", "Facilities to Pilgrims"),
      path: "facilities-to-pilgrims",
    },
    {
      name: pagesData("commonTitles", "Connectivity"),
      path: "connectivity",
    },
    // {
    //   name: "Live Chat",
    //   path: "live-chat",
    // },
    {
      name: "Contact Us",
      path: "contact-us",
    },
  ];

  const legalities = [
    { name: "Privacy Policy", path: "privacy-policy" },
    { name: "Refund Policy", path: "refund-policy" },
    { name: "Trademarks", path: "trademarks" },
    { name: "Terms and Conditions", path: "terms-and-conditions" },
    { name: "Cautionary Notice", path: "cautionary-notice" },
  ];

  const dataMap = {
    about: about,
    "seva-darshanam": sevasAndDarshanam,
    donation: donation[id],
    "online-data": onlineBooking[id],
    support: support,
    legalities: legalities,
    media: mediaRoom,
  };
  return dataMap[key];
};
export default FooterData;
