import React, { useEffect, useContext, useState, useRef } from "react";
import { toast } from "react-toastify";
import {
  sd_dashboard_dob,
  SD_Right_Arrow_white,
  sd_trash,
} from "../../../Assets/imageList";
import { SD_Context } from "../../../context/sd-context";
import Sd_DashboardService from "../../../services/sd-dashboard.service";
import "../../../Utils/sd-styles/sd-css/main.css";
import "react-toastify/dist/ReactToastify.css";

import Sd_loading from "../../../Components/sd-common-components/sd-loading";
import ParokshaSevaService from "../../../services/sd-parokshaseva.service";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import DatePicker from "react-date-picker";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";

import moment from "moment";
import { addDays } from "date-fns";
import pagesData from "../../../Utils/data/pages.data";
const Sd_dashboard_online_booking_donations = ({ history }) => {
  //-------------------------------------------------------------states--------------------------------------------------------//
  const {
    setActive,
    setActivePath,
    currentBookingDonation,
    setcurrentBookingDonation,
    userData,
    devoteeList,
    logout,
    templeID,
    language,
    AddressRegex,
    zipcodeRegex,
  } = useContext(SD_Context);

  const [donor, setDonar] = useState("");
  const [gothram, setGothram] = useState("");
  const [occasion, setOcassion] = useState("");
  const [docNumber, setdocNumber] = useState("");
  const [docType, setDockType] = useState("pan");
  const [amount, setAmount] = useState("");
  const [paksham, setPaksham] = useState("shukla");
  const [calenderType, setCalenderType] = useState("english");
  const [autoFill, setAutoFill] = useState("none");
  const [startDate, setStartDate] = useState("");
  const [dob, setDob] = useState("");
  const [selectArray, setSelectArray] = useState([]);

  const [loading, setloading] = useState(true);

  const [uploadLink, setUploadLink] = useState("");
  const [acceptFlag, setAcceptFlag] = useState(false);
  const [donation, setDonation] = useState("");
  const [masam, setMasam] = useState("Chaithram");
  const [tithi, setTithi] = useState("Prathama");
  const [err, setErr] = useState([]);
  const ref = useRef(null);
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("India");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [mobile, setMobile] = useState("91");
  const [gender, setGender] = useState("");
  const [uploadFlag, setUploadFlag] = useState(false);
  //-------------------------------------------------------------states-----------------------------------------------------------//

  //-------------------------------------------------------------useeffect--------------------------------------------------------//
  useEffect(() => {
    setloading(true);
    setActive("online-booking-donations");
    setActivePath("Onlinebooking-Donations");
    if (
      new URLSearchParams(history?.location?.search).get("state") === "clear"
    ) {
      setcurrentBookingDonation(null);
    }

    localStorage.removeItem("payment-context");

    getData();
  }, []);

  //-------------------------------------------------------------useeffect--------------------------------------------------------//

  //-------------------------------------------------------------functions-----------------------------------------------------------//
  const getData = async () => {
    try {
      let res = await Sd_DashboardService.donations_getall(templeID);
      setSelectArray(res);

      if (currentBookingDonation) {
        setDockType(currentBookingDonation?.docType);
        setDonar(currentBookingDonation?.donor);
        setGothram(currentBookingDonation?.gothram);
        setUploadLink(currentBookingDonation?.uploadLink);

        setDonation(currentBookingDonation?.donation);
        setDob(currentBookingDonation?.dob);

        setGender(currentBookingDonation?.gender);
        setAmount(currentBookingDonation?.amount);
        currentBookingDonation?.occasion &&
          setOcassion(currentBookingDonation?.occasion);
        setCalenderType(currentBookingDonation?.calenderType);
        if (currentBookingDonation?.calenderType === "telugu") {
          setMasam(currentBookingDonation?.masam);
          setTithi(currentBookingDonation?.tithi);
          setPaksham(currentBookingDonation?.paksham);
        } else {
          setStartDate(currentBookingDonation?.date);
        }
        setGothram(currentBookingDonation?.gothram);
        setAddress1(currentBookingDonation?.address1);
        setAddress2(currentBookingDonation?.address2);
        setCountry(currentBookingDonation?.country);
        setState(currentBookingDonation?.state);
        setCity(currentBookingDonation?.city);
        setZip(currentBookingDonation?.zip);
        setMobile(currentBookingDonation?.mobile);

        setdocNumber(currentBookingDonation?.docNumber);
        setAcceptFlag(false);
      }
      let path = new URLSearchParams(history?.location?.search).get("to");
      if (path) {
        let finder = res.find(
          (item) => item?.name?.replace?.(/\s/g, "-").toLowerCase() === path
        );
        setDonation(finder);
      }

      // setDonation(finder);
      setloading(false);
    } catch (e) {
      toast.warning(e.message);
      if (e.message === "Session Expired") {
        logout();

        history?.push(
          `/${language?.code}/devotee/signin?to=devotee-app/online-booking/donations`
        );
      }
      setloading(false);
    }
  };

  function capitalize(str) {
    let splitStr = str?.toLowerCase?.().split?.(" ");
    for (let i = 0; i < splitStr?.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr?.join(" ");
  }

  const handleFileUpload = async (e) => {
    setUploadFlag(true);
    try {
      if (e.target.files[0].size / 1024 / 1024 > 5) {
        setUploadFlag(false);
        document.getElementById("uploadInputd").value = "";

        throw new Error(
          "Please make sure the file you are attaching are below 5 mb "
        );
      }
      let formData = new FormData();
      formData.append("file", e.target.files[0]);
      let res = await Sd_DashboardService.upload_document(
        formData,
        "donations",
        templeID
      );
      toast.info("File Uploaded");
      setUploadLink(res);
      if (err.find((e) => e.type === "upload")) {
        let data = err.filter((e) => e.type !== "upload");
        setErr(data);
      }
      setUploadFlag(false);
    } catch (e) {
      toast.error(e.message);
      setUploadLink("");
      setUploadFlag(false);
      document.getElementById("uploadInputd").value = "";

      // let upload = document.getElementById("upload-image-donation");
      // upload.files = [];
      if (e.message === "Session Expired") {
        logout();

        history?.push(
          `/${language?.code}/devotee/signin?to=devotee-app/online-booking/donations`
        );
      }
    }
  };

  const handleSubmit = async () => {
    setloading(true);
    let a = true;

    if (a) {
      let data = [...err];
      if (address1 === "") {
        data.push({ type: "address1", msg: "Please enter a valid address" });
      } else if (address1.length < 3) {
        data.push({ type: "address1", msg: "Minimum 3 characters" });
      }
      if (mobile.substring(0, 2) === "91") {
        if (mobile.length !== 12) {
          data.push({ type: "phone", msg: "Invalid mobile number" });
        }
      } else {
        if (mobile.length < 8) {
          data.push({ type: "phone", msg: "Invalid mobile number" });
        }
      }
      if (city === "") {
        data.push({ type: "city", msg: "Please enter a city" });
      } else if (city.length < 3) {
        data.push({ type: "city", msg: "Minimum 3 characters" });
      }
      if (state === "") {
        data.push({ type: "state", msg: "Please select your state" });
      }

      if (zip.length === 0 || zip.length < 6) {
        data.push({ type: "zip", msg: "Invalid zipcode" });
      }

      if (country === "") {
        data.push({ type: "country", msg: "Please select a country" });
      }

      if (donation === "" || donation?.length === 0) {
        data.push({ type: "type", msg: "Select a donation type" });
      }

      if (amount === "") {
        data.push({ type: "amount", msg: "Please enter a donation amount" });
      } else {
        if (amount < donation?.price) {
          data.push({
            type: "amount",
            msg: `Minimum donation amount is ${donation?.price}`,
          });
        }
      }

      if (donor === "") {
        data.push({ type: "donor", msg: "Please enter a donor/nominee name" });
      } else if (donor.length < 3) {
        data.push({ type: "donor", msg: "Name must be minimum 3 characters" });
      }

      if (acceptFlag === false) {
        data.push({
          type: "acceptFlag",
          msg: "Please accept terms and conditions",
        });
      }
      if (amount >= 50000) {
        if (docNumber !== "") {
          if (docType === "pan") {
            let txt = docNumber.toUpperCase();
            let regex =
              /[a-zA-Z]{3}[PCHFATBLJG]{1}[a-zA-Z]{1}[0-9]{4}[a-zA-Z]{1}$/;
            let pan = {
              C: "Company",
              P: "Personal",
              H: "Hindu Undivided Family (HUF)",
              F: "Firm",
              A: "Association of Persons (AOP)",
              T: "AOP (Trust)",
              B: "Body of Individuals (BOI)",
              L: "Local Authority",
              J: "Artificial Juridical Person",
              G: "Govt",
            };
            pan = pan[txt[3]];
            if (regex.test(txt)) {
              if (pan !== "Personal") {
                data.push({ type: "docNumber", msg: "Invalid pan number" });
              }
            } else data.push({ type: "docNumber", msg: "Invalid pan number" });
          } else if (docType === "ration") {
            let regex = /^([a-zA-Z0-9]){8,12}\s*$/;
            if (!regex.test(docNumber.toUpperCase())) {
              data.push({ type: "docNumber", msg: "Invalid ration number" });
            }
          } else if (docType === "driving") {
            let regex =
              /^(([A-Z]{2}[0-9]{2})( )|([A-Z]{2}-[0-9]{2}))((19|20)[0-9][0-9])[0-9]{7}$/;
            if (!regex.test(docNumber.toUpperCase())) {
              // data.push({
              //   type: "docNumber",
              //   msg: "Invalid driving license number",
              // });
            }
          } else if (docType === "voter") {
            let regex = /^([a-zA-Z]){3}([0-9]){7}?$/;
            if (!regex.test(docNumber.toUpperCase())) {
              data.push({ type: "docNumber", msg: "Invalid voter id" });
            }
          } else if (docType === "aadhar") {
            let regex = /^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/;
            if (!regex.test(docNumber.toUpperCase())) {
              data.push({ type: "docNumber", msg: "Invalid AADHAAR number" });
            }
          } else if (docType === "passport") {
            let regex = /^[A-Za-z0-9]{6,15}$/;
            if (!regex.test(docNumber.toUpperCase())) {
              data.push({ type: "docNumber", msg: "Invalid passport id" });
            }
          }
        }
        // if (uploadLink === "") {
        //   data.push({type: "upload", msg: "Please attach a valid id proof"});
        // }
        if (docType !== "") {
          if (uploadLink === "") {
            data.push({
              type: "upload",
              msg: "Please attach a valid id proof",
            });
          }
        }
      }

      if (
        donation?.name !== "SBMS Srisaila TV" &&
        donation?.name !== "SBMS Swachh Srisailam" &&
        donation?.name !== "SBMS Pranadana Trust"
      ) {
        if (gender === "") {
          data.push({ type: "gender", msg: "Please select a gender" });
        }
        if (dob === "") {
          data.push({ type: "dob", msg: "Please select a date" });
        }

        if (calenderType === "english") {
          if (startDate === "") {
            data.push({ type: "date", msg: "Please select a date" });
          }
        }
      }

      setErr(data);
      if (data.length !== 0) {
        setloading(false);
        ref.current?.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
        return;
      }
    }

    try {
      let dto = {
        donationId: Number(donation?.id),
        devoteeName: donor,
        amount: Number(amount),
        address: capitalize(address1),
        city: capitalize(city),
        state: state,
        country: country,
        pinCode: Number(zip),
        contact: `+${mobile}`,
      };
      if (
        donation?.name !== "SBMS Srisaila TV" &&
        donation?.name !== "SBMS Swachh Srisailam" &&
        donation?.name !== "SBMS Pranadana Trust"
      ) {
        dto.dob = moment(dob).format("YYYY-MM-DD");
        dto.gender = gender;
        dto.calendarType = calenderType;
        if (calenderType === "telugu") {
          dto.masam = masam;
          dto.paksham = paksham;
          dto.tithi = tithi;
        } else if (calenderType === "english") {
          dto.bookingDate = moment(startDate).format("YYYY-MM-DD");
        }
      }
      if (docType && uploadLink) {
        dto.panCardFileURL = uploadLink.path
          ? uploadLink.path
          : uploadLink.fileUrl; // req
        dto.documentType = docType?.toLowerCase(); // req
      }
      if (gothram !== "") {
        dto.gothram = capitalize(gothram);
      }
      if (address2 !== "") {
        dto.address2 = capitalize(address2);
      }
      if (amount >= 50000 && docNumber !== "") {
        dto.panCardId = docNumber;
      }
      if (occasion) {
        dto.occasion = capitalize(occasion); // req
      }

      // if (donation?.name === "SBMS AnnaPrasadam Trust") {
      //   dto.anadanam_type = annadanamType;
      // }

      let subDataObj = {
        cartContains: ["donation"],
        donationBookings: [dto],
        returnPriceStats: true,
      };

      let res = await ParokshaSevaService.bookings(subDataObj, templeID);

      if (res) {
        let data = {
          donor,
          gothram,
          calenderType,
          docType,
          docNumber,
          uploadLink,
          amount,
          donation,
          address1,
          address2,
          city,
          country,
          state,
          zip,
          mobile,
          dob,
          gender,
          res,
          templeID,
        };
        if (occasion !== "") {
          data.occasion = capitalize(occasion);
        }

        if (calenderType === "telugu") {
          (data.masam = masam), (data.paksham = paksham), (data.tithi = tithi);
        } else if (calenderType === "english") {
          data.date = startDate;
        }
        setcurrentBookingDonation(data);

        history?.push(
          `/${language?.code}/devotee-app/online-booking/donations/confirm`
        );
      }
    } catch (e) {
      ref.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });

      toast.error(e.message);
      if (e.message === "Session Expired") {
        logout();
        history?.push(
          `/${language?.code}/devotee/signin?to=devotee-app/online-booking/donations`
        );
      }
      setloading(false);

      return;
    }
  };

  const onSelectHandler = (e) => {
    console.log(e.target.value);
    let data = JSON.parse(e.target.value);
    setAmount("");
    setDonation(data);
  };

  const fnCalculateAge = (userDateinput) => {
    let birthDate = new Date(userDateinput);
    let difference = Date.now() - birthDate.getTime();
    let ageDate = new Date(difference);
    let calculatedAge = Math.abs(ageDate.getUTCFullYear() - 1970);
    return calculatedAge;
  };

  const autoFillFunction = (name) => {
    let array = [];

    const userCLick = () => {
      userData?.firstName || userData?.lastName
        ? setDonar(`${userData?.firstName} ${userData?.lastName}`)
        : setDonar(userData?.displayName);
      userData?.gothram ? setGothram(userData?.gothram) : setGothram("");

      userData?.city ? setCity(userData?.city) : setCity("");
      userData?.country && setCountry(userData?.country);
      userData?.address1 ? setAddress1(userData?.address1) : setAddress1("");
      userData?.address2 ? setAddress2(userData?.address2) : setAddress2("");
      userData?.state ? setState(userData?.state) : setState("");
      userData?.gender ? setGender(userData?.gender) : setGender("");
      userData?.dateOfBirth
        ? setDob(new Date(userData?.dateOfBirth))
        : setDob("");

      userData?.phone
        ? setMobile(userData?.phone?.replace?.("+", ""))
        : setMobile("");
      userData?.pinCode ? setZip(userData?.pinCode) : setZip("");
      // if (userData?.idProofType?.toLowerCase() === "pan") {
      //   userData?.docFileUrl
      //     ? setUploadLink(userData?.docFileUrl)
      //     : setUploadLink("");
      //   userData?.idProofType
      //     ? setDockType(userData?.idProofType?.toLowerCase())
      //     : setDockType("");
      //   userData?.idProofNumber
      //     ? setdocNumber(userData?.idProofNumber)
      //     : setdocNumber("");
      // } else {
      //   setDockType("");
      //   setdocNumber("");
      //   setUploadLink("");
      // }
      let data = err.filter((e) => {
        if (
          e.type !== "donor" &&
          e.type !== "gothram" &&
          e.type !== "city" &&
          e.type !== "address1" &&
          e.type !== "state" &&
          e.type !== "country" &&
          e.type !== "phone" &&
          e.type !== "zip" &&
          e.type !== "upload" &&
          e.type !== "docNumber" &&
          e.type !== "docType" &&
          e.type !== "address2" &&
          e.type !== "dob" &&
          e.type !== "gender"
        ) {
          return e;
        }
      });
      setErr(data);

      setAutoFill("none");
    };
    if (
      userData?.firstName?.toLowerCase()?.includes(name?.toLowerCase()) ||
      userData?.lastName?.toLowerCase()?.includes(name?.toLowerCase())
    ) {
      array.push(
        <p
          className="sd-autofill-div"
          onClick={() => {
            userCLick();
          }}
        >
          <p
            style={{ textTransform: "capitalize" }}
            onClick={() => {
              userCLick();
            }}
          >
            Name:{" "}
            {userData?.firstName ? userData?.firstName : userData?.displayName}
            <span style={{ marginLeft: "3px" }}>{userData?.lastName}</span>
          </p>
          {userData?.dateOfBirth && (
            <p
              onClick={() => {
                userCLick();
              }}
            >
              Age:
              {fnCalculateAge(userData?.dateOfBirth)} yrs
            </p>
          )}
          {userData?.gender && (
            <p
              onClick={() => {
                userCLick();
              }}
            >
              Gender: {userData?.gender?.toLowerCase()}
            </p>
          )}
        </p>
      );
    }
    if (devoteeList !== null) {
      devoteeList.forEach((items) => {
        if (
          items?.firstName?.toLowerCase()?.includes(name?.toLowerCase()) ||
          items?.lastName?.toLowerCase()?.includes(name?.toLowerCase())
        ) {
          const onClicks = () => {
            items?.firstName || items?.lastName
              ? setDonar(`${items?.firstName} ${items?.lastName}`)
              : setDonar("");
            items?.gothram ? setGothram(items?.gothram) : setGothram("");
            items?.gender
              ? setGender(items?.gender.toLowerCase())
              : setGender("");
            items?.dob ? setDob(new Date(items?.dob)) : setDob("");

            if (items?.idProofType?.toLowerCase() === "pan") {
              items?.docFileUrl
                ? setUploadLink(items?.docFileUrl)
                : setUploadLink("");
              items?.idProofType
                ? setDockType(items?.idProofType)
                : setDockType("");
              items?.idProofNumber
                ? setdocNumber(items?.idProofNumber)
                : setdocNumber("");
            } else {
              setDockType("pan");
              setdocNumber("");
              setUploadLink("");
            }
            let data = err.filter((e) => {
              if (
                e.type !== "donor" &&
                e.type !== "gothram" &&
                e.type !== "upload" &&
                e.type !== "docNumber" &&
                e.type !== "docType" &&
                e.type !== "dob" &&
                e.type !== "gender"
              ) {
                return e;
              }
            });
            setErr(data);
            setAutoFill("none");
          };
          array.push(
            <p
              key={items.id + "autofill"}
              className="sd-autofill-div"
              onClick={() => {
                onClicks();
              }}
            >
              <p
                onClick={() => {
                  onClicks();
                }}
              >
                Name: {items?.firstName}
                <span style={{ marginLeft: "3px" }}>{items?.lastName}</span>
              </p>
              <p
                onClick={() => {
                  onClicks();
                }}
              >
                Age:
                {fnCalculateAge(items?.dob)} yrs
              </p>
              <p
                onClick={() => {
                  onClicks();
                }}
              >
                Gender: {items?.gender}
              </p>
            </p>
          );
        }
      });
    }

    if (array.length !== 0) {
      return (
        <div className="sd-autofill bg">
          {array.map((item) => {
            return item;
          })}
        </div>
      );
    } else {
      if (name === "") {
        let demo = (
          <p
            className="sd-autofill-div"
            onClick={() => {
              userCLick();
            }}
          >
            <p
              style={{ textTransform: "capitalize" }}
              onClick={() => {
                userCLick();
              }}
            >
              Name:{" "}
              {userData?.firstName
                ? userData?.firstName
                : userData?.displayName}
              <span style={{ marginLeft: "3px" }}>{userData?.lastName}</span>
            </p>
            {userData?.dateOfBirth && (
              <p
                onClick={() => {
                  userCLick();
                }}
              >
                Age:
                {fnCalculateAge(userData?.dateOfBirth)} yrs
              </p>
            )}
            {userData?.gender && (
              <p
                onClick={() => {
                  userCLick();
                }}
              >
                Gender: {userData?.gender}
              </p>
            )}
          </p>
        );

        let a = [...array, demo];
        return (
          <div className="sd-autofill bg">
            {a.map((item) => {
              return item;
            })}{" "}
          </div>
        );
      } else {
        return "";
      }
    }
  };
  //-------------------------------------------------------------functions--------------------------------------------------------//
  return (
    <>
      {loading ? (
        <Sd_loading dist="67%" />
      ) : (
        <div
          className="col-xs-12 col-sm-12 col-md-9 col-lg-9 sd-dashboard"
          style={{
            minHeight: document.getElementById("side-menu")?.offsetHeight,
          }}
        >
          <div className="col-xs-12 col-sm-12 col-md-11 col-lg-10 pl-0 xs-pr-0 sd-profile sd-seva-form">
            <h2 className="sd-side-heading fw400">
              <span className="fw700">Donations</span>
            </h2>
            <div className="clearfix">
              <form className="sd-form">
                <div className="form-group col-xs-12 col-sm-12 col-md-12 col-lg-12 xssm-pr-0 pl-0 pr-0">
                  <label ref={ref}>
                    Select Donation Type<span className="sd-imp">*</span>
                  </label>
                  <select
                    placeholder="Select"
                    className={
                      err?.find((e) => e.type === `type`)
                        ? "form-control sd-red"
                        : donation !== ""
                        ? "form-control sd-voilet"
                        : "form-control"
                    }
                    onChange={(e) => {
                      if (err.find((e) => e.type === "type")) {
                        let data = err.filter((e) => e.type !== "type");
                        setErr(data);
                      }
                      if (e.target.value === "") {
                        return;
                      }
                      onSelectHandler(e);
                    }}
                  >
                    <option value="" disabled selected hidden>
                      Select a donation type{" "}
                    </option>{" "}
                    {selectArray?.map((item) => {
                      if (
                        item?.disabled === true ||
                        item?.name === "SBMS Saswatha AnnaPrasadam Trust"
                      ) {
                        return;
                      }
                      return (
                        <option
                          style={{ cursor: "pointer !important" }}
                          key={
                            item?.name?.includes("AnnaPrasadam")
                              ? "SBMS AnnaPrasadam Trust"
                              : item?.name
                          }
                          value={JSON.stringify(item)}
                          selected={
                            donation?.name === item?.name
                              ? true
                              : donation?.name?.includes("AnnaPrasadam") &&
                                item?.name.includes("AnnaPrasadam")
                              ? true
                              : false
                          }
                        >
                          {item?.name.includes("AnnaPrasadam")
                            ? "SBMS AnnaPrasadam Trust"
                            : item?.name}{" "}
                          - (Min - Rs.{item?.price}{" "}
                          {item?.maxAmount ? `- Max -Rs${item?.maxAmount}` : ""}{" "}
                          )
                        </option>
                      );
                    })}
                  </select>
                  <p className="d-EM ">
                    {err.find((e) => e.type === "type")?.msg}
                  </p>
                </div>
                <hr className="clearfix"></hr>
                {donation?.name?.includes("AnnaPrasadam") ? (
                  <>
                    <div className="form-group col-xs-12 col-sm-12 col-md-12 col-lg-12  xssm-pr-0 pl-0 pr-0">
                      <label>
                        Annadanam Type<span className="sd-imp">*</span>
                      </label>
                      <div className="clearfix">
                        {selectArray.map((item, i) => {
                          if (item?.name?.includes("AnnaPrasadam")) {
                            return (
                              <div
                                className="col-xs-12 col-sm-12 col-md-6 col-lg-6  xssm-pr-0 pl-0"
                                key={i + "annadanam"}
                              >
                                <div
                                  className={
                                    item?.name === donation?.name
                                      ? "sd-radio-select sd-voilet"
                                      : "sd-radio-select"
                                  }
                                  onClick={() => {
                                    setDonation(item);
                                    setAmount("");
                                  }}
                                >
                                  <input
                                    type="radio"
                                    name={item?.name}
                                    id={item?.name}
                                    // value={}
                                    onChange={() => {
                                      setDonation(item);
                                      // setAnnadamanType("Annadanam for a day");
                                    }}
                                    checked={
                                      item?.name === donation?.name
                                        ? true
                                        : false
                                    }
                                  />
                                  <label
                                    htmlFor={item?.name}
                                    className="radio-inline"
                                    onClick={() => {
                                      setDonation(item);
                                    }}
                                  >
                                    <p>
                                      {item?.name === "SBMS AnnaPrasadam Trust"
                                        ? "Annadanam for a day"
                                        : "Saswatha Annadanam"}
                                      <br></br>
                                      <span>
                                        Minimum Amount Rs. {item?.price}
                                        .00
                                      </span>
                                    </p>
                                  </label>
                                </div>
                              </div>
                            );
                          }
                        })}
                        {/*                        
                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6  xssm-pl-0 pr-0">
                          <div
                            className={
                              annadanamType === "Saswatha Annadanam"
                                ? "sd-radio-select sd-voilet"
                                : "sd-radio-select"
                            }
                          >
                            <input
                              type="radio"
                              name="optradio2"
                              id="Annadanam2"
                              value={annadanamType}
                              onChange={() => {
                                setAnnadamanType("Saswatha Annadanam");
                              }}
                              checked={
                                annadanamType !== "Annadanam for a day"
                                  ? true
                                  : false
                              }
                            />
                            <label
                              htmlFor="Annadanam2"
                              className="radio-inline"
                            >
                              <p>
                                Saswatha Annadanam <br></br>
                                <span>Minimum Amount Rs. 5000.00</span>
                              </p>
                            </label>
                          </div>
                        </div>
                      */}
                      </div>
                    </div>
                    <hr className="clearfix"></hr>
                  </>
                ) : (
                  ""
                )}
                <div className="clearfix">
                  <div className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6  xssm-pr-0 pl-0">
                    <label>
                      Donor / Nominee Name<span className="sd-imp">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Please enter a donor/nominee name
                      "
                      value={donor}
                      maxLength="30"
                      className={
                        err?.find((e) => e.type === `donor`)
                          ? "form-control sd-red"
                          : donor !== ""
                          ? "form-control sd-voilet"
                          : "form-control"
                      }
                      onFocus={() => {
                        if (autoFill === `autofill`) {
                          setAutoFill("none");
                        } else {
                          setAutoFill(`autofill`);
                        }
                      }}
                      onBlur={() => {
                        if (autoFill === `autofill`) {
                          setTimeout(() => {
                            setAutoFill("none");
                          }, 250);
                        }
                      }}
                      onChange={(e) => {
                        if (
                          /^[a-zA-Z ]*$/.test(String(e.target.value)) ||
                          e.target.value === ""
                        ) {
                          if (err.find((e) => e.type === "donor")) {
                            let data = err.filter((e) => e.type !== "donor");
                            setErr(data);
                          }
                          setDonar(e.target.value);
                        }
                      }}
                    />
                    {autoFill === `autofill` && autoFillFunction(donor)}

                    <p className="d-EM mr-15">
                      {err.find((e) => e.type === "donor")?.msg}
                    </p>
                  </div>
                  <div className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6  xssm-pl-0 xssm-pr-0  pr-0">
                    <label>Gothram</label>
                    <input
                      type="text"
                      maxLength="30"
                      placeholder="Please enter your gothram."
                      className={
                        err?.find((e) => e.type === `gothram`)
                          ? "form-control sd-red"
                          : gothram !== ""
                          ? "form-control sd-voilet"
                          : "form-control"
                      }
                      value={gothram}
                      onChange={(e) => {
                        if (
                          /^[a-zA-Z]*$/.test(String(e.target.value)) ||
                          e.target.value === ""
                        ) {
                          if (err.find((e) => e.type === "gothram")) {
                            let data = err.filter((e) => e.type !== "gothram");
                            setErr(data);
                          }
                          setGothram(e.target.value);
                        }
                      }}
                    />
                    <p className="d-EM">
                      {err.find((e) => e.type === "gothram")?.msg}
                    </p>
                  </div>
                </div>
                <div className="clearfix">
                  <div className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6  xssm-pr-0 pl-0">
                    <label>
                      Amount<span className="sd-imp">*</span>
                    </label>
                    <input
                      type={"number"}
                      maxLength="6"
                      onWheel={(event) => event.currentTarget.blur()}
                      className={
                        err?.find((e) => e.type === `amount`)
                          ? "form-control sd-red"
                          : amount !== ""
                          ? "form-control sd-voilet"
                          : "form-control"
                      }
                      style={{ textTransform: "none" }}
                      placeholder={`Minimum donation amount Rs. ${
                        donation?.price !== undefined ? donation?.price : ""
                      }`}
                      value={amount}
                      onKeyDown={(e) => {
                        if (e.key === "+" || e.key === "-" || e.key === ".") {
                          e.preventDefault();
                        }
                      }}
                      onChange={(e) => {
                        if (e.target.value > donation?.maxAmount) {
                          return;
                        }
                        if (e.target.value < 0) {
                          return;
                        }
                        if (err.find((e) => e.type === "amount")) {
                          let data = err.filter((e) => e.type !== "amount");
                          setErr(data);
                        }
                        if (donation?.name === "SBMS AnnaPrasadam Trust") {
                          if (e.target.value > 4999) {
                            return;
                          } else {
                            setAmount(e.target.value);
                          }
                        } else {
                          setAmount(e.target.value);
                        }
                      }}
                    />

                    <p className="d-EM mr-15">
                      {err.find((e) => e.type === "amount")?.msg}
                    </p>
                  </div>
                  <div className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6  xssm-pl-0 xssm-pr-0  pr-0">
                    <label>Occasion / Auspicious Day</label>
                    <input
                      type="text"
                      maxLength="30"
                      placeholder="Please enter the occasion. Eg: Birthday"
                      className={
                        occasion !== ""
                          ? "form-control sd-voilet"
                          : "form-control"
                      }
                      value={occasion}
                      onChange={(e) => {
                        if (
                          AddressRegex.test(e.target.value) ||
                          e.target.value === ""
                        ) {
                          setOcassion(e.target.value);
                        }
                      }}
                    />
                  </div>
                  {donation?.name !== "SBMS Srisaila TV" &&
                  donation?.name !== "SBMS Swachh Srisailam" &&
                  donation?.name !== "SBMS Pranadana Trust" ? (
                    <>
                      {" "}
                      <div className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6  xssm-pl-0 xssm-pr-0 pl-0">
                        <label>
                          Date of Birth<span className="sd-imp">*</span>
                        </label>
                        <div
                          className={
                            err.find((e) => e.type === "dob")
                              ? "sd-red sd-dob customDatePickerWidth"
                              : dob !== ""
                              ? "sd-voilet sd-dob customDatePickerWidth"
                              : "sd-dob customDatePickerWidth"
                          }
                        >
                          <DatePicker
                            dayPlaceholder={"DD"}
                            monthPlaceholder={"MM"}
                            yearPlaceholder={"YYYY"}
                            style={{
                              width: "90%",
                              border: "none",
                              backgroundColor: "blue",
                            }}
                            format="d-M-y"
                            maxDate={addDays(new Date(), -6570)}
                            calendarIcon={<img src={sd_dashboard_dob} alt="" />}
                            clearIcon={null}
                            value={dob}
                            onChange={(date) => {
                              if (
                                err.find(
                                  (e) => e.type === "dob" || e.type === "common"
                                )
                              ) {
                                let array = err.filter((e) => {
                                  if (e.type !== "dob" && e.type !== "common") {
                                    return e;
                                  }
                                });
                                setErr(array);
                              }
                              setDob(date);
                            }}
                          />
                          <p
                            className="sd-error-m"
                            style={{ fontSize: "15.4px" }}
                          >
                            {err.find((e) => e.type === "dob")?.msg}
                          </p>
                        </div>
                      </div>
                      <div className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6  xssm-pl-0 pr-0 ">
                        <label>
                          Gender<span className="sd-imp">*</span>
                        </label>
                        <select
                          className={
                            err.find((e) => e.type === "gender")
                              ? "form-control sd-red"
                              : gender !== ""
                              ? "form-control sd-voilet"
                              : "form-control"
                          }
                          placeholder="Select"
                          value={gender}
                          defaultValue={gender}
                          onChange={(e) => {
                            if (
                              err.find(
                                (e) =>
                                  e.type === "gender" || e.type === "common"
                              )
                            ) {
                              let data = err.filter((e) => {
                                if (
                                  e.type !== "gender" &&
                                  e.type !== "common"
                                ) {
                                  return e;
                                }
                              });
                              setErr(data);
                            }
                            setGender(e.target.value);
                          }}
                        >
                          <option key="" value="" style={{ display: "none" }}>
                            Select a gender
                          </option>
                          <option key="male" value="male">
                            Male
                          </option>
                          <option key="female" value="female">
                            Female
                          </option>
                          <option key="others" value="others">
                            Others
                          </option>
                        </select>
                        <p className="sd-error-m mr-15">
                          {err.find((e) => e.type === "gender")?.msg}
                        </p>
                      </div>
                    </>
                  ) : (
                    ""
                  )}{" "}
                </div>
                <hr className="clearfix"></hr>
                <div className="clearfix">
                  <div className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6  xssm-pr-0 pl-0">
                    <label>
                      Address Lane 1<span className="sd-imp">*</span>
                    </label>
                    <input
                      type="text"
                      maxLength="50"
                      placeholder="House/building number, street number"
                      className={
                        err.find((e) => e.type === "address1")
                          ? "form-control sd-red"
                          : address1 !== ""
                          ? "form-control sd-voilet"
                          : "form-control"
                      }
                      value={address1}
                      onChange={(e) => {
                        if (
                          AddressRegex.test(e.target.value) ||
                          e.target.value === ""
                        ) {
                          if (
                            err.find(
                              (e) =>
                                e.type === "address1" || e.type === "common"
                            )
                          ) {
                            let data = err.filter((e) => {
                              if (
                                e.type !== "address1" &&
                                e.type !== "common"
                              ) {
                                return e;
                              }
                            });
                            setErr(data);
                          }
                          setAddress1(e.target.value);
                        }
                      }}
                    />
                    <p className="d-EM mr-15">
                      {err.find((e) => e.type === "address1")
                        ? err.find((e) => e.type === "address1")?.msg
                        : ""}
                    </p>
                  </div>
                  <div className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6  xssm-pl-0  xssm-pr-0 pr-0 ">
                    <label>Address Lane 2</label>
                    <input
                      type="text"
                      maxLength="50"
                      className={
                        err.find((e) => e.type === "address2")
                          ? "form-control sd-red"
                          : address2 !== ""
                          ? "form-control sd-voilet"
                          : "form-control"
                      }
                      placeholder="building name, street name, etc"
                      value={address2}
                      onChange={(e) => {
                        if (
                          AddressRegex.test(e.target.value) ||
                          e.target.value === ""
                        ) {
                          if (
                            err.find(
                              (e) =>
                                e.type === "address2" || e.type === "common"
                            )
                          ) {
                            let data = err.filter((e) => {
                              if (
                                e.type !== "address2" &&
                                e.type !== "common"
                              ) {
                                return e;
                              }
                            });
                            setErr(data);
                          }
                          setAddress2(e.target.value);
                        }
                      }}
                    />
                    <p className="d-EM mr-15">
                      {err.find((e) => e.type === "address2")
                        ? err.find((e) => e.type === "address2")?.msg
                        : ""}
                    </p>
                  </div>
                </div>
                <div className="clearfix">
                  <div className="form-group col-xs-12 col-sm-12 col-md-3 col-lg-3  xssm-pr-0 pl-0">
                    <label>
                      City<span className="sd-imp">*</span>
                    </label>
                    <input
                      type="text"
                      maxLength="30"
                      className={
                        err.find((e) => e.type === "city")
                          ? "form-control sd-red"
                          : city !== ""
                          ? "form-control sd-voilet"
                          : "form-control"
                      }
                      value={city}
                      placeholder="Please enter your city"
                      onChange={(e) => {
                        if (
                          AddressRegex.test(e.target.value) ||
                          e.target.value === ""
                        ) {
                          if (
                            err.find(
                              (e) => e.type === "city" || e.type === "common"
                            )
                          ) {
                            let data = err.filter((e) => {
                              if (e.type !== "city" && e.type !== "common") {
                                return e;
                              }
                            });
                            setErr(data);
                          }
                          setCity(e.target.value);
                        }
                      }}
                    />
                    <p className="d-EM mr-15">
                      {err.find((e) => e.type === "city")
                        ? err.find((e) => e.type === "city")?.msg
                        : ""}
                    </p>
                  </div>

                  <div className="form-group col-xs-12 col-sm-12 col-md-3 col-lg-3  xssm-pr-0 ">
                    <label>
                      Zipcode<span className="sd-imp">*</span>
                    </label>
                    <input
                      type="text"
                      onWheel={(event) => event.currentTarget.blur()}
                      className={
                        err.find((e) => e.type === "zip")
                          ? "form-control sd-red"
                          : zip !== ""
                          ? "form-control sd-voilet"
                          : "form-control"
                      }
                      value={zip}
                      maxLength={6}
                      placeholder="Enter your zipcode"
                      onChange={(e) => {
                        if (
                          zipcodeRegex.test(e.target.value) ||
                          e.target.value === ""
                        ) {
                          if (
                            err.find(
                              (e) => e.type === "zip" || e.type === "common"
                            )
                          ) {
                            let data = err.filter((e) => {
                              if (e.type !== "zip" && e.type !== "common") {
                                return e;
                              }
                            });
                            setErr(data);
                          }
                          if (e.target.value.length < 12) {
                            setZip(e.target.value.toUpperCase());
                          }
                        }
                      }}
                    />
                    <p className="d-EM mr-15">
                      {err.find((e) => e.type === "zip")
                        ? err.find((e) => e.type === "zip")?.msg
                        : ""}
                    </p>
                  </div>

                  <div className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6  xssm-pr-0 pr-0 ">
                    <label>
                      State<span className="sd-imp">*</span>
                    </label>
                    <RegionDropdown
                      country={country}
                      classes={
                        err.find((e) => e.type === "state")
                          ? "sd-red w-100 sd-border-none"
                          : state !== ""
                          ? " sd-voilet w-100 sd-border-none"
                          : "w-100 sd-border-none"
                      }
                      value={state}
                      showDefaultOption={true}
                      blankOptionLabel="Select a State"
                      onChange={(val) => {
                        if (
                          err.find(
                            (e) => e.type === "state" || e.type === "common"
                          )
                        ) {
                          let data = err.filter((e) => {
                            if (e.type !== "state" && e.type !== "common") {
                              return e;
                            }
                          });
                          setErr(data);
                        }
                        setState(val);
                      }}
                    />
                    <p className="d-EM ">
                      {err.find((e) => e.type === "state")
                        ? err.find((e) => e.type === "state")?.msg
                        : ""}
                    </p>
                  </div>
                </div>
                <div className="clearfix">
                  <div className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6  xssm-pl-0 pl-0 xssm-pr-0 ">
                    <label>
                      Country<span className="sd-imp">*</span>
                    </label>
                    <CountryDropdown
                      value={country}
                      classes={
                        err.find((e) => e.type === "country")
                          ? "sd-red w-100 "
                          : country !== ""
                          ? " sd-voilet w-100 sd-border-none"
                          : "w-100 sd-border-none"
                      }
                      showDefaultOption={true}
                      defaultValue="India"
                      defaultOptionLabel="Select a Country"
                      onChange={(val) => {
                        if (
                          err.find(
                            (e) => e.type === "country" || e.type === "common"
                          )
                        ) {
                          let data = err.filter((e) => {
                            if (e.type !== "country" && e.type !== "common") {
                              return e;
                            }
                          });
                          setErr(data);
                        }
                        setCountry(val);
                      }}
                    />
                    <p className="d-EM mr-15">
                      {err.find((e) => e.type === "country")
                        ? err.find((e) => e.type === "country")?.msg
                        : ""}
                    </p>
                  </div>

                  <div className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6  xssm-pr-0 pr-0">
                    <label>
                      Mobile Number<span className="sd-imp">*</span>
                    </label>

                    <div className={"sd-profile-mobile"}>
                      {/* <span className="prefix">
                        <img src={sd_india_flag} alt="india" height="20px" /> +
                      </span> */}
                      <PhoneInput
                        inputExtraProps={{
                          autoFocus: true,
                          enableSearch: true,
                          countryCodeEditable: false,
                        }}
                        onlyCountries={["in"]}
                        // onlyCountries={[
                        //   "in",
                        //   "au",
                        //   "ca",
                        //   "sg",
                        //   "ch",
                        //   "gb",
                        //   "us",
                        // ]}
                        placeholder="Enter Your 10 digit mobile number"
                        maxLength="15"
                        countryCodeEditable={false}
                        containerClass={
                          err?.find((e) => e.type === "phone")
                            ? "sd-phone-voilet sd-red"
                            : mobile !== ""
                            ? "sd-phone-voilet"
                            : ""
                        }
                        country="in"
                        value={mobile}
                        onChange={(e) => {
                          if (
                            err?.find(
                              (e) => e.type === "phone" || e.type === "common"
                            )
                          ) {
                            let array = err.filter((e) => {
                              if (e.type !== "phone" && e.type !== "common") {
                                return e;
                              }
                            });
                            setErr(array);
                          }
                          setMobile(e);
                        }}
                      />
                      <p className="d-EM ">
                        {err.find((e) => e.type === "phone")
                          ? err.find((e) => e.type === "phone")?.msg
                          : ""}
                      </p>
                      {/* <input
                        className={
                          err?.find((e) => e.type === "phone")
                            ? "input-box sd-red"
                            : mobile !== ""
                            ? "sd-voilet input-box"
                            : "input-box"
                        }
                        style={{width: "100%"}}
                        type="number"
                        onWheel={(event) => event.currentTarget.blur()}
                        value={mobile}
                        placeholder="Enter your 10 digit mobile number"
                        onChange={(e) => {
                          if (
                            err?.find(
                              (e) => e.type === "phone" || e.type === "common"
                            )
                          ) {
                            let array = err.filter((e) => {
                              if (e.type !== "phone" && e.type !== "common") {
                                return e;
                              }
                            });
                            setErr(array);
                          }
                          if (e.target.value.length <= 10) {
                            setMobile(e.target.value);
                          }
                        }}
                      /> */}
                    </div>
                  </div>
                </div>
                {donation?.name !== "SBMS Srisaila TV" &&
                donation?.name !== "SBMS Swachh Srisailam" &&
                donation?.name !== "SBMS Pranadana Trust" ? (
                  <>
                    <hr className="clearfix"></hr>
                    <div className="clearfix">
                      <div className="form-group col-xs-12 col-sm-12 col-md-12 col-lg-12  xssm-pr-0 pl-0 pr-0 ">
                        <label>
                          Calendar Type<span className="sd-imp">*</span>
                        </label>
                        <div className="clearfix">
                          <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4  xssm-pr-0 pl-0 pr-0">
                            <div className="sd-radio-select-no-border">
                              <input
                                type="radio"
                                name="optradio1"
                                id="test1"
                                onChange={() => {}}
                                // value={calenderType}
                                checked={
                                  calenderType === "telugu" ? true : false
                                }
                              />
                              <label
                                htmlFor="test1"
                                className="radio-inline"
                                onClick={() => {
                                  //
                                  setCalenderType("telugu");
                                }}
                              >
                                <p>Telugu Calendar</p>
                              </label>
                            </div>
                          </div>
                          <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4  xssm-pl-0 pr-0 pl-0">
                            <div className="sd-radio-select-no-border">
                              <input
                                type="radio"
                                name="optradio2"
                                id="test2"
                                onChange={() => {
                                  //clg
                                }}
                                checked={
                                  calenderType === "english" ? true : false
                                }
                              />
                              <label
                                htmlFor="test2"
                                className={"radio-inline"}
                                onClick={() => {
                                  //

                                  setCalenderType("english");
                                }}
                              >
                                <p>English Calendar</p>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {calenderType === "telugu" ? (
                      <>
                        <div className="form-group col-xs-12 sd-profile-mobile col-sm-12 col-md-6 col-lg-6  xssm-pr-0 pl-0">
                          <label>
                            Masam<span className="sd-imp">*</span>
                          </label>{" "}
                          <select
                            value={masam}
                            className={
                              masam !== ""
                                ? "form-control sd-voilet"
                                : "form-control"
                            }
                            defaultValue={masam}
                            onChange={(e) => {
                              setMasam(e.target.value);
                            }}
                          >
                            <option key={"Chaithram"} value={"Chaithram"}>
                              Chaithram
                            </option>
                            <option key={"Vaisaakham"} value={"Vaisaakham"}>
                              Vaisaakham
                            </option>
                            <option key={"Jyeshttam"} value={"Jyeshttam"}>
                              Jyeshttam{" "}
                            </option>
                            <option key={"Ashadam"} value={"Ashadam"}>
                              Ashadam{" "}
                            </option>
                            <option key={"Sraavanam"} value={"Sraavanam"}>
                              Sraavanam{" "}
                            </option>
                            <option
                              key={"Bhaadhrapadam"}
                              value={"Bhaadhrapadam"}
                            >
                              Bhaadhrapadam{" "}
                            </option>
                            <option key={"Aasveeyujam"} value={"Aasveeyujam"}>
                              Aasveeyujam{" "}
                            </option>
                            <option key={"Kaarthikam"} value={"Kaarthikam"}>
                              Kaarthikam{" "}
                            </option>
                            <option
                              key={"Maargaseersham"}
                              value={"Maargaseersham"}
                            >
                              Maargaseersham{" "}
                            </option>
                            <option key={"Pushyam"} value={"Pushyam"}>
                              Pushyam{" "}
                            </option>
                            <option key={"Maagham"} value={"Maagham"}>
                              Maagham{" "}
                            </option>
                            <option key={"Phalgunam"} value={"Phalgunam"}>
                              Phalgunam{" "}
                            </option>
                          </select>{" "}
                        </div>
                        <div className="form-group col-xs-12 col-sm-12 col-md-3 col-lg-3  xssm-pl-0 xssm-pr-0">
                          <label>
                            Paksham<span className="sd-imp">*</span>
                          </label>
                          <select
                            className={
                              paksham !== ""
                                ? "form-control sd-voilet"
                                : "form-control"
                            }
                            value={paksham}
                            defaultValue={paksham}
                            onChange={(e) => {
                              setPaksham(e.target.value);
                            }}
                          >
                            <option key={"Shukla"} value={"Shukla"}>
                              Shukla
                            </option>
                            <option key={"Krishna"} value={"Krishna"}>
                              Krishna
                            </option>
                          </select>
                        </div>
                        <div className="form-group col-xs-12 col-sm-12 col-md-3 col-lg-3  xssm-pl-0 pr-0">
                          <label>
                            Tithi<span className="sd-imp">*</span>
                          </label>
                          <select
                            className={
                              tithi !== ""
                                ? "form-control sd-voilet"
                                : "form-control"
                            }
                            value={tithi}
                            defaultValue={tithi}
                            onChange={(e) => {
                              setTithi(e.target.value);
                            }}
                          >
                            <option key={"Prathama"} value={"Prathama"}>
                              Prathama
                            </option>
                            <option key={"Dwitiya"} value={"Dwitiya"}>
                              Dwitiya
                            </option>
                            <option key={"Tritiya"} value={"Tritiya"}>
                              Tritiya{" "}
                            </option>
                            <option key={"Chaturthi"} value={"Chaturthi"}>
                              Chaturthi{" "}
                            </option>
                            <option key={"Panchami"} value={"Panchami"}>
                              Panchami{" "}
                            </option>
                            <option key={"Shashthi"} value={"Shashthi"}>
                              Shashthi{" "}
                            </option>
                            <option key={"Saptami"} value={"Saptami"}>
                              Saptami
                            </option>
                            <option key={"Ashtami"} value={"Ashtami"}>
                              Ashtami{" "}
                            </option>
                            <option key={"Navami"} value={"Navami"}>
                              Navami{" "}
                            </option>
                            <option key={"Dasami"} value={"Dasami"}>
                              Dasami{" "}
                            </option>
                            <option key={"Ekadasi"} value={"Ekadasi"}>
                              Ekadasi{" "}
                            </option>
                            <option key={"Dvadasi"} value={"Dvadasi"}>
                              Dvadasi{" "}
                            </option>
                            <option key={"Trayodasi"} value={"Trayodasi"}>
                              Trayodasi{" "}
                            </option>
                            <option key={"Chaturdashi"} value={"Chaturdashi"}>
                              Chaturdashi{" "}
                            </option>
                            <option
                              key={
                                paksham === "Shukla" ? "Purnima" : "Amavasya"
                              }
                              value={
                                paksham === "Shukla" ? "Purnima" : "Amavasya"
                              }
                            >
                              {paksham === "Shukla" ? "Purnima" : "Amavasya"}
                            </option>
                          </select>
                        </div>
                      </>
                    ) : (
                      <div
                        className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6  xssm-pl-0 pr-0 ml-0"
                        style={{ paddingLeft: "0px" }}
                      >
                        <label>
                          Select Date<span className="sd-imp">*</span>
                        </label>

                        <div
                          className={
                            err?.find((e) => e.type === `date`)
                              ? "sd-dob customDatePickerWidth sd-red"
                              : startDate !== ""
                              ? "sd-date-voilet sd-dob customDatePickerWidth"
                              : "sd-dob customDatePickerWidth"
                          }
                        >
                          <DatePicker
                            dayPlaceholder="DD"
                            monthPlaceholder="MM"
                            yearPlaceholder="YYYY"
                            style={{
                              width: "90%",
                              border: "none",
                              backgroundColor: "blue",
                            }}
                            format="dd-MM-y"
                            minDate={new Date()}
                            calendarIcon={<img src={sd_dashboard_dob} alt="" />}
                            clearIcon={null}
                            value={startDate}
                            onChange={(date) => {
                              if (err.find((e) => e.type === "date")) {
                                let data = err.filter((e) => e.type !== "date");
                                setErr(data);
                              }
                              setStartDate(date);
                            }}
                          />
                        </div>

                        <p className="d-EM ">
                          {err.find((e) => e.type === "date")?.msg}
                        </p>
                      </div>
                    )}
                  </>
                ) : (
                  ""
                )}

                <hr className="clearfix"></hr>
                {amount > 50000 && (
                  <>
                    <div className="form-group col-xs-12 sd-profile-mobile col-sm-12 col-md-4 col-lg-4  xssm-pr-0 pl-0">
                      <label>PAN Number</label>
                      <input
                        type="text"
                        placeholder="Enter PAN Number"
                        className={
                          err?.find((e) => e.type === `docNumber`)
                            ? "form-control sd-red"
                            : docNumber !== ""
                            ? "form-control sd-voilet"
                            : "form-control"
                        }
                        style={{ paddingLeft: "15px" }}
                        value={docNumber}
                        onChange={(e) => {
                          if (
                            /^[#.0-9a-zA-Z\s]+$/.test(e.target.value) ||
                            e.target.value === ""
                          ) {
                            if (err.find((e) => e.type === "docNumber")) {
                              let data = err.filter(
                                (e) => e.type !== "docNumber"
                              );
                              setErr(data);
                            }
                            setdocNumber(
                              e.target.value
                                ?.toUpperCase()
                                ?.replaceAll?.(" ", "")
                            );
                          }
                        }}
                      />
                      <p className="d-EM mr-15">
                        {err.find((e) => e.type === "docNumber")?.msg}
                      </p>
                    </div>
                    {/* <div className="form-group col-xs-12 col-sm-12 col-md-3 col-lg-3  xssm-pl-0 xssm-pr-0">
                      <label>ID Proof Type</label>
                      <select
                        className={
                          err?.find((e) => e.type === `docType`)
                            ? "form-control sd-red"
                            : docType !== ""
                            ? "form-control sd-voilet"
                            : "form-control"
                        }
                        onChange={(e) => {
                          if (err.find((e) => e.type === "docType")) {
                            let data = err.filter((e) => e.type !== "docType");
                            setErr(data);
                          }
                          if (e.target.value === "") {
                            setDockType("");
                            setUploadLink("");
                            return;
                          }
                          let value = docType;

                          setDockType(e.target.value);
                          if (value !== e.target.value) {
                            setUploadLink("");
                          }
                        }}
                        value={docType}
                      >
                        <option style={{ display: "none" }} value="" key="">
                          Select a ID proof
                        </option>
                        <option key="pan" value="pan">
                          PAN
                        </option>
                        <option key="aadhar" value="aadhar">
                          AADHAAR
                        </option>
                        <option key="passport" value="passport">
                          Passport
                        </option>
                        <option key="driving" value="driving">
                          Driving License
                        </option>
                        <option key="ration" value="ration">
                          Ration Card
                        </option>
                        <option key="voter" value="voter">
                          Voter ID
                        </option>
                        <option key="none" value={""}>
                          None
                        </option>
                      </select>
                      <p className="d-EM mr-15">
                        {err.find((e) => e.type === "docType")?.msg}
                      </p>
                    </div> */}
                    <div
                      className="form-group col-xs-12 col-sm-12 col-md-5 col-lg-5  xssm-pl-0 pr-0 mb-0"
                      style={{ marginBottom: "0px !important" }}
                    >
                      <label>Upload ID Proof</label>
                      {uploadLink !== "" && (
                        <div className="upload-button">
                          <p>
                            {" "}
                            {uploadLink?.originalname
                              ? uploadLink?.originalname
                              : "File Uploaded"}{" "}
                          </p>{" "}
                          <img
                            src={sd_trash}
                            alt="delete"
                            onClick={() => {
                              setUploadLink("");
                            }}
                          />
                        </div>
                      )}
                      {uploadFlag && (
                        <div className="upload-button">
                          <p>Uploading...</p>{" "}
                        </div>
                      )}

                      {uploadLink === "" && !uploadFlag && (
                        <input
                          style={{ padding: "0px 12px" }}
                          type="file"
                          placeholder="Browse"
                          id="uploadInputd"
                          accept="image/png, image/jpeg,image/jpg,.pdf"
                          className={
                            err.find((e) => e.type === "upload")
                              ? "form-control sd-red"
                              : "form-control"
                          }
                          onChange={(e) => {
                            if (err.find((e) => e.type === "upload")) {
                              let data = err.filter((e) => e.type !== "upload");
                              setErr(data);
                            }
                            handleFileUpload(e);
                          }}
                        />
                      )}
                      <p className="d-EM">
                        {err.find((e) => e.type === "upload")?.msg}
                      </p>
                    </div>
                    <hr className="clearfix"></hr>
                  </>
                )}
                <div className="form-group col-xs-12 col-sm-12 col-md-12 col-lg-12  xssm-pl-0 pr-0">
                  <ol className="sd-terms-list">
                    {donation === "" ||
                    donation?.name === "SBMS AnnaPrasadam Trust" ? (
                      <>
                        <li className="active">
                          The subscription period for Saswatha Annadam is
                          forever and Saswatha Arjitha Sevas is 10 years only.
                        </li>
                        <li>
                          The amount paid for Saswatha Annadanam / Saswatha
                          Arjitha sevas will be kept as fixed deposit in a
                          scheduled bank and the interest accrued every year by
                          the FD will be utilized for the performance of
                          Annadanam on a selected day (choice of devotee) once
                          in a year. Please go through the Disclaimer, Privacy
                          Policy and Copyright information in Terms & Conditions
                          provided in the website.
                        </li>
                      </>
                    ) : (
                      <>
                        <li>
                          {pagesData("home")?.title} is an Endowment
                          Organisation which supervises the administrative
                          functions necessary to conduct the various traditional
                          rituals at {pagesData("home")?.title}
                        </li>
                        <li>
                          {pagesData("home")?.title}  does not solicit or
                          canvass for any offering. It has its own fund, the
                          corpus of which, shall include all the amounts
                          received by it, by way of offerings, including
                          offerings deposited in {donation?.name}.
                        </li>
                      </>
                    )}
                  </ol>

                  <div className="form-group col-xs-12 col-sm-12 col-md-12 col-lg-12 xssm-pr-0 pl-0 sd-terms-checkbox">
                    <input
                      type="checkbox"
                      name=""
                      value={acceptFlag}
                      onChange={() => {
                        if (err.find((e) => e.type === "acceptFlag")) {
                          let data = err.filter((e) => e.type !== "acceptFlag");
                          setErr(data);
                        }
                        setAcceptFlag(!acceptFlag);
                      }}
                      checked={acceptFlag ? true : false}
                    />
                    <label
                      htmlFor=""
                      style={{ marginBottom: "0px" }}
                      className="ml-5"
                    >
                      I agree to the{" "}
                      <a
                        onClick={() => {
                          history?.push(
                            `/${language?.code}/terms-and-conditions`
                          );
                        }}
                      >
                        Terms & Conditions
                      </a>{" "}
                      and the
                      <a
                        style={{ marginLeft: "3px" }}
                        onClick={() => {
                          history?.push(`/${language?.code}/privacy-policy`);
                        }}
                      >
                        Privacy Policy
                      </a>
                    </label>
                    <p className="sd-accept">
                      {err.find((e) => e.type === "acceptFlag")?.msg}
                    </p>
                  </div>
                </div>
                <hr className="clearfix"></hr>
                <div className="clearfix mt-50">
                  <a
                    className="sd-btn-orange"
                    onClick={() => {
                      if (!loading && !uploadFlag) {
                        handleSubmit();
                      }
                    }}
                  >
                    Continue
                    <img
                      className="sd-arrow-btn ml-5"
                      src={SD_Right_Arrow_white}
                    />
                  </a>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default Sd_dashboard_online_booking_donations;
