import React from "react";
import SD_Paroksha_Seva_One from "../../Components/body/paroksha-seva/sd-paroksha-seva-component-one";
import pagesData from "../../Utils/data/pages.data";
// import Sd_breadcrumb from "../../Components/sd-common-components/sd-breadcrumb";

const Sd_private_policy = () => {
  return (
    <div>
      <SD_Paroksha_Seva_One from="Privacy Policy" />

      {/* <Sd_breadcrumb from="Privacy Policy"></Sd_breadcrumb> */}
      <div className="sd-t-and-c">
        <div className="sd-t-and-c-inner">
          <div className="top white-bc">
            <h1>Privacy Policy</h1>
            <img
              src={pagesData("home").divider}
              alt={"SD_divider"}
              style={{ height: "20px" }}
            ></img>
          </div>
          <>
            <br />
            <span style={{ paddingLeft: "0px" }}>
              Last Updated: {pagesData("policy")?.date}
            </span>
            <br />
            <br />
            {pagesData("policy")?.desc}
            <br />
            <br />
            {pagesData("policy")?.policy?.map((item, i) => {
              console.log(item);
              return (
                <div key={i}>
                  <b>{item?.section}</b>
                  <br />
                  {item?.content}
                  <br />
                  <br />
                </div>
              );
            })}
          </>
        </div>
      </div>
    </div>
  );
};
export default Sd_private_policy;
