import React from "react";
import { withRouter } from "react-router";
import { SD_Logo_circle } from "../../Assets/imageList";
const LoadingContent = ({ history }) => {
  return (
    <div className="sd-coming-soon">
      <img src={SD_Logo_circle} alt="logo" height={"100px"} />
      <h4>No Data</h4>{" "}
      <p>
        Return to{" "}
        <span
          onClick={() => {
            history.push("/");
          }}
        >
          Home page
        </span>
      </p>
    </div>
  );
};
export default withRouter(LoadingContent);
