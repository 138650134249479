import React, { Suspense, lazy } from "react";
import { Route, Switch } from "react-router-dom";
import "./Utils/sd-styles/sd-app.scss";
import Sd_Home from "./pages/sd-home/sd-home";
import SD_Dashboard from "./pages/sd-dashboard";
import Sd_terms_and_conditions from "./pages/sd-misc/sd-terms-and-condotions";
import Sd_private_policy from "./pages/sd-misc/sd-private-policy";
import Sd_loading from "./Components/sd-common-components/sd-loading";
import Sd_about_general_information from "./pages/sd-about/sd-about-general-information";
//About---------------------
const Sd_about = lazy(() => import("./pages/sd-about/sd-about"));
const Sd_about_certifications = lazy(() =>
  import("./pages/sd-about/sd-about-certifications")
);

const Sd_faqs_all = lazy(() =>
  import("./Components/sd-common-components/sd-faqs-all")
);

const Sd_support = lazy(() => import("./pages/sd-support/sd-support"));

//Sevas-and-darshanam
const Sd_sevas_and_darshanam_paroksha_seva = lazy(() =>
  import("./pages/sd-sevas-and-darshanam/sd-sevas-and-darshanam-paroksha-seva")
);
const Sd_sevas_and_darshanam_pratyakshaseva = lazy(() =>
  import("./pages/sd-sevas-and-darshanam/sd-sevas-and-darshanam-pratyashaseva")
);
const Sd_sevas_and_darshanam = lazy(() =>
  import("./pages/sd-sevas-and-darshanam/sd-sevas-and-darshasnam")
);
//darshanam
const Sd_sevas_and_darshanam_darshanam = lazy(() =>
  import(
    "./pages/sd-sevas-and-darshanam/sd-darshanam/sd-sevas-and-darshanam-darshanam"
  )
);

//pratyaksha seva

const Sd_gallery_full_view = lazy(() =>
  import("./Components/sd-common-components/sd-gallery-full-view")
);

// paroksha seva

// const Sd_sevas_and_darshanam_paroksha_seva_sri_ganapathi_pooja = lazy(() =>
//   import(
//     "./pages/sd-sevas-and-darshanam/sd-paroksha-seva/sd-sevas-and-darshanam-paroksha-seva-sri-ganapathi-pooja"
//   )
// );

const Sd_temple_dairy = lazy(() =>
  import("./pages/sd-media-room/sd-media-room-temple-dairy")
);
//donations

const Sd_donations = lazy(() => import("./pages/sd-donation/sd-donations"));
//Online Booking

const Sd_online_booking = lazy(() =>
  import("./pages/sd-online-booking/sd-online-booking")
);

const Sd_online_booking_anna_prasadam_tickets = lazy(() =>
  import("./pages/sd-online-booking/sd-online-booking-anna-prasadam-tickets")
);

const Sd_online_booking_kalayana_mandapam_booking = lazy(() =>
  import(
    "./pages/sd-online-booking/sd-online-booking-kalayana-mandapam-booking"
  )
);
const Sd_online_booking_kalyana_katta_tickets = lazy(() =>
  import("./pages/sd-online-booking/sd-online-booking-kalyana-katta-tickets")
);
const Sd_online_booking_tour_packages = lazy(() =>
  import("./pages/sd-online-booking/sd-online-booking-tour-packages")
);
const Sd_online_booking_transportation = lazy(() =>
  import("./pages/sd-online-booking/sd-online-booking-transportation")
);
//Media Room
const Sd_comming_soon = lazy(() => import("./pages/sd-about/sd-coming-soon"));
const Sd_media_room_press_hoc = lazy(() =>
  import("./pages/sd-media-room/sd-media-room-press/sd-media-room-press-hoc")
);

const Sd_media_room_tenders = lazy(() =>
  import("./pages/sd-media-room/sd-media-room-tenders")
);
const Sd_media_room = lazy(() => import("./pages/sd-media-room/sd-media-room"));
const Sd_media_room_events = lazy(() =>
  import("./pages/sd-media-room/sd-media-room-events")
);
const Sd_media_room_media_kit = lazy(() =>
  import("./pages/sd-media-room/sd-media-room-media-kit")
);
const Sd_media_room_media_queries = lazy(() =>
  import("./pages/sd-media-room/sd-media-room-media-queries")
);
const Sd_media_room_newsletter = lazy(() =>
  import("./pages/sd-media-room/sd-media-room-newsletter")
);
const Sd_media_room_press_coverage = lazy(() =>
  import("./pages/sd-media-room/sd-media-room-press-coverage")
);
const Sd_media_room_press_releases = lazy(() =>
  import("./pages/sd-media-room/sd-media-room-press-releases")
);
const Sd_media_room_publications = lazy(() =>
  import("./pages/sd-media-room/sd-media-room-publications")
);
const Sd_media_room_rti_act = lazy(() =>
  import("./pages/sd-media-room/sd-media-room-rti-act")
);
const Sd_media_room_video_gallery = lazy(() =>
  import("./pages/sd-media-room/sd-media-room-video-gallery")
);
//volunteer-get involved
const Sd_get_involoved_volunteer_opurtunity = lazy(() =>
  import("./pages/sd-get-involved/sd-get-invloved-volunteer-oppurtunity")
);
//shop
const Sd_shop = lazy(() => import("./pages/sd-shop/sd-shop"));
//cart
const Sd_search = lazy(() => import("./pages/sd-search/sd-search"));
const Sd_cart = lazy(() => import("./pages/sd-cart/sd-cart"));
//support
const Sd_support_connectivity = lazy(() =>
  import("./pages/sd-support/sd-support-conectivity")
);
const Sd_support_contactUs = lazy(() =>
  import("./pages/sd-support/sd-support-contacUs")
);
const Sd_support_facilities_to_pilgrims = lazy(() =>
  import("./pages/sd-support/sd-support-facilities-to-pilgrims")
);
const Sd_support_live_chat = lazy(() =>
  import("./pages/sd-support/sd-support-live-chat")
);
const Sd_support_faqs = lazy(() =>
  import("./pages/sd-support/sd-support-faqs")
);
const SD_Login = lazy(() => import("./pages/sd-login-register-pages/sd-login"));
const SD_Signup = lazy(() =>
  import("./pages/sd-login-register-pages/sd-singup")
);

const SD_Assistance = lazy(() => import("./pages/sd-assistance"));
const SD_Reset = lazy(() => import("./pages/sd-login-register-pages/sd-reset"));
const SD_Verify_OTP = lazy(() =>
  import("./pages/sd-login-register-pages/sd-verify-otp")
);

const SD_Signup_Step_Two = lazy(() =>
  import("./pages/sd-login-register-pages/sd-register-step-two")
);
const SD_Dont_Remember = lazy(() =>
  import("./pages/sd-login-register-pages/sd-dont-remember")
);
// import {SD_Context} = lazy(() => import( "./context/sd-context";

const SD_Guest_SignUp = lazy(() =>
  import("./pages/sd-login-register-pages/sd-guest-login")
);
// import SD_Dashboard = lazy(() => import( "./pages/sd-dashboard";
const Sd_404 = lazy(() => import("./pages/sd-error-pages/sd-404"));

const Sd_about_the_temple_history = lazy(() =>
  import("./pages/sd-about/sd-about-the-temple-hitsory")
);
const Sd_about_the_temple = lazy(() =>
  import("./pages/sd-about/sd-about-the-temple")
);
// const Sd_trademark = lazy(() => import("./pages/sd-misc/sd-trademarks"));
const Sd_faqs_Main = lazy(() => import("./pages/sd-help/sd-faqs"));
const Sd_Print_a_ticket = lazy(() =>
  import("./pages/sd-misc/sd-print-a-ticket")
);
const Sd_Routes = () => {
  return (
    <Suspense fallback={<Sd_loading />}>
      <Switch>
        <Route exact path="/:lang/home" component={Sd_Home} />
        <Route exact path="/:lang/devotee-app/*" component={SD_Dashboard} />
        <Route exact path="/:lang/about" component={Sd_about} />
        <Route exact path="/:lang/devotee/signin" component={SD_Login} />
        <Route exact path="/:lang/devotee/signup" component={SD_Signup} />
        <Route
          exact
          path="/:lang/media-room/press/:type"
          component={Sd_media_room_press_hoc}
        />
        <Route
          exact
          path="/:lang/devotee/print-a-ticket"
          component={Sd_Print_a_ticket}
        />
        <Route
          exact
          path="/:lang/about/the-temple-history/:type"
          component={Sd_about_the_temple_history}
        />
        <Route exact path="/:lang/coming-soon" component={Sd_comming_soon} />
        <Route
          exact
          path="/:lang/about/the-temple/:type"
          component={Sd_about_the_temple}
        />
        <Route
          exact
          path="/:lang/about/general-information/:type"
          component={Sd_about_general_information}
        />
        <Route
          exact
          path="/:lang/terms-and-conditions"
          component={Sd_terms_and_conditions}
        />
        <Route
          exact
          path="/:lang/privacy-policy"
          component={Sd_private_policy}
        />
        <Route exact path="/:lang/faqs/:type" component={Sd_faqs_Main} />
        <Route exact path="/:lang/support/faqs" component={Sd_faqs_all} />
        <Route
          exact
          path="/:lang/devotee/signup/verify"
          component={SD_Signup_Step_Two}
        />
        <Route
          exact
          path="/:lang/media-room/photo-gallery"
          component={Sd_gallery_full_view}
        />
        <Route
          exact
          path="/:lang/devotee/assistance"
          component={SD_Assistance}
        />
        <Route
          exact
          path="/:lang/devotee/reset/verify/:num"
          component={SD_Verify_OTP}
        />
        {/* <Route exact path="/:lang/trademarks" component={Sd_trademark} /> */}
        <Route exact path="/:lang/devotee/reset" component={SD_Reset} />
        <Route
          exact
          path="/:lang/devotee/reset/help"
          component={SD_Dont_Remember}
        />
        <Route exact path="/:lang/guestLogin" component={SD_Guest_SignUp} />
        {/* <Route
          exact
          path="/:lang/about/srisaila-devasthanam"
          component={Sd_about_srisaila_devasthanam}
        /> */}
        <Route
          exact
          path="/:lang/about/certifications"
          component={Sd_about_certifications}
        />
        {/* <Route exact path="/:lang/about/trusts" component={Sd_about_trusts} /> */}
        <Route
          exact
          path="/:lang/sevas-and-darshanam"
          component={Sd_sevas_and_darshanam}
        />
        <Route
          exact
          path="/:lang/sevas-and-darshanam/paroksha-seva"
          component={Sd_sevas_and_darshanam_paroksha_seva}
        />
        <Route
          exact
          path="/:lang/sevas-and-darshanam/pratyaksha-seva"
          component={Sd_sevas_and_darshanam_pratyakshaseva}
        />
        {/* <Route
          exact
          path="/:lang/sevas-and-darshanam/saswata-seva"
          component={Sd_sevas_and_darshanam_saswata_seva}
        /> */}
        <Route
          exact
          path="/:lang/sevas-and-darshanam/darshanam"
          component={Sd_sevas_and_darshanam_darshanam}
        />
        {/* <Route
          exact
          path="/:lang/sevas-and-darshanam/paroksha-seva/sri-ganapathi-pooja"
          component={Sd_sevas_and_darshanam_paroksha_seva_sri_ganapathi_pooja}
        /> */}
        <Route exact path="/:lang/donations/" component={Sd_donations} />
        <Route
          exact
          path="/:lang/online-booking"
          component={Sd_online_booking}
        />
        <Route
          exact
          path="/:lang/online-booking/kalyana-katta-tickets"
          component={Sd_online_booking_kalyana_katta_tickets}
        />

        <Route
          exact
          path="/:lang/online-booking/anna-prasadam-tickets"
          component={Sd_online_booking_anna_prasadam_tickets}
        />
        <Route
          exact
          path="/:lang/online-booking/transportation"
          component={Sd_online_booking_transportation}
        />
        <Route
          exact
          path="/:lang/online-booking/kalyana-mandapam-booking"
          component={Sd_online_booking_kalayana_mandapam_booking}
        />
        <Route
          exact
          path="/:lang/online-booking/tour-packages"
          component={Sd_online_booking_tour_packages}
        />
        <Route exact path="/:lang/media-room" component={Sd_media_room} />
        <Route
          exact
          path="/:lang/media-room/media-kit"
          component={Sd_media_room_media_kit}
        />
        <Route
          exact
          path="/:lang/media-room/video-gallery"
          component={Sd_media_room_video_gallery}
        />
        <Route
          exact
          path="/:lang/media-room/whats-new/events"
          component={Sd_media_room_events}
        />
        <Route
          exact
          path="/:lang/media-room/whats-new/publications"
          component={Sd_media_room_publications}
        />
        <Route
          exact
          path="/:lang/media-room/whats-new/temple-diary"
          component={Sd_temple_dairy}
        />
        <Route
          exact
          path="/:lang/media-room/whats-new/newsletter"
          component={Sd_media_room_newsletter}
        />
        <Route
          exact
          path="/:lang/media-room/tenders"
          component={Sd_media_room_tenders}
        />
        <Route
          exact
          path="/:lang/media-room/rti-act"
          component={Sd_media_room_rti_act}
        />
        <Route
          exact
          path="/:lang/media-room/press/press-releases"
          component={Sd_media_room_press_releases}
        />

        <Route
          exact
          path="/:lang/media-room/press/press-coverage"
          component={Sd_media_room_press_coverage}
        />
        <Route
          exacts
          path="/:lang/media-room/press/media-queries"
          component={Sd_media_room_media_queries}
        />
        <Route
          exact
          path="/:lang/get-involved/volunteer-oppurtunity"
          component={Sd_get_involoved_volunteer_opurtunity}
        />
        <Route exact path="/:lang/shop" component={Sd_shop} />
        <Route exact path="/:lang/cart" component={Sd_cart} />
        <Route exact path="/:lang/search" component={Sd_search} />
        <Route
          exact
          path="/:lang/support/contact-us"
          component={Sd_support_contactUs}
        />
        <Route
          exact
          path="/:lang/support/support-faqs"
          component={Sd_support_faqs}
        />
        <Route
          exact
          path="/:lang/support/live-chat"
          component={Sd_support_live_chat}
        />
        <Route
          exact
          path="/:lang/support/facilities-to-pilgrims"
          component={Sd_support_facilities_to_pilgrims}
        />
        <Route
          exact
          path="/:lang/support/connectivity"
          component={Sd_support_connectivity}
        />
        <Route exact path="/:lang/support" component={Sd_support} />
        <Route path="*" component={Sd_404} />
      </Switch>
    </Suspense>
  );
};
export default Sd_Routes;
