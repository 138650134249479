import React, { useState, useEffect, useRef } from "react";
import {
  SD_cart_icon,
  SD_Print_a_ticket_icon,
  SD_search_icon,
  SD_Dropdown_Arrow_icon,
  SD_Logo_circle,
  SD_Sign_In_icon,
  sd_minus_icon,
  sd_plus_icon,
  sd_default_profile_pic,
  sd_devotee_dashboard,
  sd_logout_icon,
  sd_booking_history_icon,
  sd_online_booking_icon,
  sd_support,
} from "../../Assets/imageList";
import "../../Utils/sd-styles/sd-navbar.scss";
import moment from "moment";
import { useLocation, withRouter } from "react-router-dom";
import { SD_Context } from "../../context/sd-context";
import Sd_mobile_left_sider from "./sd-mobile-left-sider";
import NavBarData from "../../Utils/data/navbar.data";
import pagesData from "../../Utils/data/pages.data";
// import {toast} from "react-toastify";

const New_Nav = React.memo(({ history }) => {
  const {
    setActiveTopHeader,
    activeTopHeader,
    userData,
    language,
    setlanguage,
    setUserData,
    setCurrentDashboardLower,
    currentDashbaordLower,
    logout,
    languageList,
  } = React.useContext(SD_Context);

  //-----------------------------------states-------------------------------------------//

  const [sider, setSider] = useState(false);
  const [rightsider, setrightSider] = useState(false);

  const [visibleBut, setVisibleBut] = useState(false);

  const [donationDrop, setDonationDrop] = useState("none");

  const { pathname } = useLocation();
  const [dropRightone, setDropRightOne] = useState(false);
  const [dropRightTwo, setDropRightTwo] = useState(false);

  const refContainer = useRef();
  const [time, setTime] = React.useState(
    moment().utcOffset("+5:30").format(`dddd, DD MMMM YYYY - hh:mm A `)
  );
  const title = pagesData("home")?.title?.split(" ");

  //-----------------------------------states-------------------------------------------//

  //-----------------------------------useeffects---------------------------------------//

  useEffect(() => {
    const id = setInterval(timer, 60000);
    return () => clearInterval(id);
  }, [time]);

  useEffect(() => {
    if (document.getElementById("myDIV").scrollTop > 1000) {
      setVisibleBut(true);
    } else {
      setVisibleBut(false);
    }
  });

  useEffect(() => {
    if (history?.location?.pathname?.split?.("/")?.[2] === "about") {
      setCurrentDashboardLower("about");
    } else if (history?.location?.pathname?.split?.("/")?.[2] === "home") {
      setCurrentDashboardLower("home");
    } else if (
      history?.location?.pathname?.split?.("/")?.[2] === "sevas-and-darshanam"
    ) {
      setCurrentDashboardLower("sevas-and-darshanam");
    } else if (history?.location?.pathname?.split?.("/")?.[2] === "donations") {
      setCurrentDashboardLower("Donations");
    } else if (
      history?.location?.pathname?.split?.("/")?.[2] === "online-booking"
    ) {
      setCurrentDashboardLower("online-booking");
    } else if (history?.location?.pathname?.split?.("/")?.[2] === "support") {
      setCurrentDashboardLower("Support");
    } else {
      setCurrentDashboardLower("");
    }
  }, [history?.location?.pathname]);

  useEffect(() => {
    if (!pathname?.includes("about/general-information")) {
      refContainer.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [pathname]);

  //-----------------------------------useeffects-------------------------------------------//

  const timer = () => {
    let frist = moment()
      .utcOffset("+5:30")
      .format(`dddd, DD MMMM YYYY - hh:mm A `);
    //
    setTime(frist + "IST");
  };

  return (
    <div className="sd-navbar-custom" ref={refContainer}>
      <div className="sd-top-nav">
        <div className="sd-top-nav-left">
          {time}
          <div>
            {pagesData("navbar")?.socialLinks?.map((item, i) => {
              return (
                <img
                  key={i}
                  onClick={() => {
                    window.open(item?.link, "_blank");
                  }}
                  src={item?.icon}
                  alt="icon"
                />
              );
            })}
          </div>
        </div>

        <div className="sd-top-nav-right">
          {pagesData("navbar")?.tv && (
            <div
              className={"sd-inner-div sd-w-22 sd-selected"}
              onClick={() => {
                window.open(pagesData("navbar")?.tv?.link, "_blank");
              }}
            >
              {pagesData("navbar")?.tv?.label}
            </div>
          )}

          {/*Language Seletor*/}

          <div className="sd-dropdown-language">
            <button className="sd-dropbtn-language" type="button">
              {language.desc} <img src={SD_Dropdown_Arrow_icon} alt="icon" />
            </button>
            <div
              className="sd-dropdown-content-language"
              aria-labelledby="dropdownMenuButton1"
            >
              {languageList?.map((item, i) => {
                return (
                  <a
                    key={i}
                    className="dropdown-item"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setlanguage(item);
                      history?.push(
                        `${history?.location?.pathname?.replace(
                          history?.location?.pathname.split?.("/")?.[1],
                          item?.code
                        )}${history?.location?.search}`
                      );
                    }}
                  >
                    {item?.desc}
                  </a>
                );
              })}
            </div>
          </div>

          <div
            className={
              activeTopHeader !== "print-a-ticket"
                ? "sd-inner-div sd-w-24"
                : "sd-inner-div sd-w-24 sd-selected"
            }
            onClick={() => {
              if (localStorage.getItem("accessToken")) {
                history?.push(`/${language?.code}/devotee-app/booking-history`);
              } else {
                history?.push(
                  `/${language?.code}/devotee/signin?to=devotee-app/booking-history`
                );
              }
              setActiveTopHeader("print-a-ticket");
            }}
          >
            <img src={SD_Print_a_ticket_icon} alt="icon" />
            BOOKING HISTORY
          </div>

          <div
            className={
              activeTopHeader !== "sign-in"
                ? "sd-inner-div sd-w-25"
                : "sd-inner-div sd-selected sd-w-25"
            }
            onClick={() => {
              setActiveTopHeader("sign-in");
            }}
            style={{ borderLeft: "none" }}
          >
            {localStorage.getItem("accessToken") ? (
              userData?.displayName ? (
                <div className="sd-dropdown-signin ">
                  <button className="sd-dropbtn-signin" type="button">
                    <img
                      className="spcl"
                      src={
                        userData?.profilePic !== null
                          ? userData?.profilePic
                          : SD_Sign_In_icon
                      }
                      alt={SD_Sign_In_icon}
                    />{" "}
                    {userData?.displayName !== "" ||
                    userData?.displayName !== null
                      ? userData?.displayName
                      : "User"}
                  </button>
                  <div
                    className="sd-dropdown-content-signin"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <a
                      onClick={() => {
                        history.push(
                          `/${language?.code}/devotee-app/devotee-dashboard`
                        );
                      }}
                    >
                      Dashboard
                    </a>
                    {/* {userData?.role === "admin" && (
                      <a
                        onClick={() => {
                          history?.push(
                            `/${language?.code}/devotee-app/userview`
                          );
                        }}
                      >
                        User view
                      </a>
                    )} */}
                    <a
                      onClick={() => {
                        logout();
                        history.push(`/${language?.code}/home`);
                      }}
                    >
                      Logout
                    </a>
                  </div>
                </div>
              ) : (
                <div
                  style={{ color: "white", fontWeight: "600" }}
                  onClick={() => {
                    history?.push(
                      `/${language?.code}/devotee/signin?to=devotee-app/devotee-dashboard`
                    );
                  }}
                >
                  <img src={SD_Sign_In_icon} alt="icon" /> SIGNIN /SIGNUP
                </div>
              )
            ) : (
              <div
                style={{ color: "white", fontWeight: "600" }}
                onClick={() => {
                  history?.push(
                    `/${language?.code}/devotee/signin?to=devotee-app/devotee-dashboard`
                  );
                }}
              >
                <img src={SD_Sign_In_icon} alt="icon" /> SIGN IN / SIGN UP
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="sd-bottom-nav">
        <nav className="sd-inner-navbar">
          <div
            className="sd-hamburger-icon"
            onClick={() => {
              if (rightsider) {
                setrightSider(false);
              }
              setSider(true);
            }}
          >
            <div></div>
            <div></div>
            <div></div>
          </div>

          <div className={sider ? "sd-left-sider block" : "sd-left-sider none"}>
            <Sd_mobile_left_sider
              selectedlanguage={language?.code}
              setSider={setSider}
            />
          </div>

          <div className="sd-inner-left">
            <img
              className="logo"
              src={SD_Logo_circle}
              alt="icon"
              onClick={() => {
                history?.push(`/${language?.code}/home`);
              }}
            />
            <a
              onClick={() => {
                history?.push(`/${language?.code}/home`);
              }}
            >
              {title[0]} {title[1]}
              <br />
              {title[2]} {title[3]} <br />
              {title?.splice(4, title?.length)?.join(" ")}
            </a>
          </div>
          <div className="sd-inner-right">
            <ul className="sd-top-list">
              <li className="item">
                <a
                  className="nav-link"
                  onClick={() => {
                    history?.push(
                      `/${language?.code}/get-involved/volunteer-oppurtunity`
                    );
                  }}
                >
                  Volunteer
                </a>
              </li>
              <li className="item">
                <a
                  className="nav-link"
                  onClick={() => {
                    history?.push(`/${language?.code}/shop`);
                  }}
                >
                  Shop
                </a>
              </li>
              {window.innerWidth > 600 ? (
                <>
                  <li
                    className="item"
                    onClick={() => {
                      history?.push(`/${language?.code}/cart`);
                    }}
                  >
                    <img
                      src={SD_cart_icon}
                      alt="icon"
                      style={{ cursor: "pointer", filter: "invert(1)" }}
                    />
                  </li>
                  <li
                    className="item sd-spcl"
                    onClick={() => {
                      history?.push(`/${language?.code}/search`);
                    }}
                  >
                    <img
                      src={SD_search_icon}
                      alt="icon"
                      style={{ cursor: "pointer", filter: "invert(1)" }}
                    />
                  </li>
                </>
              ) : (
                ""
              )}
            </ul>
            <ul className="sd-bottom-list">
              {NavBarData("menu")?.map((item, i) => {
                return (
                  <li
                    key={i}
                    className={
                      currentDashbaordLower === item?.key
                        ? "dropdown sd-first-button border-r active"
                        : "dropdown sd-first-button border-r"
                    }
                    onMouseEnter={() => {
                      setDonationDrop(item?.key);
                    }}
                    onMouseLeave={() => setDonationDrop("none")}
                  >
                    <button
                      className={
                        currentDashbaordLower === item?.key
                          ? "btn active"
                          : "btn"
                      }
                      type="button"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      onClick={() => {
                        setCurrentDashboardLower(item?.key);
                        history?.push(`/${language?.code}/${item?.key}`);
                      }}
                    >
                      {item?.title}
                    </button>
                    {donationDrop === item?.key && (
                      <div
                        className={`dropdown-menu ${
                          item?.key === "support" ? "sd-left" : ""
                        }`}
                        aria-labelledby="dropdownMenuButton"
                      >
                        {item?.children?.map((subItem, j) => {
                          return (
                            <a
                              key={j}
                              className="dropdown-item"
                              onClick={() => {
                                if (item?.key === "online-booking") {
                                  if (subItem?.path === "online-booking") {
                                    history?.push(
                                      `/${language?.code}/${subItem?.path}/`
                                    );
                                  } else if (
                                    localStorage.getItem("accessToken")
                                  ) {
                                    history?.push(
                                      `/${language?.code}/devotee-app/online-booking/${subItem?.path}`
                                    );
                                  } else {
                                    history?.push(
                                      `/${language?.code}/devotee/signin?to=devotee-app/online-booking/${subItem?.path}`
                                    );
                                  }
                                } else if (subItem?.children === undefined) {
                                  history?.push(
                                    `/${language?.code}/${subItem?.path}/`
                                  );
                                }
                                setDonationDrop("none");
                              }}
                            >
                              <a style={{ color: "black", paddingLeft: "0px" }}>
                                {subItem?.name}
                              </a>
                              {subItem?.children !== undefined && (
                                <div
                                  className={`side-hover ${
                                    item?.key === "media-room"
                                      ? "sd-left-hover"
                                      : ""
                                  }`}
                                >
                                  {subItem?.children?.map((data, k) => {
                                    return (
                                      <div
                                        key={k}
                                        className="dropdown-item"
                                        onClick={() => {
                                          setDonationDrop("none");

                                          history?.push(
                                            `/${language?.code}/${data?.path}`
                                          );
                                        }}
                                      >
                                        {data?.name}
                                      </div>
                                    );
                                  })}
                                </div>
                              )}
                            </a>
                          );
                        })}
                      </div>
                    )}
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="sd-user-slider">
            <img
              src={SD_Sign_In_icon}
              alt="icon"
              onClick={() => {
                if (userData) {
                  if (userData.displayName) {
                    if (sider) {
                      setSider(false);
                    }
                    setrightSider(true);
                  } else {
                    history.push(
                      `/${language?.code}/devotee/signin?to=devotee-app/devotee-dashboard`
                    );
                  }
                } else {
                  history.push(
                    `/${language?.code}/devotee/signin?to=devotee-app/devotee-dashboard`
                  );
                }
              }}
            />
          </div>
          <div
            className={
              rightsider ? "sd-right-sider block" : "sd-right-sider none"
            }
          >
            <div className="inner">
              <div className="inner-nav-top">
                <div className="left">
                  <img
                    className="logo"
                    src={SD_Logo_circle}
                    alt="icon"
                    onClick={() => {
                      if (history) history.push(`/${language?.code}/home`);
                    }}
                  />
                </div>
                <div className="right">
                  <img src={SD_cart_icon} alt={SD_cart_icon} />
                  <button
                    type="button"
                    onClick={() => {
                      setrightSider(false);
                    }}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              </div>
              <div className="inner-bottom">
                <div className="inner-bottom-t">
                  <div className="sd-dashboard-profile ml-0">
                    <div className={"profile white-bg"}>
                      <p style={{ marginBottom: "0px" }}>
                        {userData?.displayName === "" ||
                        userData?.displayName === null ? (
                          "User"
                        ) : (
                          <>
                            <span>
                              {" "}
                              {userData.displayName?.split(" ")[1] ? (
                                userData.displayName
                                  ?.split(" ")[0]
                                  ?.charAt(0)
                                  .toUpperCase()
                              ) : (
                                <>
                                  <span>
                                    {userData.displayName
                                      ?.split(" ")[0]
                                      ?.charAt(0)
                                      .toUpperCase()}
                                    {userData.displayName
                                      ?.split(" ")[0]
                                      ?.charAt(1)}
                                  </span>
                                </>
                              )}
                            </span>
                            <span style={{ marginLeft: "-2px" }}>
                              {userData.displayName?.split(" ")[1]?.charAt(0)}
                            </span>
                          </>
                        )}
                      </p>
                    </div>

                    {/* <img
                        className="sd-dashboard-icons"
                        src={sd_profile_icon_dashboard}
                      /> */}
                    <h2
                      className="sd-heading-18 float-right-ellipse c-voilet "
                      style={{ padding: "5px !important" }}
                    >
                      {userData?.displayName}
                    </h2>
                  </div>
                </div>
                <div className="inner-bottom-b">
                  <div
                    onClick={() => {
                      setrightSider(false);

                      history?.push(
                        `/${language?.code}/devotee-app/devotee-dashboard`
                      );
                    }}
                  >
                    <img src={sd_devotee_dashboard} alt="dashboard" />
                    Devotee Dashboard
                  </div>

                  <div
                    className="between"
                    onClick={() => {
                      if (dropRightone) {
                        setDropRightOne(false);
                      }
                      setDropRightTwo(!dropRightTwo);
                    }}
                  >
                    <div className="sd-padding-none">
                      <img src={sd_online_booking_icon} alt="online" /> Online
                      Booking
                    </div>
                    {dropRightTwo ? (
                      <img
                        src={sd_minus_icon}
                        alt="-"
                        onClick={() => {
                          setDropRightTwo(false);
                        }}
                      ></img>
                    ) : (
                      <img
                        src={sd_plus_icon}
                        onClick={() => {
                          setDropRightTwo(true);
                        }}
                      />
                    )}
                  </div>

                  <div
                    className={
                      dropRightTwo
                        ? "drop-right-one block"
                        : "drop-right-one none"
                    }
                  >
                    <div
                      className="dropdown-item"
                      onClick={() => {
                        setrightSider(false);

                        if (localStorage.getItem("accessToken")) {
                          history?.push(
                            `/${language?.code}/devotee-app/online-booking/darshanam`
                          );
                        } else {
                          history?.push(
                            `/${language?.code}/devotee/signin?to=devotee-app/online-booking/darshanam`
                          );
                        }
                      }}
                    >
                      {pagesData("commonTitles", "Darshanam")}
                    </div>
                    <div
                      className="dropdown-item"
                      onClick={() => {
                        setrightSider(false);

                        if (localStorage.getItem("accessToken")) {
                          history?.push(
                            `/${language?.code}/devotee-app/online-booking/pratyaksha-seva`
                          );
                        } else {
                          history?.push(
                            `/${language?.code}/devotee/signin?to=devotee-app/online-booking/pratyaksha-seva`
                          );
                        }
                      }}
                    >
                      {pagesData("commonTitles", "Pratyaksha Seva")}
                    </div>
                    <div
                      className="dropdown-item"
                      onClick={() => {
                        setrightSider(false);
                        if (localStorage.getItem("accessToken")) {
                          history?.push(
                            `/${language?.code}/devotee-app/online-booking/paroksha-seva`
                          );
                        } else {
                          history?.push(
                            `/${language?.code}/devotee/signin?to=devotee-app/online-booking/paroksha-seva`
                          );
                        }
                      }}
                    >
                      {pagesData("commonTitles", "Paroksha Seva")}
                    </div>
                    <div
                      className="dropdown-item"
                      onClick={() => {
                        setrightSider(false);

                        if (localStorage.getItem("accessToken")) {
                          history?.push(
                            `/${language?.code}/devotee-app/online-booking/donations`
                          );
                        } else {
                          history?.push(
                            `/${language?.code}/devotee/signin?to=devotee-app/online-booking/donations`
                          );
                        }
                      }}
                    >
                      {pagesData("commonTitles", "Donations")}
                    </div>
                    <div
                      className="dropdown-item"
                      onClick={() => {
                        setrightSider(false);

                        if (localStorage.getItem("accessToken")) {
                          history?.push(
                            `/${language?.code}/devotee-app/online-booking/accommodations`
                          );
                        } else {
                          history?.push(
                            `/${language?.code}/devotee/signin?to=devotee-app/online-booking/accommodations`
                          );
                        }
                      }}
                    >
                      {pagesData("commonTitles", "Accommodation")}
                    </div>{" "}
                    {/* <div
                      className="dropdown-item"
                      onClick={() => {
                        setrightSider(false);
                        history?.push(
                          `/${language?.code}/devotee-app/online-booking/publications`
                        );
                      }}
                    >
                      Publications
                    </div> */}
                  </div>

                  <div
                    onClick={() => {
                      setrightSider(false);

                      history?.push(
                        `/${language?.code}/devotee-app/booking-history`
                      );
                    }}
                  >
                    <img src={sd_booking_history_icon} alt="booking" /> Booking
                    History
                  </div>

                  <div
                    className="between"
                    onClick={() => {
                      if (dropRightTwo) {
                        setDropRightTwo(false);
                      }
                      setDropRightOne(!dropRightone);
                    }}
                  >
                    <div className="sd-padding-none">
                      <img src={sd_default_profile_pic}></img> My Account
                    </div>
                    {dropRightone ? (
                      <img src={sd_minus_icon} alt="-" />
                    ) : (
                      <img src={sd_plus_icon} alt="+" />
                    )}
                  </div>

                  <div
                    className={
                      dropRightone ? "drop-one block" : "drop-one none"
                    }
                  >
                    <div
                      onClick={() => {
                        setrightSider(false);

                        history?.push(
                          `/${language?.code}/devotee-app/my-account/profile`
                        );
                      }}
                    >
                      My Profile
                    </div>
                    <div
                      onClick={() => {
                        setrightSider(false);

                        history?.push(
                          `/${language?.code}/devotee-app/my-account/change-password`
                        );
                      }}
                    >
                      Change Password
                    </div>
                    <div
                      onClick={() => {
                        setrightSider(false);

                        history?.push(
                          `/${language?.code}/devotee-app/my-account/devotee-masterlist`
                        );
                      }}
                    >
                      Devotee Master List
                    </div>
                    <div
                      onClick={() => {
                        setrightSider(false);

                        history?.push(
                          `/${language?.code}/devotee-app/my-account/favorites`
                        );
                      }}
                    >
                      My Favorites
                    </div>
                  </div>

                  <div
                    style={{ paddingBottom: "50px" }}
                    onClick={() => {
                      setrightSider(false);

                      history?.push(`/${language?.code}/support`);
                    }}
                  >
                    <img src={sd_support} alt="support" />
                    Support
                  </div>
                </div>
              </div>
              <div
                className="inner-footer"
                onClick={() => {
                  logout();
                  setUserData({});

                  setDropRightOne(false);
                  setDropRightTwo(false);
                  setrightSider(false);
                  history.push("/");
                }}
              >
                <img src={sd_logout_icon} alt="Logout" height="15px" />
                <span style={{ marginLeft: "5px", color: "#991403" }}>
                  Logout
                </span>
              </div>
            </div>
          </div>
        </nav>
      </div>
      <button
        className={visibleBut ? "active-r" : "hide-r"}
        onClick={() => {
          setVisibleBut(false);
          refContainer.current.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }}
      >
        <img src={SD_Dropdown_Arrow_icon} />
      </button>
    </div>
  );
});

New_Nav.displayName = "navbar";
export default withRouter(New_Nav);
