import Axios from "../Utils/sd-axios/Axios";

const ParokshaSevaService = {
  getAll: async () => {
    try {
      const resp = await Axios.get("/seva/all?sevaType=parokshaSeva");
      return resp.data;
    } catch (error) {
      if (error?.response?.status === 401) {
        throw new Error("Session Expired");
      } else if (
        error?.response?.status !== 500 &&
        error?.response?.data?.message
      ) {
        throw new Error(error?.response?.data?.message);
      } else {
        //
        throw new Error("something is wrong");
      }
    }
  },
  bookings: async (bookingData, id) => {
    try {
      const resp = await Axios.post(`online-cart/new-cart`, bookingData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Code": id,
        },
      });
      return resp.data;
    } catch (error) {
      if (error?.response?.status === 401) {
        throw new Error("Session Expired");
      } else if (
        error?.response?.status !== 500 &&
        error?.response?.data?.message
      ) {
        throw new Error(error?.response?.data?.message);
      } else {
        //
        throw new Error("something is wrong");
      }
    }
  },
};

export default ParokshaSevaService;
