import React, { useState, useEffect, useContext } from "react";
import {
  sd_faq_plus,
  sd_faq_minus,
  orange_arrow,
} from "../../Assets/imageList";
import "../../Utils/sd-styles/sd-faqs.scss";
import { withRouter } from "react-router";
import Sd_loading from "./sd-loading";
import pagesData from "../../Utils/data/pages.data";
import { SD_Context } from "../../context/sd-context";
import FaqsData from "../../Utils/data/faqs.data";

const Sd_faqs_semi_view = ({ from, history }) => {
  //-------------------------------------------------------------states-----------------------------------------------------------//
  const { language } = useContext(SD_Context);

  const [active, setActive] = useState("none");
  const [subData, setSubData] = useState([]);
  const [loading, setloading] = useState(true);
  //-------------------------------------------------------------states-----------------------------------------------------------//

  //-------------------------------------------------------------useefects-----------------------------------------------------------//
  useEffect(() => {
    setloading(true);
    setSubData(FaqsData(from));
    setloading(false);
  }, []);

  //-------------------------------------------------------------useeffects-----------------------------------------------------------//

  //-------------------------------------------------------------functions-----------------------------------------------------------//

  //-------------------------------------------------------------functions-----------------------------------------------------------//
  return (
    <>
      {loading ? (
        <Sd_loading />
      ) : (
        <>
          <div className="sd-faqs-conatainer">
            <div className="sd-border">
              {" "}
              <hr />
            </div>
            <div className="sd-faqs-top">
              <h1>
                <span>FAQ</span>s
              </h1>
              <img
                src={pagesData("home").divider}
                alt="divider"
                style={{ height: "20px" }}
              ></img>
            </div>
            <div className="sd-faqs-bottom">
              <div className="accordion" id="accordionExample">
                {subData?.slice(0, 4)?.map((item, i) => {
                  return (
                    <div
                      className={`card selective-card ${i === 0 && "sd-bt"}`}
                      key={i}
                      onClick={() => {
                        if (active === `none`) {
                          setActive(`heading${i}`);
                        } else if (active === `heading${i}`) {
                          setActive("none");
                        } else {
                          setActive(`heading${i}`);
                        }
                      }}
                    >
                      <div className="card-header heading" id={`heading${i}`}>
                        <div className="top-head">
                          <div className="top-head-top">
                            <h3>{i + 1}</h3>
                            <h2>{item?.question}</h2>
                          </div>
                          <h2 className="mb-0 custom-h2">
                            <button
                              className="btn btn-link cutombuttom"
                              type="button"
                            >
                              {active === `heading${i}` ? (
                                <img
                                  src={sd_faq_minus}
                                  alt=""
                                  onClick={() => {
                                    setActive("none");
                                  }}
                                />
                              ) : (
                                <img
                                  src={sd_faq_plus}
                                  onClick={() => {
                                    setActive(`heading${i}`);
                                  }}
                                />
                              )}
                            </button>
                          </h2>
                        </div>
                      </div>
                      <div id={`collapse${i}`}>
                        <div
                          className={
                            active === `heading${i}`
                              ? "sd-display card-body"
                              : "sd-none card-body"
                          }
                        >
                          <span style={{ fontWeight: "600" }}>Ans. </span>
                          {item.answer}
                        </div>
                      </div>
                    </div>
                  );
                })}

                <div className="toggle"></div>
              </div>
            </div>

            {!subData ? (
              ""
            ) : (
              <div className="sd-faqs-button">
                <button
                  className="sd-view-button"
                  onClick={() => {
                    history.push(`/${language?.code}/faqs/${from}`);
                  }}
                >
                  View All <img src={orange_arrow} alt=">" />
                </button>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};
export default withRouter(Sd_faqs_semi_view);
