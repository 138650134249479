import React from "react";
import pagesData from "./pages.data";

const FaqsData = (from) => {
  const paddingStyle = {
    paddingLeft: "40px",
    marginTop: "-23px",
  };

  const data = {
    paroksha_seva: [
      {
        question: "What is Paroksha Seva?",
        answer:
          "Paroksha Seva is method to perform seva from anywhere of the with a slogan “Worship from Home”",
      },
      {
        question: "What are the uses of Paroksha seva?",
        answer:
          "By Paroksha seva, devotees can be a part of seva from anywhere in the world",
      },
      {
        question:
          "What is the difference between Paroksha seva and Pratyaksha seva?",
        answer:
          "Paroksha Seva is for those who are unable to participate in Sevas directly. Those who can participate in person at the Temple will be mentioned as Sevadaris of Pratyaksha Seva.",
      },
      {
        question: "Who should attend Paroksha seva?",
        answer: "Any devotee can attend paroksha seva.",
      },
      {
        question: "Can unmarried persons attend Paroksha seva?",
        answer: "Yes",
      },
      {
        question: "What are the timings of Paroksha seva?",
        answer: "Based on the seva chosen",
      },
      {
        question:
          "Will Paroksha Sevas be performed at the same timings everyday?",
        answer:
          "Yes. All Paroksha Sevas will be performed during the designated times.",
      },
      {
        question:
          "Will you perform Paroksha seva on auspicious and festival days?",
        answer: "That varies and the information will be given on our website.",
      },
      {
        question: "If yes, any change of timings in performing Paroksha seva?",
        answer: "No.",
      },
      {
        question: "How to book Paroksha seva?",
        answer: `Visit our official website ${
          pagesData("home")?.link
        } to book Paroksha seva`,
      },
      {
        question: "Can we book Paroksha seva offline?",
        answer: "No",
      },
      {
        question:
          "What are the proofs to be submitted to book the Paroksha seva?",
        answer: "No proofs can be submitted for booking.",
      },
      {
        question: "How prior can we book Paroksha seva?",
        answer: "It is 15 days prior from the performance date.",
      },
      {
        question: "What is the cut off time to book Paroksha seva?",
        answer: "The seva can be booked 24 hours from the time of performance.",
      },
      {
        question:
          "Can we attend Paroksha seva by booking a ticket for Pratyaksha seva?",
        answer: "No",
      },
      {
        question:
          "Can we attend Pratyaksha seva by booking a ticket for Paroksha seva?",
        answer: "No",
      },
      {
        question:
          "Is there any difference in Seva Rituals between Paroksha seva and Pratyaksha seva?",
        answer: "No",
      },
      {
        question: "Will you perform two or more Sevas at the same time?",
        answer: "No",
      },
      {
        question: "Are there any offers available, if we book more Sevas?",
        answer: "No",
      },
      {
        question:
          "What are the rules and regulations to be followed during Paroksha seva?",
        answer:
          "Devotees are requested to take bath and watch the seva live with our youtube link provided.",
      },
      {
        question:
          "Will you read out Gotra-Names of the whole family with a single ticket?",
        answer:
          "No. Only names of 4 people of a family are recited during the seva.",
      },
      {
        question:
          "With a single ticket, how many Gothrams can be allowed to read?",
        answer: "Only 4 members of the family.",
      },
      {
        question:
          "Will you perform seva to each individual ticket holder separately?",
        answer: "No",
      },
      {
        question: "Will you provide Prasadam for Paroksha seva attendees?",
        answer: "Yes",
      },
      {
        question: "How will we know that you sent Prasadam for us?",
        answer:
          "Sevadari will get a text message with a tracking number for tracking the prasadam.",
      },
      {
        question:
          "What Prasadam will be sent by you for Paroksha seva attendees?",
        answer: "That varies with the Seva booked.",
      },
      {
        question: "How will you send Prasadam to our place?",
        answer: "With the Indian postal address provided during seva booking.",
      },
      {
        question: "How can we track our Prasadam?",
        answer:
          "With the tracking number provided to sevadari via text message.",
      },
      {
        question: "How many days will it take to receive Prasadam?",
        answer: "It depends on the distance from Temple.",
      },
      {
        question: "How can we watch Paroksha seva live?",
        answer: "With the live link provided to the sevadari",
      },
      {
        question: "Is there any daily limit for Paroksha seva booking?",
        answer: "No",
      },
      {
        question: "Can we cancel the Paroksha seva ticket?",
        answer: "No",
      },
      {
        question:
          "Can we transfer our ticket to another person or change the date of seva?",
        answer: "No",
      },
      {
        question: "Can NRIs book Paroksha seva?",
        answer: "Yes",
      },
      {
        question: "Can NRIs get Prasadam by booking Paroksha seva?",
        answer: "No",
      },
      {
        question: "How can I provide feedback and suggestions?",
        answer: `Mail to ${pagesData("home")?.email}`,
      },
    ],

    accommodations: [
      {
        question: "What is the process to book accommodation?",
        answer: `Visit the Devasthanam official website for Devasthanam accommodation ${
          pagesData("home")?.link
        }. Click on Accommodation-select Advanced date and book the accommodation.`,
      },
      {
        question: "Is registration required to book accommodation?",
        answer: "Yes, Registration is required for accommodation booking.",
      },
      {
        question:
          "What are the documents to be given while booking accommodation?",
        answer: "Aadhar Card",
      },
      {
        question:
          "Am I supposed to show the documents at the reception of choultry which I submitted while booking accommodation?",
        answer: "Yes",
      },
      {
        question: "What types of rooms are available in accommodation?",
        answer: "1. AC rooms, 2. Non AC rooms, 3. Suite rooms, 4. Dormitory",
      },
      {
        question: "What are the check-in and check-out timings?",
        answer: "10:00 a.m to next day 10:00 a.m",
      },
      {
        question: "Am I supposed to deposit while checking-in?",
        answer: "Yes",
      },
      {
        question:
          "Where should I have to collect my deposit, while checking-out?",
        answer: "At the Reception counter",
      },
      {
        question: "Is it secure to keep my belongings in the choultry room?",
        answer:
          "Yes, but the responsibility must be taken by the devotees only",
      },
      {
        question: "Can I change the room you allotted, if I don’t like it?",
        answer: "Yes",
      },
      {
        question: "How prior should I book accommodation online?",
        answer: "15 days",
      },
      {
        question: "Can I book accommodation offline?",
        answer: "Yes",
      },
      {
        question: "What are the documents to be shown to take accommodation?",
        answer:
          "Documents which are produced during booking online accommodation",
      },
      {
        question: "How many rooms can I book within a registration?",
        answer:
          "A single person is allowed to book single accommodation only. There is no restriction on the number of room bookings",
      },
      {
        question: "How many people are allowed to stay in a room?",
        answer: "Four persons only and single person is not allowed to stay",
      },
      {
        question: "Will choultry provide any extra beds, if needed?",
        answer: "No",
      },
      {
        question: "How long can I stay in a choultry room?",
        answer: "24 hours only",
      },
      {
        question: "Can I cancel my accommodation?",
        answer: "No",
      },
      {
        question: "For any queries and complaints, whom should we contact?",
        answer: `Devotees can contact via mail : ${
          pagesData("home")?.email
        } or mobile : ${pagesData("home")?.mobile}`,
      },
    ],

    darshanam: [
      {
        question: "Is there any dress code for Darshanam?",
        answer:
          "Yes. Pancha or Dhoti for Male and Saree or Half saree for female",
      },
      {
        question: "Where can I get the Darshanam tickets at Devasthanam?",
        answer: "At the Devasthanam Darshanam Counters",
      },
      {
        question: "What is the age limit for kids to have a ticket?",
        answer: "10 years",
      },
      {
        question: "Will I have to carry ID proof for Darshanam?",
        answer: "Yes",
      },
      {
        question: "What is the process of booking an online ticket?",
        answer: `Visit our website: ${pagesData("home")?.link}`,
      },
      {
        question:
          "What documents or ID proofs are required for booking an online ticket for Darshanam?",
        answer:
          "Any of the following proofs can be submitted : Aadhar card, Voter ID, PAN card, Driving License.",
      },
      {
        question: "If I miss my online ticket receipt, what should I do?",
        answer: "Reprint the ticket from our website",
      },
      {
        question: "After booking tickets online, can I cancel the ticket?",
        answer: "No",
      },
      {
        question:
          "In any case, after online booking, may I change the date of Darshanam?",
        answer: "No",
      },
      {
        question: "May I transfer my online ticket to another person?",
        answer: "No",
      },
      {
        question:
          "How much time will it take for Darshanam on weekdays and weekends?",
        answer: "Two hours for free Darshanam approximately.",
      },
      {
        question:
          "How much time it will take for Seeghra Darshanam in weekdays and weekends?",
        answer: "One hour approximately.",
      },
      {
        question:
          "How much time it will take for Athi Seegra Darshanam in weekdays and weekends?",
        answer: "30 Minutes approximately.",
      },
      {
        question:
          "Is there any specific difference between Seeghra and Athi Seeghra Darshanam other than timings?",
        answer: "No",
      },
      {
        question:
          "May I carry any objects (mobile, water bottle etc) while going to Darshanam?",
        answer: "No",
      },
      {
        question: "Where should I keep my electronics safe?",
        answer: "At cloak room or room at accommodation booked by Devotee",
      },
      {
        question: "Do I have to pay any amount to keep my electronics safe?",
        answer: "Yes at cloak room",
      },
      {
        question:
          "By buying a ticket, will Devasthanam provide any Prasadam for us?",
        answer: "No",
      },
      {
        question:
          "Is there any special queue for Handicapped or Postnatal women or pregnant women or aged people?",
        answer: "Yes",
      },
      {
        question:
          "Any specific rules and regulations to follow during Darshanam?",
        answer:
          'Follow the dress code, Chant "Om" followed by god or goddess name in the queue line.',
      },
      {
        question:
          "If any further queries and complaints, who should we contact?",
        answer: `Contact via mail : ${pagesData("home")?.email} or mobile : ${
          pagesData("home")?.mobile
        }`,
      },
    ],
    donations: [
      {
        question: "What is the limit of donation?",
        answer:
          "There is no limit for donations. The minimum donation is Rs. 1/-",
      },
      {
        question: "What type of payments are accepted?",
        answer:
          "All credit/debit cards, UPI, Net Banking, etc are available for payments in online",
      },
      {
        question: "What if a transaction failed?",
        answer: "Kindly contact your Bank for the details",
      },
      {
        question:
          "How many days will it take to refund the failed transaction amount?",
        answer: "Details will be available at your Bank",
      },
      {
        question:
          "Whom should we contact, if the failed transaction amount isn’t refunded?",
        answer: "Bank",
      },
      {
        question:
          "Can I donate through upi as I don’t have an internet banking facility?",
        answer: "Yes",
      },
      {
        question: "Can I donate any assets of mine?",
        answer: "Yes",
      },
      {
        question: "Can I donate digital gold?",
        answer: "No",
      },
      {
        question: "How will my donation be used?",
        answer: "In the favor of Temple development",
      },
      {
        question: "Can I dedicate my donation to someone else's name?",
        answer: "Yes",
      },
      {
        question: "Is my donation tax-deductible?",
        answer: "Yes",
      },
      {
        question: "Can I get an 80G certificate?",
        answer: "Yes",
      },
      {
        question: "Do my details get traded to any other agencies?",
        answer: "No",
      },
      {
        question: "Will I get any privileges by donations?",
        answer: "No",
      },
      {
        question: "How can I get a receipt for a donation?",
        answer: "Yes",
      },
      {
        question: "Can NRIs donate to Devasthanam?",
        answer: "Yes",
      },
    ],
    online_booking: [
      {
        question: `What documents need to be uploaded during online booking?`,
        answer: `Any Indian Govt.id proof is required for online booking.`,
      },
      {
        question: `What type of cards are accepted in internet banking?`,
        answer: `All Types of Debit/Credit cards are accepted.`,
      },
      {
        question: `Is there a UPI payment facility for online booking?`,
        answer: `Yes. We have provided a UPI payment facility for online booking.`,
      },
      {
        question: `Is it secure to give my personal details and my credit/debit card information for online booking/payments?`,
        answer: `Yes. It is safe to provide your personal details and credit/debit card information online booking/payments.`,
      },
      {
        question: `What if the transaction failed?`,
        answer: `Please contact the Devasthanam information centre available 24/7 to receive your complaints and suggestions. They will record your complaint and inform the concerned team to serve you better.`,
      },
      {
        question: `How many days will it take to refund the failed transaction amount?`,
        answer: `It takes around a minimum of 15 days to refund the amount.`,
      },
      {
        question: `Whom should we contact, if the failed transaction amount isn’t refunded?`,
        answer: `Please contact the Devasthanam information centre available 24/7 to receive your complaints and suggestions. They will record your complaint and inform the concerned team to serve you better`,
      },
      {
        question: `Can I cancel my ticket after online booking?`,
        answer: `No. Once booked, the tickets can not be cancelled.`,
      },
      {
        question: `How can I check my booking details?`,
        answer: `There is a user login facility available on the website. You may log in with your email ID and track your booking details.`,
      },
      {
        question: `Will I receive any confirmation through SMS or e-mail after online booking?`,
        answer: `Yes. The receipt will be generated after your transaction is completed and confirmation shall be sent via e-mail & SMS.`,
      },
      {
        question: `I didn’t receive a booking confirmation. What should I do?`,
        answer: `There is a user login facility available on the website. You may log in with your email ID and track your booking details.`,
      },
      {
        question: `Is a change in date of Darshanam/Seva/Accommodation or any online booking service possible?`,
        answer: `No. Please book your dates carefully as once booked, the dates can not be changed.`,
      },
      {
        question: `Can we book a ticket for Darshanam/Accommodation/Sevas on the same day of arrival?`,
        answer: `No, one must book tickets for Darshanam/Accommodation/Sevas at least 24 Hrs prior to arrival.`,
      },
      {
        question: `Can we make multiple bookings?`,
        answer: `Yes. You may make multiple bookings.`,
      },
      {
        question: `With one registration ID, how many tickets can I book?`,
        answer: `You may book up to 10 bookings for each service. But for Paroksha Seva, you can make unlimited bookings.`,
      },
      {
        question: `How much time will it take for booking confirmation?`,
        answer: `If the transaction is successful, the booking is confirmed immediately.`,
      },
      {
        question: `For further queries and complaints, whom should we contact?`,
        answer: `Devasthanam information centre employees can attend to all your queries & complaints. They shall record the calls and send them to the concerned department to serve you better.`,
      },
    ],

    // saswata_seva: [
    //   {
    //     question: `What is Saswata Seva?`,
    //     answer: `Amongst sevas performed in Sri Venkateswara Swamy Vari Devasthanam, a devotee can choose any seva to
    //   be performed on a specific date for 10 years on a chosen date.`,
    //   },
    //   {
    //     question: `How many years will you perform Seva on our name with Saswata Seva?`,
    //     answer: `Saswata Seva is performed for 10 Years Only.`,
    //   },
    //   {
    //     question: `Will you send any Prasadam for us?`,
    //     answer: `Yes. Prasadam will be sent through Indian Postal to the devotee’s address.`,
    //   },
    //   {
    //     question: `Will we get any privilege by booking Saswata Seva?`,
    //     answer: `Devotees who've booked Saswata Seva are eligible for donor ID privilege for 10 years
    //   period. A Donor Card issued in the donor's name facilitates the Darshan for 5 Members.`,
    //   },
    //   {
    //     question: `How will we know that you are performing Saswata Seva in our name?`,
    //     answer: `A notification will be sent via SMS before 1 Week.`,
    //   },
    //   {
    //     question: `Do I have to pay the whole amount at once?`,
    //     answer: `Yes. You will have to pay the whole amount in one go which will be received by
    //   Devasthanam.`,
    //   },
    // ],

    signin_up: [
      {
        question: `What are required proofs for registration?`,
        answer: (
          <>
            {" "}
            You have to provide anyone proof amongst the following list<br></br>
            Aadhar card
            <br />
            Voter card <br />
            Ration card
            <br />
            PAN Card
            <br />
            Passport <br />
            After registering with any ID proof from above listed documents, the
            pilgrim should carry the Original ID proof along with him which was
            submitted during registration.
          </>
        ),
      },
      {
        question: `What is the age limit for registration?`,
        answer: `All people 12 years & above can register on the devasthanam website.`,
      },
      {
        question: `What should I do, if I forget my password?`,
        answer: (
          <div style={paddingStyle}>
            You can reset your password with your registered mobile number or
            e-mail ID. To reset your password, please follow the steps listed
            below:
            <br />
            Click on Forget Password on the login page
            <br />
            {`- `} Your page will be redirected to the Reset your Password
            screen
            <br />
            {`- `}Enter your registered mobile number
            <br />
            {`- `}Click continue
            <br />
            {`- `}A One Time Password (OTP) will be sent to your mentioned email
            ID or mobile number.
            <br />
            {`- `} Enter the OTP and click continue.
            <br />
            {`- `}You are redirected to the password reset screen.
            <br />
            {`- `}Enter your new password.
            <br />
            {`- `}Re-Enter your new password for confirmation.
            <br />
            {`- `} Click on continue to complete the process.
            <br />
            {`- `} You can now sign in with your new password.
          </div>
        ),
      },
      {
        question: `What if I forget the email address that I used for registration.?`,
        answer: `If you have an existing account, try to login with that account details. But if you forget your
      login credentials, please contact us for help locating your account sign-in information.`,
      },
      {
        question: `What if I didn’t get an OTP?`,
        answer: `Please check that you've entered the correct previously registered mobile number or
      email ID. Otherwise, check your spam or junk section of your email.`,
      },
      {
        question: `Does my email ID acts as a Username?`,
        answer: `The name you enter in the signup process will act as your username.`,
      },
      {
        question: `Do I need to provide my credit/debit card details for registration?`,
        answer: `There is no need to provide any credit/debit card details for registration.`,
      },
      // {
      //   question: `Is registration mandatory for online booking?`,
      //   answer: `No. you can use the guest login.`,
      // },
      {
        question: `Can I cancel my ticket, when I book a ticket through guest login?`,
        answer: `No.`,
      },
      {
        question: `By registration, can I get updates and news of Temple regularly`,
        answer: `Yes. You will receive updates and news of Temple regularly.`,
      },
      {
        question: `For further queries, whom should we contact?`,
        answer: `You can contact Live chat, Customer support and you can contact through Devasthanam e-mail.`,
      },
    ],

    volunteer: [
      {
        question: `How to register as a volunteer?`,
        answer: `Devotees have to visit our website and go to the volunteer page. There you can register as a volunteer.`,
      },
      {
        question: `What is the age limit to register as a volunteer?`,
        answer: `18 years to 60 years.`,
      },
      {
        question: `What are the documents to be submitted to register as a volunteer?`,
        answer: `Any one of Government registered ID proofs such as Aadhar card, driving licence, voter identification card etc.`,
      },
      {
        question: `Can I register offline?`,
        answer: `Yes, You Can Register offline`,
      },
      {
        question: `Are there any accommodation facilities for volunteers?`,
        answer: `Yes. Accommodation will be provided by Devasthanam for volunteers.`,
      },
      {
        question: `What are the sectors available to work as volunteers?`,
        answer: `There are many sections for volunteers to work like Queue, AnnaPrasadam etc.`,
      },
      {
        question: `Can I choose any specific sector to work as a volunteer?`,
        answer: `No. Devasthanam authority will allot the sector rotation basis.`,
      },
      {
        question: `Can we choose to work as volunteers on any specific dates?`,
        answer: `Based on availability of slots you are able to work on specific dates.`,
      },
      {
        question: `Can I work as a volunteer all over the year?`,
        answer: `No.`,
      },
      {
        question: `How can we know that Devasthanam needs a volunteer?`,
        answer: `Devasthanam website will announce the requirement of volunteer in website.`,
      },
      {
        question: `Will you provide any Identity Card for a volunteer?`,
        answer: `Yes. Devasthanam will provide the ID card for every volunteer.`,
      },
      {
        question: `What is the validity of Identity cards?`,
        answer: `It depends on the Utsavams of ${pagesData("home")?.title}`,
      },
      {
        question: `Can I withdraw my voluntary, after registration?`,
        answer: `Yes.`,
      },
      {
        question: `Can I withdraw my voluntary, after being assigned to work?`,
        answer: `No.`,
      },
      {
        question: `Can I change my voluntary service dates?`,
        answer: `Yes.`,
      },
    ],

    pratyaksha_seva: [
      {
        question: "What is Pratyaksha seva?",
        answer:
          "The seva performed at temple and devotees can participate in person at the temple",
      },
      {
        question: "What are the uses of Pratyaksha seva?",
        answer:
          "Devotees can participate in the Seva as in person at the temple",
      },
      {
        question:
          "What is the difference between Paroksha seva and Pratyaksha seva?",
        answer:
          "Paroksha Seva is for those who are unable to participate in Sevas directly. Those who can participate in person at the Temple will be mentioned as Sevadaris of Pratyaksha Seva",
      },
      {
        question: "Who should attend Pratyaksha seva?",
        answer: "Any devotee can attend Pratyaksha Seva",
      },
      {
        question: "Is there any age limit to attend Pratyaksha Seva?",
        answer: "No",
      },
      {
        question: "Can unmarried persons attend Pratyaksha seva?",
        answer: "Yes",
      },
      {
        question: "What are the timings of Pratyaksha seva?",
        answer: "Based on Seva, the timings will be differed",
      },
      {
        question: "Is there any dress code to attend Pratyaksha Seva?",
        answer:
          "Yes. Pancha or Dhoti for male and Saree or Half Saree for female",
      },
      {
        question:
          "What are the rules and regulations to be followed during Pratyaksha seva?",
        answer:
          "Proper attire, peaceful mind and soul is enough for offering seva",
      },
      {
        question:
          "Will Pratyaksha sevas be performed at the same timings everyday?",
        answer: "Yes, but varies during the special dates like festivals",
      },
      {
        question: "What is the reporting time for Pratyaksha seva?",
        answer: "15 minutes prior the seva",
      },
      {
        question:
          "Will you perform Pratyaksha seva on auspicious and festival days?",
        answer: "No",
      },
      {
        question: "Where can I buy Pratyaksha Seva tickets?",
        answer: "At Devasthanam seva counter",
      },
      {
        question: "Can we book Pratyaksha seva online?",
        answer: "Yes",
      },
      {
        question:
          "What are the proofs to be submitted to book the Pratyaksha seva?",
        answer: "Aadhar card or Driving license or Voter ID or Passport",
      },
      {
        question: "How prior can we book Pratyaksha seva?",
        answer:
          "Devotee can book pratyaksha seva 15 days prior from the performance date",
      },
      {
        question: "What is the cut off time to book Pratyaksha seva?",
        answer:
          "The seva can be booked before 24 hours from the time of performance",
      },
      {
        question:
          "Can we attend Paroksha seva by booking a ticket for Pratyaksha seva?",
        answer: "No",
      },
      {
        question:
          "Can we attend Pratyaksha seva by booking a ticket for Paroksha seva?",
        answer: "No",
      },
      {
        question: "Will you perform two or more sevas at the same time?",
        answer: "No",
      },
      {
        question: "Are there any offers available, if we book more sevas?",
        answer: "No",
      },
      {
        question:
          "How many people are allowed to attend Pratyaksha seva on one ticket?",
        answer: "Single or Married couple and depends on Seva",
      },
      {
        question: "Will you perform seva to each individual separately?",
        answer: "That depends on the seva chosen",
      },
      {
        question: "What if Sevadhari is a non Telugu speaking person?",
        answer: "There will be explanation by Purohit elaborately",
      },
      {
        question:
          "Can Sevadhari adopt native language translator while performing Seva?",
        answer: "No",
      },
      {
        question: "Will you provide Prasadam for Pratyaksha seva attendees?",
        answer: "Yes",
      },
      {
        question:
          "What Prasadam will be given by you for Pratyaksha seva attendees?",
        answer: "It varies with the seva chosen",
      },
      {
        question: "Are there limited tickets for Pratyaksha seva?",
        answer: "No",
      },
      {
        question: "Can we cancel the Pratyaksha seva ticket?",
        answer: "No",
      },
      {
        question: "Can we transfer our ticket to another person?",
        answer: "No",
      },
      {
        question: "Can we change our Pratyaksha seva date?",
        answer: "No",
      },
      {
        question: "If I have any queries and doubts, whom should we contact?",
        answer: `Contact via mail : ${pagesData("home")?.email} or mobile : ${
          pagesData("home")?.mobile
        }`,
      },
    ],
    media_room: [
      {
        question: "What is a media room?",
        answer:
          "The media room is where devotees can find additional information about happenings in the temple, press reports, gallery and other information.",
      },
      {
        question: "Can I download the files in the media room?",
        answer: `${
          pagesData("home")?.title
        } is the sole owner of all the content on the website. Downloading or using the content for commercial purposes on our website is strictly prohibited. However, devotees can download the files for non-commercial or devotional purposes.`,
      },
      {
        question:
          "I am a Photographer and I want to donate my artwork to the temple. How can I submit my photographs?",
        answer: `We accept photographs, videos and artwork from professionals only. You may send your work for donation via email ${
          pagesData("home")?.email
        }. After review, If your artwork/photographs/videos are up to our standards, we shall publish them on our website with due credits to the artist.`,
      },
      {
        question: "How can I receive updates on the temple via email?",
        answer:
          "Please subscribe to our newsletter via our website. By doing so, you shall receive all the valuable communication via email.",
      },
      {
        question:
          "From where can I download information and details about specific tenders?",
        answer:
          "Click on your selected tender, you will go to that specific page from where you can download tender details.",
      },
      {
        question:
          "Is there a helpline or email address for queries or concerns regarding the media room of the temple?",
        answer: "Please contact our customer support for further queries.",
      },
    ],

    support: [
      {
        question: "Do you offer phone support?",
        answer: `Yes. Contact : Enquiry office : ${pagesData("home")?.mobile}`,
      },
      {
        question: "Do you offer email support?",
        answer: `Yes. mail : ${pagesData("home")?.email}`,
      },
      {
        question: "Do you offer chat support?",
        answer: "Yes",
      },
      {
        question: `It is our first time visiting ${
          pagesData("home")?.title
        }. Can we be assigned a support specialist or a guide to make our visit easy ?`,
        answer: "No",
      },
      {
        question: `Is ${
          pagesData("home")?.title
        } well connected in case of a medical emergency?`,
        answer: "Yes",
      },
      {
        question: `Are cab services available at ${pagesData("home")?.title}?`,
        answer: "Yes.",
      },
      {
        question: "When to contact your office?",
        answer: "From 09:30 a.m to 06:00 p.m everyday",
      },
      {
        question:
          "How to participate in cultural programmes during festivities",
        answer: "Contact temple office",
      },
      {
        question: `Do ${pagesData("home")?.title} run cloakrooms for devotees?`,
        answer: "Yes",
      },
      {
        question: `Is ${
          pagesData("home")?.title
        } well facilitated for a month-long stay?`,
        answer: "Yes",
      },
      {
        question: "If I have other questions, whom should I contact?",
        answer: `Contact mail : ${pagesData("home")?.email}`,
      },
    ],
  };

  return data[from];
};

export default FaqsData;
