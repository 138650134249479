import React from "react";
import "../../Utils/sd-styles/sd-misc.scss";
// import Sd_breadcrumb from "../../Components/sd-common-components/sd-breadcrumb";
import ParokshaSevaOne from "../../Components/body/paroksha-seva/sd-paroksha-seva-component-one";
import pagesData from "../../Utils/data/pages.data";
const Sd_terms_and_conditions = () => {
  return (
    <div>
      {/* <Sd_breadcrumb from="Terms and Conditions"></Sd_breadcrumb> */}
      <ParokshaSevaOne from="Terms and Conditions" />
      <div className="sd-t-and-c">
        <div className="sd-t-and-c-inner">
          {/* <h2></h2> */}
          <div className="top white-bc">
            <h1>Terms and Conditions</h1>
            <img
              src={pagesData("home").divider}
              alt={"SD_divider"}
              style={{ height: "20px" }}
            ></img>
          </div>
          <>
            <br />
            <span style={{ paddingLeft: "0px" }}>
              Last Updated: {pagesData("terms")?.date}
            </span>
            <br />
            <br />
            {pagesData("terms")?.desc}
            <br />
            {pagesData("terms")?.terms?.map((item, i) => {
              return (
                <div key={i}>
                  <br />
                  <span>{item?.section}</span>
                  {typeof item?.content === "string"
                    ? item?.content
                    : item?.content?.map((subitem, s) => {
                        return (
                          <div
                            key={s}
                            className="ml-2 mt-3"
                            style={{ marginBottom: "20px" }}
                          >
                            <span>{subitem?.section}</span>
                            {subitem?.content}
                            <br />
                          </div>
                        );
                      })}
                  <br />
                </div>
              );
            })}
          </>
        </div>
      </div>
    </div>
  );
};
export default Sd_terms_and_conditions;
