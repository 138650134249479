const prodSites = {
  dwarakatirumala: "SVSVD",
  srikalahastheeswaraswamy: "SKHTD",
  simhachalamdevasthanam: "SVLNSD",
  srikanipakadevasthanam: "SSVVSD",
  kanakadurgamma: "SSVVSD",
};

const devSites = ["SVSVD", "SKHTD", "SVLNSD", "SSVVSD", "SDMSD"];

const fetchTempleIDforDev = (location) => {
  return process.env.REACT_APP_TEMPLE ??
    devSites?.includes(location?.toString()?.split(".")[0].toUpperCase())
    ? location?.toString()?.split(".")[0].toUpperCase()
    : "SVSVD";
};

const getTempleShortCodeFromDomain = (location) => {
  return process.env.REACT_APP_MasterFlag === "dev"
    ? fetchTempleIDforDev(location)
    : prodSites[location] ?? "SVSVD";
};

export default getTempleShortCodeFromDomain;
