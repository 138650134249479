import React, { useContext, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import Sd_loading from "../../../Components/sd-common-components/sd-loading";
import { SD_Context } from "../../../context/sd-context";

import { SD_Print_Icon } from "../../../Assets/imageList";
import { toast } from "react-toastify";

import Sd_DashboardService from "../../../services/sd-dashboard.service";
import PrintDonorCoupon from "./sd-donor-module-print";

const Sd_Dashboard_Donor_module = () => {
  const { setActive, setActivePath, templeID } = useContext(SD_Context);
  const [modal, setModal] = useState(false);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState();
  const [blocks, setBlocks] = useState([]);
  const [selectedBlock, setSelectedBlock] = useState(null);
  const [printData, setPrintData] = useState();

  useEffect(() => {
    setLoading(true);
    setActive("donor-module");
    setActivePath("Donor Module");

    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await Sd_DashboardService.getAllDonorCoupons(templeID);
      setData(response.items);
      const blocksData = [];
      response.items.forEach((item) => {
        let findOne = blocksData.find(
          (innerItem) =>
            innerItem.sd_accommodation_donar_registry.sd_block_details
              .block_name ===
              item.sd_accommodation_donar_registry.sd_block_details
                .block_name &&
            item.sd_accommodation_donar_registry.sd_rooms.room_number ===
              innerItem.sd_accommodation_donar_registry.sd_rooms.room_number
        );
        if (!findOne) {
          blocksData.push(item);
        }
      });
      setBlocks(blocksData);
      setSelectedBlock(blocksData[0]);
    } catch (error) {
      toast.error(error.message);
    }
    setLoading(false);
  };
  const onPrintFunction = (item) => {
    setModal(true);
    setPrintData(item);
  };

  const displayCoupons = () => {
    let filtered = data.map((e, i) => {
      if (
        e.sd_accommodation_donar_registry.sd_block_details.block_name ===
          selectedBlock.sd_accommodation_donar_registry.sd_block_details
            .block_name &&
        e.sd_accommodation_donar_registry.sd_rooms.room_number ===
          selectedBlock.sd_accommodation_donar_registry.sd_rooms.room_number
      ) {
        return (
          <div
            key={`coupn-box-${i}`}
            className={!e.used ? "coupon-box" : "coupon-box opacity-05"}
          >
            <div className="cc-left">
              Rs.{e.max_offer_amount} <br />
              OFF
            </div>
            <div className="up-circle"></div>
            <div className="down-circle"></div>
            <div className="cc-right">
              <p className="cc-right-heading">Coupon Code</p>
              <p>
                {e.coupon_code}{" "}
                <img
                  onClick={() => {
                    onPrintFunction(e);
                  }}
                  src={SD_Print_Icon}
                  style={{ margin: "-1px 0px 0px 5px", height: "14px" }}
                />
              </p>
            </div>
            {
              <div
                className={!e.used ? `cc-used used-c` : "cc-used not-used-c"}
              >
                {!e.used ? "Available" : "Used"}
              </div>
            }
          </div>
        );
      } else {
        return;
      }
    });
    return filtered;
  };
  const displayBlocks = () => {
    if (blocks?.length === 0 || !blocks) {
      return <div>No Blocks available</div>;
    }
    return blocks.map((item, i) => {
      return (
        <div
          className="sd-acc-blocks"
          key={"blocks" + i}
          tabIndex={i}
          onClick={() => {
            setSelectedBlock(item);
          }}
        >
          <div className="sd-block-name">
            <div
              className={
                item?.id === selectedBlock?.id
                  ? "sd-selected-border"
                  : "sd-plain-border"
              }
            ></div>
            {
              item?.sd_accommodation_donar_registry?.sd_block_details
                ?.block_name
            }
          </div>
          <div className="sd-room-name">
            <span style={{ fontSize: "13px" }}>Room No </span>
            <br />
            {item?.sd_accommodation_donar_registry?.sd_rooms?.room_number}
          </div>
        </div>
      );
    });
  };
  return (
    <>
      <div className="col-xs-12 col-sm-12 col-md-9 col-lg-9 sd-dashboard">
        {loading ? (
          <Sd_loading />
        ) : (
          <>
            <h2
              className="sd-side-heading fw500"
              style={{ paddingBottom: "10px" }}
            >
              <span className="fw700">Donor</span> Module
            </h2>
            {/* <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 pl-0 pr-0 sd-dd-boxes flex-start pt-10">
              <div className="form-group col-xs-12 col-sm-12 col-md-3 col-lg-3  xssm-pl-0 xssm-pr-0 fs-16 pl-0">
                Name: S.Saikrishna
              </div>
              <div className="form-group col-xs-12 col-sm-12 col-md-3 col-lg-3  xssm-pl-0 xssm-pr-0 fs-16 pl-0">
                Address: S.Saikrishna
              </div>
              <div className="form-group col-xs-12 col-sm-12 col-md-3 col-lg-3  xssm-pl-0 xssm-pr-0 fs-16 pl-0">
                Delivey: S.Saikrishna
              </div>
              <div className="form-group col-xs-12 col-sm-12 col-md-3 col-lg-3  xssm-pl-0 xssm-pr-0 fs-16 pl-0">
                AMount: S.Saikrishna
              </div>
              <div className="form-group col-xs-12 col-sm-12 col-md-3 col-lg-3  xssm-pl-0 xssm-pr-0 fs-16 pl-0">
                GST: S.Saikrishna
              </div>
            </div> */}
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 pl-0 pr-0 sd-dd-boxes flex-start pt-20">
              {displayBlocks()}
            </div>
            <hr className="clearfix col-xs-12 col-sm-12 col-md-12 col-lg-12 pl-0"></hr>
            <h2
              className="sd-side-heading fw500"
              style={{ paddingBottom: "10px" }}
            >
              <span className="fw700">Accommodation</span> Coupons{" "}
            </h2>

            <hr className="clearfix col-xs-12 col-sm-12 col-md-12 col-lg-12 pl-0"></hr>

            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 pl-0 pr-0 sd-dd-boxes flex-start pt-20">
              {selectedBlock ? displayCoupons() : "No Coupons available"}
            </div>
          </>
        )}
      </div>{" "}
      {modal && (
        <div className="sd-profile-popup" style={{ padding: "0px !important" }}>
          <div
            className="inner"
            style={{
              height: "auto",
              display: "flex",
              flexDirection: "column",
              minHeight: "150px",
              width: "fit-content",
              overflowY: "scroll",
              padding: "0px",
            }}
          >
            <PrintDonorCoupon data={printData} setModal={setModal} />
            {/* <div style={{display: "flex"}}>
              <button
                style={{
                  color: "white",
                  backgroundColor: "#991403",
                  marginTop: "10px",
                  marginRight: "10px",
                  alignSelf: "center",
                }}
                className="btn"
                onClick={() => {
                  setModal(false);
                }}
              >
                Print
              </button>
              <button
                style={{
                  color: "white",
                  backgroundColor: "#991403",
                  marginTop: "10px",
                  alignSelf: "center",
                }}
                className="btn"
                onClick={() => {
                  setModal(false);
                }}
              >
                Download
              </button>
            </div> */}
          </div>
        </div>
      )}{" "}
    </>
  );
};
export default withRouter(Sd_Dashboard_Donor_module);
