export const colors = {
  APEDP: {
    "--baseColor": "#ffffff",
    "--secondaryColor": "#ffffff",
    "--topNavTextColor": "#ffffff",
    "--bottomNavtextColor": "#ffffff",
    "--bottomNavInnerTextColor": "#ffffff",
    "--navElementBorderColor": "#ffffff",
    "--navElementHoverColor": "#ffffff",
    "--mobileNavElementBackground": "#ffffff",
    "--mobileNavInnerElementBackground": "#ffffff",
    "--baseDotColor": "#ffffff",
    "--activeDotColor": "#ffffff",
    "--buttonBackgroundColor": "#ffffff",
    "--buttonHoverBackgroundColor": "#ffffff",
    "--buttonHoverTextColor": "#ffffff",
    "--buttonTextColor": "#ffffff",
    "--breadcrumbTextColor": "#ffffff",
    "--headingsColor": "#ffffff",
    "--servicesBackground": "#ffffff",
    "--happeningsBackground": "#ffffff",
    "--headingTextColor": "#ffffff",
    "--newsBarColor": "#ffffff",
    "--footerTopBackground": "#ffffff",
    "--footerMiddleBackground": "#ffffff",
    "--footerBottomBackground": "#ffffff",
    "--borderDividerColor": "#ffffff",
  },
  SVSVD: {
    "--baseColor": "#0e3b38",
    "--secondaryColor": "#36908a",
    "--topNavTextColor": "#ffffff",
    "--bottomNavtextColor": "#ffffff",
    "--bottomNavInnerTextColor": "black",
    "--navElementBorderColor": "#36908a",
    "--navElementHoverColor": "#0e3b38",
    "--mobileNavElementBackground": "#fae1cb",
    "--mobileNavInnerElementBackground": "#fef4eb",
    "--baseDotColor": "#0e3b38",
    "--activeDotColor": "#36908a",
    "--buttonBackgroundColor": "#0e3b38",
    "--buttonHoverBackgroundColor": "#36908a",
    "--buttonHoverTextColor": "#181818",
    "--buttonTextColor": "#ffffff",
    "--breadcrumbTextColor": "#0e3b38",
    "--headingsColor": "#36908a",
    "--servicesBackground": "rgb(54, 144, 138, 0.1)",
    "--happeningsBackground": "rgb(54, 144, 138, 0.1)",
    "--headingTextColor": "#0e3b38",
    "--newsBarColor": "#0e3b38",
    "--footerTopBackground": "#36908a",
    "--footerMiddleBackground": "#0e3b38",
    "--footerBottomBackground": "#062221",
    "--borderDividerColor": "#ffffff",
  },
  SKHTD: {
    "--baseColor": "#643a24",
    "--secondaryColor": "#d09a49",
    "--topNavTextColor": "#ffffff",
    "--bottomNavtextColor": "#000f41",
    "--bottomNavInnerTextColor": "black",
    "--navElementBorderColor": "#d09a49",
    "--navElementHoverColor": "#643a24",
    "--mobileNavElementBackground": "#fae1cb",
    "--mobileNavInnerElementBackground": "#fef4eb",
    "--baseDotColor": "#643a24",
    "--activeDotColor": "#d09a49",
    "--buttonBackgroundColor": "#643a24",
    "--buttonHoverBackgroundColor": "#d09a49",
    "--buttonHoverTextColor": "black",
    "--buttonTextColor": "#ffffff",
    "--breadcrumbTextColor": "#643a24",
    "--headingsColor": "#d09a49",
    "--servicesBackground": "rgba(134, 100, 86, 0.15)",
    "--happeningsBackground": "rgba(214, 207, 201, 0.21)",
    "--headingTextColor": "#643a24",
    "--newsBarColor": "#d1994a",
    "--footerTopBackground": "#d1994a",
    "--footerMiddleBackground": "#886456",
    "--footerBottomBackground": "#643a24",
    "--borderDividerColor": "#ffffff",
  },
  SVLNSD: {
    "--baseColor": "#25537d",
    "--secondaryColor": "#f0c53e",
    "--topNavTextColor": "#ffffff",
    "--bottomNavtextColor": "#181818",
    "--bottomNavInnerTextColor": "black",
    "--navElementBorderColor": "#f0c53e",
    "--navElementHoverColor": "#a41503",
    "--mobileNavElementBackground": "#fae1cb",
    "--mobileNavInnerElementBackground": "#fef4eb",
    "--baseDotColor": "#25537d",
    "--activeDotColor": "#f0c53e",
    "--buttonBackgroundColor": "#bd242b",
    "--buttonHoverBackgroundColor": "#f0c53e",
    "--buttonHoverTextColor": "#181818",
    "--buttonTextColor": "#ffffff",
    "--breadcrumbTextColor": "#a41503",
    "--headingsColor": "#f0c53e",
    "--servicesBackground": "#fdeedf",
    "--happeningsBackground": "#fdeedf",
    "--headingTextColor": "#bd242b",
    "--newsBarColor": "#25537d",
    "--footerTopBackground": "#f0c53e",
    "--footerMiddleBackground": "#25537d",
    "--footerBottomBackground": "#1c4469",
    "--borderDividerColor": "#ffffff",
  },
  SSVVSD: {
    "--baseColor": "#a41503",
    "--secondaryColor": "#ef9e1f",
    "--topNavTextColor": "#ffffff",
    "--bottomNavtextColor": "#ffffff",
    "--bottomNavInnerTextColor": "black",
    "--navElementBorderColor": "#c4681a",
    "--navElementHoverColor": "#a41503",
    "--mobileNavElementBackground": "#fae1cb",
    "--mobileNavInnerElementBackground": "#fef4eb",
    "--baseDotColor": "#a41503",
    "--activeDotColor": "#ef9e1f",
    "--buttonBackgroundColor": "#991403",
    "--buttonHoverBackgroundColor": "#991403",
    "--buttonHoverTextColor": "#ffffff",
    "--buttonTextColor": "#ffffff",
    "--breadcrumbTextColor": "#991403",
    "--headingsColor": "#991403",
    "--servicesBackground": "#ffefe2",
    "--happeningsBackground": "#ffefe2",
    "--headingTextColor": "#991403",
    "--newsBarColor": "#991403",
    "--footerTopBackground": "#ef9e1f",
    "--footerMiddleBackground": "#a41503",
    "--footerBottomBackground": "#751212",
    "--borderDividerColor": "#c4681a",
  },
  SDMSD: {
    "--baseColor": "#620404",
    "--secondaryColor": "#ecbd00",
    "--topNavTextColor": "#ffffff",
    "--bottomNavtextColor": "#000000",
    "--bottomNavInnerTextColor": "black",
    "--navElementBorderColor": "#c4681a",
    "--navElementHoverColor": "#620404",
    "--mobileNavElementBackground": "#fae1cb",
    "--mobileNavInnerElementBackground": "#fef4eb",
    "--baseDotColor": "#620404",
    "--activeDotColor": "#ecbd00",
    "--buttonBackgroundColor": "#991403",
    "--buttonHoverBackgroundColor": "#991403",
    "--buttonHoverTextColor": "#ffffff",
    "--buttonTextColor": "#ffffff",
    "--breadcrumbTextColor": "#991403",
    "--headingsColor": "#991403",
    "--servicesBackground": "#F9EBB2",
    "--happeningsBackground": "#F9EBB2",
    "--headingTextColor": "#991403",
    "--newsBarColor": "#991403",
    "--footerTopBackground": "#ecbd00",
    "--footerMiddleBackground": "#620404",
    "--footerBottomBackground": "#751212",
    "--borderDividerColor": "#c4681a",
  },
};
