import React, { useContext, useRef } from "react";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { withRouter } from "react-router";
import {
  sd_minus_icon,
  sd_plus_icon,
  SD_Logo_circle,
} from "../../../Assets/imageList";
import "../../../Utils/sd-styles/sd-about.scss";
import Sd_comming_soon from "../../../pages/sd-about/sd-coming-soon";
import pagesData from "../../../Utils/data/pages.data";
import { SD_Context } from "../../../context/sd-context";
import NavBarData from "../../../Utils/data/navbar.data";

const Sd_about_general_information_component = ({
  match,
  visible,
  history,
}) => {
  //-------------------------------------------------------------states--------------------------------------------------------//

  const [type, setType] = useState(match.params.type);
  const [drop1, setDrop1] = useState("");
  const [drop2, setDrop2] = useState("");
  const { language } = useContext(SD_Context);

  const titleRef = useRef();

  const dieties = NavBarData("dieties");

  const trust = NavBarData("donation").splice(1, NavBarData("donation").length);
  //-------------------------------------------------------------states--------------------------------------------------------//

  //-------------------------------------------------------------useEffects--------------------------------------------------------//

  useEffect(() => {
    if (match?.params?.type) {
      setType(match?.params?.type);
    } else {
      setType("dos-and-donts");
    }
    titleRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  }, [match?.params?.type]);

  useEffect(() => {
    history?.push(`/${language?.code}/about/general-information/${type}`);
  }, [type]);

  //-------------------------------------------------------------useEffects--------------------------------------------------------//
  const data = pagesData("about")?.generalInformation[type];
  const sideMenuList = [
    {
      name: "Visiting Places",
      path: "visiting-places",
    },
    { name: "Dieties", path: "dieties", children: dieties },
    { name: "Adopted Temples", path: "adopted-temples" },
    { name: "Trusts", path: "trusts", children: trust },
    { name: "Chairman", path: "chairman" },
    { name: "Executive Officer", path: "executive" },
    { name: "Do's & Don'ts", path: "dos-and-donts" },
    { name: "Intellectual Properties", path: "intellectual-property" },
    { name: "Certifications", path: "certifications" },
  ];
  const seoData = {
    dieties: {
      seo_tag: {
        title: "",
        desc: "",
        keywords: "",
        h1_tag: "",
      },
    },
    trusts: {
      seo_tag: {
        title: `${pagesData("home")?.title} Trust | ${
          pagesData("home")?.title
        } `,
        desc: `${pagesData("home")?.title} Trust comes under ${
          pagesData("home")?.title
        } to collect funds for preserving the ancient shrine. Contribute to ${
          pagesData("home")?.title
        } Trust.`,
        keywords: `${pagesData("home")?.title} Trust, ${
          pagesData("home")?.title
        } `,
        h1_tag: `${pagesData("home")?.title} Trust`,
      },
    },
    "e-Hundi": {
      seo_tag: {
        title: `${pagesData("home")?.title}  | ${pagesData("home")?.title}`,
        desc: `${
          pagesData("home")?.title
        } Trust is a nonprofitable organisation. People make donations to ${
          pagesData("home")?.title
        } Trust on ${pagesData("home")?.title} Online.`,
        keywords: `${pagesData("home")?.title} , ${pagesData("home")?.title}`,
        h1_tag: `${pagesData("home")?.title}`,
      },
    },
  };

  const renderLinks = (innerData) => {
    const datas = innerData.map((item, i) => {
      console.log(item);
      return (
        <div
          key={i + "geenral"}
          className={type === item.path && "sd-active"}
          onClick={() => {
            setType(item.path.replace("about/general-information/", ""));
          }}
        >
          {item.name}
        </div>
      );
    });
    return datas;
  };

  const handleClick = (item) => {
    setType(
      item?.children === undefined
        ? item?.path
        : item?.children[0]?.path?.replace("about/general-information/", "")
    );
    setDrop1(item?.path === drop1 ? "" : item?.path);
  };

  const renderListItem = (item, hasChildren) => (
    <li
      className={drop1 === item?.path ? "sd-active" : ""}
      onClick={() => handleClick(item)}
    >
      {item?.name}
      {hasChildren && (
        <img
          height="18px"
          src={item?.path === drop1 ? sd_minus_icon : sd_plus_icon}
          alt="-"
          style={{ marginTop: "6px" }}
          onClick={() => handleClick(item)}
        />
      )}
    </li>
  );

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {" "}
          {seoData[type]?.seo_tag?.title ??
            `${pagesData("home")?.title} Official Website`}
        </title>
        <meta
          name="description"
          content={
            seoData[type]?.seo_tag?.desc ??
            `${pagesData("home")?.title} Official Website`
          }
        />
        <meta name="keywords" content={seoData[type]?.seo_tag?.keywords} />
      </Helmet>
      <div className="sd-about-temple-history">
        {visible && (
          <div className="sd-about-temple-history-left">
            <ol>
              {sideMenuList?.map((item, i) => {
                console.log(item?.children);
                return (
                  <div key={i}>
                    {!item?.children
                      ? renderListItem(item, false)
                      : item?.children?.length > 0 &&
                        renderListItem(item, true)}

                    <div
                      className={
                        item?.path === drop1 ? "drop1 block" : "drop1 none"
                      }
                    >
                      {item?.children?.map((subitem, j) => (
                        <div key={j}>
                          {subitem?.children !== undefined ? (
                            <>
                              <div
                                className={
                                  drop2 === subitem?.path
                                    ? "main sd-active"
                                    : "main"
                                }
                                onClick={() => {
                                  setType(
                                    (item?.children === undefined
                                      ? item?.path
                                      : item?.children[0]?.path
                                    )?.replace("about/general-information/", "")
                                  );
                                  setDrop2(
                                    subitem?.path === drop2 ? "" : subitem?.path
                                  );
                                }}
                              >
                                <span>{subitem?.name}</span>
                                <img
                                  height="18px"
                                  src={
                                    subitem?.path === drop2
                                      ? sd_minus_icon
                                      : sd_plus_icon
                                  }
                                  alt="-"
                                  style={{ marginTop: "6px" }}
                                  onClick={() => {
                                    setType(
                                      (item?.children === undefined
                                        ? item?.path
                                        : item?.children[0]?.path
                                      )?.replace(
                                        "about/general-information/",
                                        ""
                                      )
                                    );
                                    setDrop2(
                                      subitem?.path === drop2
                                        ? ""
                                        : subitem?.path
                                    );
                                  }}
                                ></img>
                              </div>
                              <div
                                className={
                                  subitem?.path === drop2
                                    ? "drop2 block"
                                    : "drop2 none"
                                }
                              >
                                {renderLinks(subitem?.children)}
                              </div>
                            </>
                          ) : (
                            <div
                              className={
                                item?.path === drop1
                                  ? "drop2 block"
                                  : "drop2 none"
                              }
                            >
                              <div
                                key={i + "geenral"}
                                className={type === subitem.path && "sd-active"}
                                onClick={() => {
                                  setType(
                                    subitem.path.replace(
                                      "about/general-information/",
                                      ""
                                    )
                                  );
                                }}
                              >
                                {subitem.name}
                              </div>
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                );
              })}
            </ol>
          </div>
        )}{" "}
        <div className="sd-about-temple-history-right">
          <div style={{ display: "block" }}>
            <div className="sd-about-temple-history-right-top" ref={titleRef}>
              <h1>
                <span> {data?.heading}</span> {}
              </h1>
              <img
                src={pagesData("home").divider}
                style={{ height: "20px" }}
                alt={"divider"}
              ></img>
            </div>
            <div className="sd-about-temple-history-right-bottom">
              {data?.description1?.length || data?.do?.length > 0 ? (
                <div className="sd-athrb-top">
                  {["chairman", "executive"]?.includes(type) ? (
                    <>
                      <div style={{ display: "flex" }} className="general-flex">
                        <div style={{ textAlign: "center" }}>
                          <img src={data?.image} height="200px" />
                          <h4
                            style={{
                              padding: "8px 0px",
                              color: "#991403",
                              fontWeight: "600",
                            }}
                          >
                            {data?.name}
                          </h4>
                          <h5 style={{ marginBottom: "5px" }}>
                            {data?.designation}
                          </h5>
                        </div>
                        <div style={{ paddingLeft: "20px" }}>
                          {data?.description1?.map((item, i) => {
                            return <p key={i}>{item}</p>;
                          })}
                        </div>
                      </div>
                    </>
                  ) : type === "adopted-temples" ||
                    type === "visiting-places" ? (
                    <div className="trust-board-wrapper">
                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 pl-0 xs-pr-0 sd-profile pr-0">
                        <div
                          className="sd-seva-booking"
                          style={{ justifyContent: "space-between" }}
                        >
                          {data?.description1?.map((temple, i) => {
                            return (
                              <div
                                className="seva-div-booking"
                                style={{
                                  padding: "15px",
                                  alignItems: "center",
                                }}
                                key={i + "booking-ps"}
                              >
                                <img
                                  src={SD_Logo_circle}
                                  height="30px"
                                  width={"30px"}
                                />
                                <h4 style={{ fontSize: "18px" }}>
                                  {temple?.title}
                                </h4>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  ) : type === "trust-board" ? (
                    <div className="trust-table">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <img src={data?.image} alt="logo" width="250px" />
                        <br />
                        <h4>{data?.title}</h4>
                      </div>
                      <table>
                        <thead>
                          <tr>
                            {data?.tableHeaders.map((header, index) => (
                              <th key={index}>{header}</th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {data?.description1?.map((trustee, t) => (
                            <tr key={t}>
                              <td>{t + 1}</td>
                              <td>{trustee.name}</td>
                              <td>{trustee.desc}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  ) : type === "dos-and-donts" ? (
                    <>
                      <h3>Do’s</h3>
                      <br />
                      <ul>
                        {data?.do?.map((list, l) => {
                          return (
                            <li style={{ listStyleType: "disc" }} key={l}>
                              <p>{list}</p>
                            </li>
                          );
                        })}
                      </ul>
                      <br />
                      <h3>Don’ts</h3>
                      <br />
                      <ul>
                        {data?.dont?.map((list, l) => {
                          return (
                            <li style={{ listStyleType: "disc" }} key={l}>
                              <p>{list}</p>
                            </li>
                          );
                        })}
                      </ul>
                    </>
                  ) : (
                    <>
                      {data?.image !== "" && (
                        <img
                          src={data?.image}
                          alt="alternative"
                          align="left"
                          className="sd-imgs"
                          onLoad={() => {
                            // setImageLoading(false);
                          }}
                        />
                      )}
                      {data?.description1?.map((item, i) => {
                        return <p key={i}>{item}</p>;
                      })}

                      <div className="sd-athrb-bottom">
                        {data?.description2?.map((item, i) => {
                          return <p key={i}>{item}</p>;
                        })}
                      </div>
                    </>
                  )}
                </div>
              ) : (
                <Sd_comming_soon />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default withRouter(Sd_about_general_information_component);
