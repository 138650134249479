/* eslint-disable react/jsx-key */
import React, { useEffect, useContext, useState, useRef } from "react";
import {
  sd_dashboard_edit_ico,
  sd_dashboard_side_arrow,
  sd_success_icon,
  sd_failure_icon,
} from "../../../../Assets/imageList";

import Sd_loading from "../../../../Components/sd-common-components/sd-loading";
import { SD_Context } from "../../../../context/sd-context";
import IndiaTime from "../../../../Utils/sd-moment/IndiaTime";
import "../../../../Utils/sd-styles/sd-css/main.css";
import DisplayRazorpay from "../../../../Utils/sd-razorpay/sd-razorpay-display";
import ParokshaSevaService from "../../../../services/sd-parokshaseva.service";
import { toast } from "react-toastify";
import moment from "moment";
import { withRouter } from "react-router";
import "react-toastify/dist/ReactToastify.css";
import { BASE_BANK } from "../../../../Utils/sd-axios/sd-api-url";
import PaymentPage from "../payment-pages/payement-page";
toast.configure();

const Sd_dashboard_parokshaseva_booking_confirm_details = ({ history }) => {
  //--------------------------------------------------------------states---------------------------------------------------------//

  const {
    setActive,
    setActivePath,
    currentBooking,
    setCurrentBooking,
    userData,
    logout,
    templeID,
    paymentPageFlag,
    setPaymentPageFlag,
    language,
  } = useContext(SD_Context);

  const [successOrfailureFlag, setSuccessOrfailureFlag] = useState(false);
  const [status, setStatus] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [confirmedData, setConfirmedData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [dissmissed, setDissmissed] = useState("");
  const [halfState, setHalfState] = useState(null);
  const refContainer = useRef();
  // const [charges, setCharges] = useState("");
  const [timestamp, setTimestamp] = useState("");
  //--------------------------------------------------------------states---------------------------------------------------------//

  //--------------------------------------------------------------useeffetcs---------------------------------------------------------//

  useEffect(() => {
    if (dissmissed === "failed") {
      setErrorMsg("Cancelled by User");
      setSuccessOrfailureFlag(true);
      setStatus("failed");
      setLoading(false);
      setDissmissed("");
    }
  }, [dissmissed]);

  useEffect(async () => {
    setLoading(true);
    if (!currentBooking) {
      history?.push(
        `/${language?.code}/devotee-app/online-booking/paroksha-seva`
      );
    }
    setActive("onlinebooking-paroksha-seva-confirm-details");
    setActivePath("Onlinebooking-Paroksha Seva-Enter Details-Confirm Details");
    setTimestamp("");

    const localData = JSON.parse(localStorage.getItem("payment-context"));
    if (!currentBooking) {
      if (localData) {
        if (localData.responseData && localData.bookingData) {
          setHalfState(localData.responseData);
          setCurrentBooking(localData.bookingData);
          setTimeout(() => {
            setPaymentPageFlag(true);
          }, 1000);
        } else {
          setCurrentBooking(localData.bookingData);
        }
      } else {
        history?.push(
          `/${language?.code}/devotee-app/online-booking/paroksha-seva`
        );
      }
    }

    setLoading(false);
    return () => {
      setTimestamp("");
    };
  }, []);

  useEffect(() => {
    refContainer?.current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  }, [status]);

  //--------------------------------------------------------------useeffects---------------------------------------------------------//

  //--------------------------------------------------------------functions---------------------------------------------------------//
  const getData = () => {
    let array = [];

    currentBooking?.selectedArray.map((item) => {
      array.push(
        <td className="sd-bl-none">
          {item?.name}
          {currentBooking?.slot?.productSlot && <br></br>}
          {currentBooking?.slot?.productSlot &&
            `Slot Time :   ${moment(
              currentBooking?.slot?.productSlot?.startTime,
              "HH:mm:ss"
            ).format("hh:mm A")}
            -
            ${moment(
              currentBooking?.slot?.productSlot?.endTime,
              "HH:mm:ss"
            ).format("hh:mm A")}`}
          <br></br> (Paroksha Seva)
        </td>
      );
    });
    return array;
  };

  function capitalize(str) {
    let splitStr = str?.toLowerCase?.().split?.(" ");
    for (let i = 0; i < splitStr?.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr?.join(" ");
  }

  const getDetailsFunction = () => {
    let array = [];
    let i = 0;
    currentBooking?.personStates.map((obj) => {
      if (obj?.name !== "") {
        array.push(
          <tr>
            <td>{i + 1}</td>
            <td>{obj?.name}</td>
            <td>{currentBooking?.gothram}</td>
          </tr>
        );
      }
      i++;
    });
    return array;
  };

  const handleSubmit = async () => {
    let res;
    if (!halfState) {
      try {
        setLoading(true);

        let dup = [...currentBooking?.personStates];
        let arrayEx = dup.map((e) => {
          return {
            personName: e?.name,
          };
        });
        //
        let dto = {
          sevaSlotId: currentBooking?.slot?.productSlot?.id,
          bookingDate: moment(currentBooking?.startDate).format("YYYY-MM-DD"),
          phoneNumber: `+${currentBooking?.mobile}`,
          personDetails: arrayEx,
          gothram: currentBooking?.gothram,
          address: capitalize(currentBooking?.address1),
          city: capitalize(currentBooking?.city),
          state: currentBooking?.state,
          country: currentBooking?.country,
          pinCode: Number(currentBooking?.zip),
        };
        if (currentBooking?.address2 !== "") {
          dto.address2 = capitalize(currentBooking?.address2);
        }
        let schema = {
          cartContains: ["seva"],
          sevaBookings: [dto],
          returnPriceStats: false,
        };
        if (
          currentBooking?.res.templePaymentConfig.configuration.type ===
          "ccavenue"
        ) {
          schema.returnURL = `${window.location.origin}/en-in/devotee-app/online-booking/payment-status`;
        }
        res = await ParokshaSevaService.bookings(schema, templeID);

        setHalfState(res);
        localStorage.setItem(
          "payment-context",
          JSON.stringify({
            responseData: {
              data: {
                productDetails: res.cartItems.seva[0].productDetails,
                price: res.cartItems.seva[0].sevaBooking.price,
                personDetails: res.cartItems.seva[0].sevaBooking.personDetails,
              },
              ...res,
            },
            bookingData: currentBooking,
            from: "parokshaSeva",
          })
        );
      } catch (e) {
        //
        toast.error(e.message);
        if (e.message === "Session Expired") {
          logout();

          history?.push(
            `/${language?.code}/devotee/signin?to=devotee-app/online-booking/paroksha-seva`
          );
        }
        setLoading(false);

        return;
      }
    }

    try {
      if (halfState !== null) {
        if (Math.floor((Date.now() - timestamp) / 1000) > 900) {
          toast.error(
            "Redirecting to Previous Page as buffer time of 15 min for retrying the payment has been exceeded."
          );
          setTimestamp("");
          setTimeout(() => {
            history?.push(
              `/${language?.code}/devotee-app/online-booking/paroksha-seva/booking/ganapathi-homam`
            );
          }, 3000);
          return;
        }
        if (
          currentBooking?.res.templePaymentConfig.configuration.type ===
          "ccavenue"
        ) {
          if (
            halfState ||
            JSON.parse(localStorage.getItem("payment-context"))
          ) {
            setLoading(false);
            setTimestamp("");
            setPaymentPageFlag(true);
            return;
          }
        } else if (
          currentBooking?.res.templePaymentConfig.configuration.type ===
          "razorpay"
        ) {
          await DisplayRazorpay(
            halfState?.paymentInformation?.razorOrder?.id,
            halfState?.paymentInformation?.razorOrder?.currency,
            halfState?.paymentInformation?.razorOrder?.amount,
            halfState?.cartItems?.donation,
            halfState?.paymentInformation?.onlinePaymentEntity?.id,
            BASE_BANK,
            setDissmissed,
            userData,
            dissmissed,
            halfState?.priceStats?.templePaymentConfig?.razorpay?.key,
            templeID
          );
          setHalfState(null);
          setConfirmedData({
            selectedArray: currentBooking.selectedArray,
            startDate: currentBooking.startDate,
            slot: currentBooking.slot,
          });
          setCurrentBooking(null);
          setTimestamp("");
          setSuccessOrfailureFlag(true);
          setStatus("success");
          setLoading(false);
        } else {
          setHalfState(null);
          setTimestamp("");
          setLoading(false);
          toast.error(
            "Payment gateway issue. Please contact temple authorities"
          );
        }
      } else {
        setTimestamp(Date.now());

        if (
          currentBooking?.res.templePaymentConfig.configuration.type ===
          "ccavenue"
        ) {
          if (res || JSON.parse(localStorage.getItem("payment-context"))) {
            setLoading(false);
            setTimestamp("");
            setPaymentPageFlag(true);
            return;
          }
        } else if (
          currentBooking?.res.templePaymentConfig.configuration.type ===
          "razorpay"
        ) {
          await DisplayRazorpay(
            res?.paymentInformation?.razorOrder?.id,
            res?.paymentInformation?.razorOrder?.currency,
            res?.paymentInformation?.razorOrder?.amount,
            res?.cartItems?.donation,
            res?.paymentInformation?.onlinePaymentEntity?.id,
            BASE_BANK,
            setDissmissed,
            userData,
            dissmissed,
            res?.priceStats?.templePaymentConfig?.razorpay?.key,
            templeID
          );
          setHalfState(null);
          setSuccessOrfailureFlag(true);
          setStatus("success");
          setTimestamp("");
          setConfirmedData({
            selectedArray: currentBooking.selectedArray,
            startDate: currentBooking.startDate,
            slot: currentBooking.slot,
          });
          setCurrentBooking(null);
          setLoading(false);
        } else {
          toast.error({
            message:
              "Something went wrong. Please try again or contact us through support.",
          });
          setLoading(false);
        }
      }
    } catch (e) {
      //
      setErrorMsg(e.message);
      setSuccessOrfailureFlag(true);
      setStatus("failed");
      setLoading(false);
    }
  };

  const getStatusFunction = () => {
    switch (status) {
      case "success":
        return (
          <div className="sd-success" ref={refContainer}>
            <div className="sd-success-top sd-green">
              <img src={sd_success_icon} alt="success" /> <p>Success</p>
            </div>
            <div className="sd-success-bottom">
              <h3>
                Confirmed! Your online booking for
                {confirmedData?.selectedArray?.map((item) => {
                  return (
                    <>
                      {` ${item?.name} `}
                      on
                      {` ${moment(confirmedData?.startDate).format(
                        "DD-MM-YYYY"
                      )} `}
                      {confirmedData?.slot?.productSlot ? (
                        ` (${moment(
                          confirmedData?.slot?.productSlot?.startTime,
                          "HH:mm:ss"
                        ).format("hh:mm A")} - ${moment(
                          confirmedData?.slot?.productSlot?.endTime,
                          "HH:mm:ss"
                        ).format("hh:mm A")}) `
                      ) : (
                        <>
                          {IndiaTime(item?.startDate).format("hh:mm A") ===
                          IndiaTime(item?.endDate).format("hh:mm A") ? (
                            <p>
                              {item?.startDate &&
                                IndiaTime(item?.startDate).format("hh:mm A")}
                            </p>
                          ) : (
                            <p>
                              {item.startDate &&
                                IndiaTime(item?.startDate).format("hh:mm A")}
                              -
                              {item.endDate &&
                                IndiaTime(item?.endDate).format("hh:mm A")}
                            </p>
                          )}
                        </>
                      )}
                    </>
                  );
                })}
                has been made successfully. Looking forward to meeting you on a
                journey towards spirituality.
              </h3>
              <div className="sd-success-bottom-down">
                <p
                  className="sd-border-right"
                  onClick={() => {
                    history?.push(
                      `/${language?.code}/devotee-app/booking-history`
                    );
                  }}
                >
                  Booking History
                </p>
                <p
                  onClick={() => {
                    history?.push(`/${language?.code}/home`);
                  }}
                >
                  Go to Home
                </p>
              </div>
            </div>
          </div>
        );
      case "failed":
        return (
          <div className="sd-success" ref={refContainer}>
            <div className="sd-success-top sd-redback">
              <img src={sd_failure_icon} alt="success" />
              <p>Sorry, your booking failed! {errorMsg} </p>
            </div>
            <div className="sd-success-bottom">
              <h3>
                Booking failed! Your online booking for
                {currentBooking?.selectedArray.map((item) => {
                  return (
                    <>
                      {item?.name} at
                      {IndiaTime(item?.startDate).format("hh:mm A") ===
                      IndiaTime(item?.endDate).format("hh:mm A") ? (
                        <p>
                          {item.startDate &&
                            IndiaTime(item.startDate).format("hh:mm A")}
                        </p>
                      ) : (
                        <p>
                          {item.startDate &&
                            IndiaTime(item?.startDate).format("hh:mm A")}
                          -
                          {item.end_date &&
                            IndiaTime(item?.end_date).format("hh:mm A")}
                        </p>
                      )}
                      on
                      <p>
                        {moment(currentBooking?.startDate).format("DD-MM-YYYY")}
                      </p>
                    </>
                  );
                })}
                <span>failed.</span>
                Check your booking history or SMS after 20 mins for
                confirmation. Please note that any funds debited will credit
                back to your account within 7 working days.
              </h3>
              <div className="sd-success-bottom-down">
                <p
                  className="sd-border-right"
                  onClick={() => {
                    setStatus("");
                    setSuccessOrfailureFlag(false);
                  }}
                >
                  Try Again
                </p>
                <p
                  onClick={() => {
                    history?.push(`/${language?.code}/support/contact-us`);
                  }}
                >
                  Contact Us
                </p>
              </div>
            </div>
          </div>
        );
    }
  };

  //--------------------------------------------------------------functions---------------------------------------------------------//

  return (
    <div
      className="col-xs-12 col-sm-12 col-md-9 col-lg-9 sd-dashboard"
      style={{
        minHeight: document.getElementById("side-menu")?.offsetHeight,
      }}
    >
      {loading ? (
        <Sd_loading />
      ) : successOrfailureFlag ? (
        getStatusFunction()
      ) : (
        <div className="sd-confirm-details">
          <h2 className="sd-side-heading fw400">
            <span className="fw400">
              <span className="fw700">Confirm</span> Details
            </span>
            <a
              onClick={() => {
                history?.push(
                  `/${
                    language?.code
                  }/devotee-app/online-booking/paroksha-seva/booking/${currentBooking?.selectedArray[0].name
                    ?.replace?.(/\s/g, "-")
                    ?.replace(/[\\/]/g, "-")
                    .toLowerCase()}`
                );
              }}
              className="sd-confirm-edit-button"
            >
              <img src={sd_dashboard_edit_ico} alt="Image" /> Edit details
            </a>
          </h2>
          <div className="clearfix  sd-booking-details">
            <h4>Booking Details</h4>
            <table className="table table-bordered sd-toggle-table">
              <thead>
                <tr>
                  <th width="60%">Booking for</th>
                  <th width="20%">Date</th>
                  <th width="20%">Total Price</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ display: "flex", flexDirection: "column" }}>
                    {getData()}
                  </td>
                  <td>
                    {moment(currentBooking.startDate).format("DD-MM-YYYY")}
                  </td>
                  <td>
                    Rs. {currentBooking?.res?.totalAmount}.00 <br /> (Handling
                    charges:
                    {parseFloat(currentBooking?.res?.templeFee).toFixed(2)})
                  </td>
                </tr>
              </tbody>
            </table>

            <div className="sd-table-detoggler">
              <div className="sd-booking-details-up bg">
                <div className="sd-actions-icons-main">
                  <label style={{ color: "grey", fontWeight: 500 }}>
                    BOOKING FOR
                  </label>
                  <br />
                  <span style={{ fontWeight: "600" }}>{getData()}</span>
                </div>
              </div>
              <div className="sd-booking-details-down ">
                <div>
                  <label>DATE/SLOT</label>
                  <span>
                    {moment(currentBooking.startDate).format("DD-MM-YYYY")}
                  </span>
                </div>
                {/* {currentBooking.occasion && (
                  <div>
                    
                    <label>OCCASION</label>
                    <span> {currentBooking?.occasion}</span>
                  </div>
                )} */}
                <div style={{ marginRight: "0px" }}>
                  <label>AMOUNT</label>
                  <span>
                    {userData?.phone?.substring(0, 3) === "+91" ? "Rs." : "$"}
                    {currentBooking?.res?.totalAmount}.00
                    <br />
                    <span style={{ fontSize: "12px" }}>
                      (Handling charges:
                      {userData?.phone?.substring(0, 3) === "+91" ? "Rs." : "$"}
                      {~~currentBooking?.res?.additionalAmount}.00)
                    </span>
                  </span>
                </div>
              </div>
              {currentBooking.occasion && (
                <div className="sd-booking-details-down ">
                  <div>
                    <label>OCCASION</label>
                    <span> {currentBooking?.occasion}</span>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="clearfix sd-devotee-details mt-30">
            <h4>Devotee Details - Mobile: {currentBooking?.mobile} </h4>

            <table className="table table-bordered">
              <thead>
                <tr>
                  <th width="20%">S.No.</th>
                  <th width="40.6%">Name</th>
                  <th width="40.6%">Gothram</th>
                </tr>
              </thead>
              <tbody>{getDetailsFunction()}</tbody>
            </table>
            <h4>
              <b>Addresss</b> : {currentBooking?.address1}
              {currentBooking?.address2 &&
                `, ${currentBooking?.address2}`}, {currentBooking?.city},
              {currentBooking?.state},{currentBooking?.country},
              {currentBooking?.zip}.
            </h4>
          </div>

          <div className="clearfix mt-50 sd-form">
            <a
              className="sd-btn-orange"
              onClick={() => {
                if (!loading) {
                  handleSubmit();
                }
              }}
            >
              Proceed to Payment{" "}
              <img
                className="sd-arrow-btn ml-5"
                src={sd_dashboard_side_arrow}
              />
            </a>
          </div>
        </div>
      )}{" "}
      {paymentPageFlag && (
        <PaymentPage
          bookingData={{
            responseData: {
              data: {
                productDetails: halfState?.cartItems?.seva[0].productDetails,
                price: halfState?.cartItems.seva[0].sevaBooking.price,
                personDetails:
                  halfState?.cartItems?.seva[0].sevaBooking.personDetails,
              },
              ...halfState,
            },
            bookingData: currentBooking,
            from: "parokshaSeva",
          }}
        />
      )}
    </div>
  );
};
export default withRouter(Sd_dashboard_parokshaseva_booking_confirm_details);
