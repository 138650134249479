import getTempleShortCodeFromDomain from "./templeId.data";
import pagesData from "./pages.data";

const NavBarData = (key) => {
  const id =
    process.env.REACT_APP_TEMPLE ??
    getTempleShortCodeFromDomain(window?.location?.hostname);

  const aboutData = {
    SVSVD: {
      "the-temple": [
        {
          name: pagesData("home")?.shortName,

          path: `about/the-temple/about-the-temple`,
        },
      ],
      "temple-story": [
        {
          name: pagesData("commonTitles", "History"),
          path: "about/the-temple-history/the-history",
        },
        {
          name: pagesData("commonTitles", "Mythology"),
          path: "about/the-temple-history/mythology",
        },

        {
          name: pagesData("commonTitles", "Topography"),
          path: "about/the-temple-history/topography",
        },
      ],
      "general-information": [
        {
          name: "Visiting Places",
          path: "about/general-information/visiting-places",
        },
        {
          name: "Adopted Temples",
          path: "about/general-information/adopted-temples",
        },
        // { "name": "Trusts", "path": "about/general-information/trusts" },
        {
          name: "Trust Board",
          path: "about/general-information/trust-board",
        },
        {
          name: "Chairman",
          path: "about/general-information/chairman",
        },
        {
          name: "Executive Officer",
          path: "about/general-information/executive",
        },
        {
          name: "Do's & Don'ts",
          path: "about/general-information/dos-and-donts",
        },
        {
          name: "Intellectual Property",
          path: "about/general-information/intellectual-property",
        },
        {
          name: "Certifications",
          path: "about/general-information/certifications",
        },
      ],
    },
    SKHTD: {
      "the-temple": [
        {
          name: pagesData("home")?.shortName,
          path: `about/the-temple/about-the-temple`,
        },
      ],
      "temple-story": [
        {
          name: pagesData("commonTitles", "History"),
          path: "about/the-temple-history/the-history",
        },
        {
          name: pagesData("commonTitles", "Mythology"),
          path: "about/the-temple-history/mythology",
        },

        {
          name: pagesData("commonTitles", "Topography"),
          path: "about/the-temple-history/topography",
        },
      ],
      "general-information": [
        {
          name: "Visiting Places",
          path: "about/general-information/visiting-places",
        },
        {
          name: "Adopted Temples",
          path: "about/general-information/adopted-temples",
        },
        // { "name": "Trusts", "path": "about/general-information/trusts" },
        {
          name: "Trust Board",
          path: "about/general-information/trust-board",
        },
        {
          name: "Chairman",
          path: "about/general-information/chairman",
        },
        {
          name: "Executive Officer",
          path: "about/general-information/executive",
        },
        {
          name: "Do's & Don'ts",
          path: "about/general-information/dos-and-donts",
        },
        {
          name: "Intellectual Property",
          path: "about/general-information/intellectual-property",
        },
        {
          name: "Certifications",
          path: "about/general-information/certifications",
        },
      ],
    },
    SVLNSD: {
      "the-temple": [
        {
          name: pagesData("home")?.shortName,
          path: `about/the-temple/about-the-temple`,
        },
      ],
      "temple-story": [
        {
          name: pagesData("commonTitles", "History"),
          path: "about/the-temple-history/the-history",
        },
        {
          name: pagesData("commonTitles", "Mythology"),
          path: "about/the-temple-history/mythology",
        },

        {
          name: pagesData("commonTitles", "Topography"),
          path: "about/the-temple-history/topography",
        },
        {
          name: pagesData("commonTitles", "Architecture"),
          path: "about/the-temple-history/architecture",
        },
      ],
      "general-information": [
        {
          name: "Visiting Places",
          path: "about/general-information/visiting-places",
        },
        {
          name: "Adopted Temples",
          path: "about/general-information/adopted-temples",
        },
        // { "name": "Trusts", "path": "about/general-information/trusts" },
        {
          name: "Trust Board",
          path: "about/general-information/trust-board",
        },
        {
          name: "Chairman",
          path: "about/general-information/chairman",
        },
        {
          name: "Executive Officer",
          path: "about/general-information/executive",
        },
        {
          name: "Do's & Don'ts",
          path: "about/general-information/dos-and-donts",
        },
        {
          name: "Intellectual Property",
          path: "about/general-information/intellectual-property",
        },
        {
          name: "Certifications",
          path: "about/general-information/certifications",
        },
      ],
    },
    SSVVSD: {
      "the-temple": [
        {
          name: pagesData("home")?.shortName,
          path: `about/the-temple/about-the-temple`,
        },
        {
          name: "Main Temple",
          path: `about/the-temple/main-temple`,
        },
        {
          name: "Utsava Murthulu",
          path: `about/the-temple/utsava-murthulu`,
        },
      ],
      "temple-story": [
        {
          name: pagesData("commonTitles", "History"),
          path: "about/the-temple-history/the-history",
        },
        {
          name: pagesData("commonTitles", "Mythology"),
          path: "about/the-temple-history/mythology",
        },

        {
          name: pagesData("commonTitles", "Topography"),
          path: "about/the-temple-history/topography",
        },
        {
          name: "Ancient Routes to Kanipakam",
          path: "about/the-temple-history/ancient-routes-to-kanipakam",
        },
      ],
      "general-information": [
        {
          name: "Visiting Places",
          path: "about/general-information/visiting-places",
        },
        {
          name: "Adopted Temples",
          path: "about/general-information/adopted-temples",
        },
        // { "name": "Trusts", "path": "about/general-information/trusts" },
        {
          name: "Trust Board",
          path: "about/general-information/trust-board",
        },
        {
          name: "Chairman",
          path: "about/general-information/chairman",
        },
        {
          name: "Executive Officer",
          path: "about/general-information/executive",
        },
        {
          name: "Do's & Don'ts",
          path: "about/general-information/dos-and-donts",
        },
        {
          name: "Intellectual Property",
          path: "about/general-information/intellectual-property",
        },
        {
          name: "Certifications",
          path: "about/general-information/certifications",
        },
      ],
    },
    SDMSD: {
      "the-temple": [
        {
          name: pagesData("home")?.shortName,
          path: `about/the-temple/about-the-temple`,
        },
        {
          name: "Main Temple",
          path: `about/the-temple/main-temple`,
        },
        {
          name: "Utsava Murthulu",
          path: `about/the-temple/utsava-murthulu`,
        },
        {
          name: "Alankaras",
          path: `about/the-temple/alankaras`,
        },
      ],
      "temple-story": [
        {
          name: pagesData("commonTitles", "History"),
          path: "about/the-temple-history/the-history",
        },
        {
          name: pagesData("commonTitles", "Mythology"),
          path: "about/the-temple-history/mythology",
        },

        {
          name: pagesData("commonTitles", "Topography"),
          path: "about/the-temple-history/topography",
        },
        // {
        //   name: "Ancient Routes to Kanipakam",
        //   path: "about/the-temple-history/ancient-routes-to-kanipakam",
        // },
      ],
      "general-information": [
        {
          name: "Visiting Places",
          path: "about/general-information/visiting-places",
        },
        {
          name: "Adopted Temples",
          path: "about/general-information/adopted-temples",
        },
        // { "name": "Trusts", "path": "about/general-information/trusts" },
        {
          name: "Trust Board",
          path: "about/general-information/trust-board",
        },
        // {
        //   name: "Chairman",
        //   path: "about/general-information/chairman",
        // },
        {
          name: "Executive Officer",
          path: "about/general-information/executive",
        },
        {
          name: "Do's & Don'ts",
          path: "about/general-information/dos-and-donts",
        },
        // {
        //   name: "Intellectual Property",
        //   path: "about/general-information/intellectual-property",
        // },
        // {
        //   name: "Certifications",
        //   path: "about/general-information/certifications",
        // },
      ],
    },
  };

  const donationsData = {
    SVSVD: [
      { name: "Overview", path: "Donations" },
      { name: "e-Hundi", path: "about/general-information/e-Hundi" },
      {
        name: "Saswatha Annadanam Trust",
        path: "about/general-information/saswatha-annadanam-trust",
      },
      {
        name: "Go Samrakshana Padhakam Trust",
        path: "about/general-information/go-samrakshana-padhakam-trust",
      },
      {
        name: "Pranadana Trust",
        path: "about/general-information/pranadana-trust",
      },
      {
        name: "Cottage Donation Scheme",
        path: "about/general-information/cottage-donation-scheme",
      },
      {
        name: "Sri Venkateswara Vykhanasa Agama Patasala Vidhyardhi Dattata Phakam",
        path: "about/general-information/sri-venkateswara-vykhanasa-agama-patasala-vidhyardhi-dattata-phakam",
      },

      // {
      //   name: "Sri Satya Deva Annaprasada Distribution Trust ",
      //   path: "sri-satya-deva-annaprasada-distribution-trust ",
      // },
      // {
      //   name: "Pulihora and Dadhojanam Scheme",
      //   path: "pulihora-and-dadhojanam-scheme",
      // },
    ],
    SKHTD: [
      { name: "Overview", path: "Donations" },
      { name: "e-Hundi", path: "about/general-information/e-Hundi" },
      {
        name: "Annadanam Trust",
        path: "about/general-information/annadanam-trust",
      },
      {
        name: "Gosamrakshana Trust",
        path: "about/general-information/gosamrakshana-trust",
      },
    ],
    SVLNSD: [
      { name: "Overview", path: "Donations" },
      { name: "e-Hundi", path: "about/general-information/e-Hundi" },
    ],
    SSVVSD: [
      { name: "Overview", path: "Donations" },
      { name: "e-Hundi", path: "about/general-information/e-Hundi" },
      {
        name: "Sri Varasiddhi Vinayaka Go Samrakshana Trust",
        path: "about/general-information/sri-varasiddhi-vinayaka-go-samrakshana-trust",
      },
      {
        name: "Saswatha Nithya Annadanam Trust",
        path: "about/general-information/saswatha-nithya-annadanam-trust",
      },
      {
        name: "Sri Siddhi Vinayaka Swamy Accommodation Trust",
        path: "about/general-information/sri-siddhi-vinayaka-swamy-accommodation-trust",
      },
    ],
    SDMSD: [
      { name: "Overview", path: "Donations" },
      { name: "e-Hundi", path: "about/general-information/e-Hundi" },
      // {
      //   name: "Sri Varasiddhi Vinayaka Go Samrakshana Trust",
      //   path: "about/general-information/sri-varasiddhi-vinayaka-go-samrakshana-trust",
      // },
      // {
      //   name: "Saswatha Nithya Annadanam Trust",
      //   path: "about/ general-information/saswatha-nithya-annadanam-trust",
      // },
      {
        name: "Saswatha Sri Durga Malleswara Swamy varla Dharma Prachara ParishadNithya Annadanam Trust",
        path: "about/general-information/saswatha-sri-durga-malleswara-swamy-varla-dharma-prachara-parishadNithya-annadanam-trust",
      },
      {
        name: "Sri Krishna Nadi Pavithra Harathi Trust",
        path: "about/general-information/sri-krishna-nadi-pavithra-harathi-trust",
      },
      {
        name: "Parirakshana Trust",
        path: "about/general-information/parirakshana-trust",
      },
      {
        name: "Gosamrakshana Trust",
        path: "about/general-information/gosamrakshana-trust",
      },
    ],
  };

  const onlineBookingData = {
    SVSVD: [
      {
        name: "Overview",
        path: "online-booking",
      },
      {
        name: pagesData("commonTitles", "Darshanam"),
        path: "darshanam",
      },
      {
        name: pagesData("commonTitles", "Pratyaksha Seva"),
        path: "pratyaksha-seva",
      },
      {
        name: pagesData("commonTitles", "Paroksha Seva"),
        path: "paroksha-seva",
      },
      // { name: pagesData("commonTitles", "Prasadam"), path: "Prasadam" },
      {
        name: pagesData("commonTitles", "Accommodation"),
        path: "accommodations",
      },
      // {
      //   name: pagesData("commonTitles", "Katta"),
      //   path: "kalyanakatta",
      // },
      // {
      //   name: pagesData("commonTitles", "Annadanam"),
      //   path: "annadanam",
      // },
      // {
      //   name: pagesData("commonTitles", "Kalyana"),
      //   path: "kalyana-mandapam",
      // },
    ],
    SKHTD: [
      {
        name: "Overview",
        path: "online-booking",
      },
      {
        name: pagesData("commonTitles", "Darshanam"),
        path: "darshanam",
      },
      {
        name: pagesData("commonTitles", "Pratyaksha Seva"),
        path: "pratyaksha-seva",
      },
      {
        name: pagesData("commonTitles", "Paroksha Seva"),
        path: "paroksha-seva",
      },
      // { name: pagesData("commonTitles", "Prasadam"), path: "Prasadam" },
      {
        name: pagesData("commonTitles", "Accommodation"),
        path: "accommodations",
      },
      // {
      //   name: pagesData("commonTitles", "Katta"),
      //   path: "kalyanakatta",
      // },
      // {
      //   name: pagesData("commonTitles", "Annadanam"),
      //   path: "annadanam",
      // },
      // {
      //   name: pagesData("commonTitles", "Kalyana"),
      //   path: "kalyana-mandapam",
      // },
    ],
    SVLNSD: [
      {
        name: "Overview",
        path: "online-booking",
      },
      {
        name: pagesData("commonTitles", "Darshanam"),
        path: "darshanam",
      },
      {
        name: pagesData("commonTitles", "Pratyaksha Seva"),
        path: "pratyaksha-seva",
      },
      {
        name: pagesData("commonTitles", "Paroksha Seva"),
        path: "paroksha-seva",
      },
      // { name: pagesData("commonTitles", "Prasadam"), path: "Prasadam" },
      {
        name: pagesData("commonTitles", "Accommodation"),
        path: "accommodations",
      },
      // {
      //   name: pagesData("commonTitles", "Katta"),
      //   path: "kalyanakatta",
      // },
      // {
      //   name: pagesData("commonTitles", "Annadanam"),
      //   path: "annadanam",
      // },
      // {
      //   name: pagesData("commonTitles", "Kalyana"),
      //   path: "kalyana-mandapam",
      // },
    ],
    SSVVSD: [
      {
        name: "Overview",
        path: "online-booking",
      },
      {
        name: pagesData("commonTitles", "Darshanam"),
        path: "darshanam",
      },
      {
        name: pagesData("commonTitles", "Pratyaksha Seva"),
        path: "pratyaksha-seva",
      },
      {
        name: pagesData("commonTitles", "Paroksha Seva"),
        path: "paroksha-seva",
      },
      {
        name: pagesData("commonTitles", "Accommodation"),
        path: "accommodations",
      },
      {
        name: pagesData("commonTitles", "Donations"),
        path: "donations",
      },
    ],
    SDMSD: [
      {
        name: "Overview",
        path: "online-booking",
      },
      {
        name: pagesData("commonTitles", "Darshanam"),
        path: "darshanam",
      },
      {
        name: pagesData("commonTitles", "Pratyaksha Seva"),
        path: "pratyaksha-seva",
      },
      {
        name: pagesData("commonTitles", "Paroksha Seva"),
        path: "paroksha-seva",
      },
      {
        name: pagesData("commonTitles", "Donations"),
        path: "donations",
      },
      // { name: pagesData("commonTitles", "Prasadam"), path: "Prasadam" },
      {
        name: pagesData("commonTitles", "Accommodation"),
        path: "accommodations",
      },
      // {
      //   name: pagesData("commonTitles", "Katta"),
      //   path: "kalyanakatta",
      // },
      // {
      //   name: pagesData("commonTitles", "Annadanam"),
      //   path: "annadanam",
      // },
      // {
      //   name: pagesData("commonTitles", "Kalyana"),
      //   path: "kalyana-mandapam",
      // },
    ],
  };

  const supportData = [
    { name: "Overview", path: "support" },
    { name: "FAQs", path: "support/faqs" },
    {
      name: pagesData("commonTitles", "Facilities to Pilgrims"),
      path: "support/facilities-to-pilgrims",
    },
    {
      name: pagesData("commonTitles", "Connectivity"),
      path: "support/connectivity",
    },
    // {
    //   name: "Live Chat",
    //   path: "support/live-chat",
    // },
    {
      name: "Contact Us",
      path: "support/contact-us",
    },
  ];

  const mediaData = {
    gallery: [
      {
        name: pagesData("commonTitles", "Photo"),
        path: "media-room/photo-gallery",
      },
      {
        name: pagesData("commonTitles", "Video"),
        path: "media-room/video-gallery",
      },
    ],
    "whats-new": [
      {
        name: "Temple Diary",
        path: "media-room/whats-new/temple-diary",
      },
      {
        name: pagesData("commonTitles", "Events"),
        path: "media-room/whats-new/events",
      },
      {
        name: pagesData("commonTitles", "NewsLetter"),
        path: "media-room/whats-new/newsletter",
      },
    ],
    press: [
      {
        name: pagesData("commonTitles", "News"),
        path: "media-room/press/temple-news",
      },
      {
        name: pagesData("commonTitles", "Release"),
        path: "media-room/press/press-releases",
      },
      {
        name: pagesData("commonTitles", "Coverage"),
        path: "media-room/press/press-coverage",
      },
      {
        name: pagesData("commonTitles", "Queries"),
        path: "media-room/press/media-queries",
      },
    ],
  };

  const menuStructure = [
    {
      title: "About",
      key: "about",
      children: [
        { name: "Overview", path: "about" },
        {
          name: "The Temple",
          path: "the-temple",
          children: aboutData[id]["the-temple"],
        },
        {
          name: "Temple Story",
          path: "temple-story",
          children: aboutData[id]["temple-story"],
        },
        {
          name: "General Information",
          path: "general-information",
          children: aboutData[id]["general-information"],
        },
      ],
    },
    {
      title: pagesData("commonTitles", "Seva and Darshanam"),
      key: "sevas-and-darshanam",
      children: [
        {
          name: "Overview",
          path: "sevas-and-darshanam",
        },
        {
          name: pagesData("commonTitles", "Darshanam"),
          path: "sevas-and-darshanam/darshanam",
        },
        {
          name: pagesData("commonTitles", "Pratyaksha Seva"),
          path: "sevas-and-darshanam/pratyaksha-seva",
        },
        {
          name: pagesData("commonTitles", "Paroksha Seva"),
          path: "sevas-and-darshanam/paroksha-seva",
        },
      ],
    },
    {
      title: pagesData("commonTitles", "Donations"),
      key: "Donations",
      children: donationsData[id],
    },
    {
      title: "Online Booking",
      key: "online-booking",
      children: onlineBookingData[id],
    },
    {
      title: pagesData("commonTitles", "Media"),
      key: "media-room",
      children: [
        {
          name: "Overview",
          path: "media-room",
        },
        {
          name: pagesData("commonTitles", "Media Kit"),
          path: "media-room/media-kit",
        },
        {
          name: pagesData("commonTitles", "Gallery"),
          path: "gallery",
          children: mediaData["gallery"],
        },
        {
          name: "Whats New",
          path: "whats-new",
          children: mediaData["whats-new"],
        },
        {
          name: pagesData("commonTitles", "Press"),
          path: "press",
          children: mediaData["press"],
        },
        {
          name: pagesData("commonTitles", "RTI Act"),
          path: "media-room/rti-act",
        },
        {
          name: pagesData("commonTitles", "Tenders"),
          path: "media-room/tenders",
        },
      ],
    },
    {
      title: "Support",
      key: "support",
      children: supportData,
    },
  ];

  const dieties = {
    SVSVD: [],
    SKHTD: [
      {
        name: "Sivanandaika Nilayamu (Mount Kailash)",
        path: "about/general-information/sivanandaika-nilayamu",
      },
      {
        name: "Sri Gnanaprasunamba",
        path: "about/general-information/sri-gnanaprasunamba",
      },
      {
        name: "Swarnamukhi River (Divya Ganga)",
        path: "about/general-information/swarnamukhi-river",
      },
      {
        name: "Bhaktha Kannappa",
        path: "about/general-information/bhaktha-kannappa",
      },
    ],
    SVLNSD: [],
    SSVVSD: [],
    SDMSD: [],
  };

  const dataMap = {
    about: aboutData[id],
    donation: donationsData[id],
    "online-data": onlineBookingData[id],
    media: mediaData,
    support: supportData,
    menu: menuStructure,
    dieties: dieties[id],
  };

  return dataMap[key];
};

export default NavBarData;
