import React, { useEffect, useContext } from "react";
import "../../../Utils/sd-styles/sd-css/main.css";
import { SD_Context } from "../../../context/sd-context";
import Sd_comming_soon from "../../sd-about/sd-coming-soon";

const Sd_dashboard_online_booking_prasadam = () => {
  const { setActive, setActivePath } = useContext(SD_Context);
  useEffect(() => {
    setActive("online-booking-prasadam");
    setActivePath("Onlinebooking-Prasadam");
  }, []);
  return (
    <div className="col-xs-12 col-sm-12 col-md-9 col-lg-9 sd-dashboard">
      <Sd_comming_soon />
    </div>
  );
};
export default Sd_dashboard_online_booking_prasadam;
