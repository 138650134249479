import React from "react";
import { SD_Logo_circle, maintenance } from "../../Assets/imageList";
import pagesData from "../../Utils/data/pages.data";

const Maintenance = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <img src={SD_Logo_circle} alt="logo" style={{ width: "100px" }} />
      <p style={{ fontWeight: "bold", fontSize: "20px", textAlign: "center" }}>
        {pagesData("home")?.title}
      </p>
      <div style={{ width: "40%" }}>
        <img
          src={maintenance}
          alt="logo"
          style={{ width: "100%", margin: "auto" }}
        />
      </div>
    </div>
  );
};

export default Maintenance;
